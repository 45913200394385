'use client';
/* eslint-disable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
// 1. The import order of macros matter and they must be kept in this order
// 2. Since macros are transpiled out during build, it is okay for them
//   to be imported even when they are not used.
// -- color must always be first -- //
import color from '@haaretz/l-color.macro';
// ---
import fontStack from '@haaretz/l-font-stack.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import zIndex from '@haaretz/l-z-index.macro';
// --- These return objects and must be spread or used inside `merge` --- //
import border from '@haaretz/l-border.macro';
import shadow from '@haaretz/l-shadow.macro';
import typesetter from '@haaretz/l-type.macro';
// --- These must come last --- //
import fork from '@haaretz/l-fork.macro';
import mq from '@haaretz/l-mq.macro';
import merge from '@haaretz/l-merge.macro';
/* eslint-enable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */

import Button from '@haaretz/s-button';
import FormfieldDescription from '@haaretz/s-formfield-description';
import { TextFieldInput, TextFieldWrapper } from '@haaretz/s-text-field';
import * as React from 'react';
import s9 from 'style9';

import type { ButtonProps as OriginalButtonProps, ButtonState } from '@haaretz/s-button';
import type { TextFieldProps as OriginalTextFieldProps } from '@haaretz/s-text-field';
import type { StyleExtend } from '@haaretz/s-types';

// `c` is short for `classNames`
const c = s9.create({
  base: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    flexGrow: 0,
    transitionTimingFunction: 'ease-in-out',
    ':active': {
      transformOrigin: fork({
        default: 'right',
        hdc: 'left',
      }),
    },

    ...merge(
      mq({ until: 'xxl', value: fork({ default: {}, hdc: { ...typesetter(0) } }) }),
      mq({ from: 'xxl', value: fork({ default: {}, hdc: { ...typesetter(-1) } }) })
    ),
  },
  inputWithButton: {
    display: 'flex',
  },
  input: {
    flexGrow: 1,
    width: 'auto',
  },

  // this class aims to fix "double" opacity attributes from button and textfield
  disabledState: {
    opacity: 1,
  },
});

type IrrelevantButtonProps =
  | 'as'
  | 'busyNotice'
  | 'children'
  | 'href'
  | 'isPressed'
  | 'priority'
  | 'ref'
  | 'sharp'
  | 'state'
  | 'stretch';
type IrrelevantTextFieldProps = 'hideBorder' | 'ref' | 'sharp' | 'type' | 'aria-describedby';

type InputType = Exclude<OriginalTextFieldProps['type'], 'hidden'>;

type ButtonProps = Omit<OriginalButtonProps, IrrelevantButtonProps> & ButtonState;
type TextfieldProps = Omit<OriginalTextFieldProps, IrrelevantTextFieldProps> & {
  type?: InputType;
};

export interface TextFieldWithButtonProps extends TextfieldProps {
  /** The text of the button */
  btnContent: OriginalButtonProps['children'];
  /**
   * A subset of the `ButtonProps` type from `@haaretz/s-button`
   * (sans the ones irrelevant to this component).
   * See [the Button component](/?path=%2Fdocs%2Fatoms-button--overview) for more details.
   */
  btnProps?: ButtonProps;
  /** A reference to the button element */
  btnRef?: OriginalButtonProps['ref'];
  /** Indicates if only the description should be invalid */
  isInvalidDescription?: boolean;
  /** styleExtend for the input */
  inputStyleExtend?: StyleExtend;
}

export default React.forwardRef<HTMLInputElement, TextFieldWithButtonProps>(
  function TextFieldWithButton(
    {
      // textfield-wrapper props
      inlineStyle,
      isInvalid,
      opaque,
      styleExtend = [],
      state,
      variant,
      required: requiredProp,

      // textfield props
      inputStyleExtend = [],
      // textfield-description props
      description,
      isInvalidDescription,

      // button props
      btnContent,
      btnProps: {
        onClick: btnOnClick,
        state: btnState,
        busyNotice,
        variant: btnVariant,
        styleExtend: btnStyleExtend = [],
        ...btnProps
      } = {},
      btnRef,

      ...textFieldProps
    }: TextFieldWithButtonProps,
    ref
  ) {
    const inputRef = React.useRef<HTMLInputElement | null>(null);
    const [required, setRequired] = React.useState(false);
    React.useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);
    const descriptionId = React.useId();

    if (isInvalid && isInvalidDescription) {
      throw new Error(
        'both isInvalid and isInvaliDescription are set to true, if you want to control only the description you have to make sure isInvalid is set to false'
      );
    }

    const buttonState = isInvalid || state === 'disabled' ? 'disabled' : btnState;

    const btnStateObjOverride: ButtonState =
      buttonState === 'busy'
        ? {
            state: 'busy',
            busyNotice: busyNotice as string,
          }
        : { state: buttonState };

    const buttonVariant = isInvalid ? 'danger' : btnVariant;
    const onButtonClick = React.useCallback(
      (evt: React.MouseEvent<HTMLButtonElement>) => {
        if (inputRef.current) {
          if (!inputRef.current.value.trim()) {
            evt.preventDefault();
            if (!requiredProp) setRequired(true);
            return;
          }
        }
        if (btnOnClick) {
          btnOnClick(evt);
        }
      },
      [btnOnClick, requiredProp]
    );

    return (
      <TextFieldWrapper
        inlineStyle={inlineStyle}
        isInvalid={isInvalid}
        opaque={opaque}
        styleExtend={[c.base, ...styleExtend]}
        state={state}
        variant={variant}
      >
        <div className={s9(c.inputWithButton)}>
          <TextFieldInput
            {...textFieldProps}
            describedBy={description && descriptionId}
            hideBorder="end"
            ref={inputRef}
            required={requiredProp || required}
            sharp="end"
            state={state}
            styleExtend={[c.input, ...inputStyleExtend]}
          />
          <Button
            {...btnProps}
            {...btnStateObjOverride}
            onClick={onButtonClick}
            ref={btnRef}
            sharp="start"
            variant={buttonVariant}
            styleExtend={[
              c.button,
              // When the entire component is disabled and not just
              // the button, we don't want the button's opacity to change
              // independently of the opacity of change of the textfield.
              state === 'disabled' && c.disabledState,
              ...btnStyleExtend,
            ]}
          >
            {btnContent}
          </Button>
        </div>
        {description ? (
          <FormfieldDescription
            id={descriptionId}
            isInvalid={isInvalidDescription || isInvalid}
            variant={variant}
          >
            {description}
          </FormfieldDescription>
        ) : null}
      </TextFieldWrapper>
    );
  }
);

// This empty Component is only used for Storybook prop inference
export function _TextFieldWithButton(_props: TextFieldWithButtonProps): React.ReactNode {
  return null;
}
