'use client';

import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import HtzImage from '@haaretz/s-htz-image';
import * as React from 'react';
import s9 from 'style9';

import { useScrollytelling } from '../ScrollytellingProvider';

import type { ImageFragment } from '@haaretz/s-fragments/HTZ_image_Image';

// `c` is short for `classNames`
const c = s9.create({
  img: {
    position: 'absolute',
    top: 0,
    left: '50%',
    opacity: 0,
    height: '100dvh',
    width: '100%',
    objectFit: 'cover',
    transform: 'translateX(-50%)',
    transition: 'opacity 0.5s',
    ...merge(
      mq({
        misc: 'landscape',
        value: {
          width: 'auto',
          marginInlineStart: 'auto',
          marginInlineEnd: 'auto',
        },
      })
    ),
  },
  current: {
    opacity: 1,
  },
});

interface ScrollyTellingImageProps extends ImageFragment {
  index: number;
}

export default function ScrollyTellingImage({ index, ...image }: ScrollyTellingImageProps) {
  const imgCur = useScrollytelling();

  return (
    <HtzImage
      {...image}
      styleExtend={[c.img, imgCur === index && c.current]}
      imgData={image.files[0]}
      contentId={image?.contentId}
      widths={[800, 1256, 1376]}
      loading="lazy"
      sizes={[{ misc: 'landscape', size: '85dvh' }, { size: 'max(85dvh, 100vw)' }]}
      aspect="vertical"
      data-testid="scrolli-img"
    />
  );
}
