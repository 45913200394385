'use client';

/* eslint-disable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
// 1. The import order of macros matter and they must be kept in this order
// 2. Since macros are transpiled out during build, it is okay for them
//   to be imported even when they are not used.
// -- color must always be first -- //
import color from '@haaretz/l-color.macro';
// ---
import fontStack from '@haaretz/l-font-stack.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import zIndex from '@haaretz/l-z-index.macro';
// --- These return objects and must be spread or used inside `merge` --- //
import border from '@haaretz/l-border.macro';
import shadow from '@haaretz/l-shadow.macro';
import typesetter from '@haaretz/l-type.macro';
// --- These must come last --- //
import fork from '@haaretz/l-fork.macro';
import mq from '@haaretz/l-mq.macro';
import merge from '@haaretz/l-merge.macro';
/* eslint-enable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */

import ClickArea from '@haaretz/s-click-area';
import Icon from '@haaretz/s-icon';
import useBi from '@haaretz/s-use-bi';
import VisuallyHidden from '@haaretz/s-visually-hidden';
import s9 from 'style9';

import TotalCount from './TotalCount';

import type { ActionBtnProps } from '../../types';
import type { BaseArticleFragment } from '@haaretz/s-fragments/articles/Base.article';

// `c` is short for `classNames`
const c = s9.create({
  clickArea: {
    columnGap: space(1),
    display: 'flex',
    justifyContent: 'start',
  },
});

export default function CommentsBtn({
  clickAreaStyleExtend = [],
  iconStyleExtend,
  commentsElementId,
  textStyleExtend,
  id,
}: ActionBtnProps & { commentsElementId: BaseArticleFragment['commentsElementId'] }) {
  const biAction = useBi();

  return (
    <ClickArea
      as="a"
      styleExtend={[c.clickArea, ...clickAreaStyleExtend]}
      rippleSize="small"
      href="#comments-section"
      data-testid="comments-btn"
      title={fork({ default: 'מעבר לתגובות', hdc: 'Comments' })}
      id={id}
      onClick={() => {
        biAction({
          feature: 'Article Page - Skip to Comments',
          feature_type: 'Content',
          action_id: 111,
        });
      }}
    >
      <Icon icon="comments" styleExtend={iconStyleExtend} variant="neutral" />
      <VisuallyHidden>{fork({ default: 'תגובות: ', hdc: 'Comments: ' })}</VisuallyHidden>
      <TotalCount commentsElementId={commentsElementId} textStyleExtend={textStyleExtend} />
    </ClickArea>
  );
}
