'use client';

import color from '@haaretz/l-color.macro';
import Caption from '@haaretz/s-caption';
import Card from '@haaretz/s-card';
import { TabPanel, TabPanels } from '@haaretz/s-tabs';
import * as React from 'react';
import s9 from 'style9';

import LineGraph from './components/LineGraph';
import LineGraphTabs from './components/LineGraphTabs';

import type { GraphRichTextElementFragment } from '@haaretz/s-fragments/GraphRichTextElement';
import type { PeriodType } from '@haaretz/s-fragments/Types';
import type { StyleExtend } from '@haaretz/s-types';

const c = s9.create({
  wrapper: {
    gridColumnEnd: 'main-end',
    gridColumnStart: 'main-start',
  },
  base: {
    color: color('neutral900'),
    position: 'relative',
  },
});

export const periods: [PeriodType, string][] = [
  ['day', 'יומי'],
  ['week', 'שבועי'],
  ['month', 'חודשי'],
  ['year1', 'שנתי'],
  ['year3', '3 שנים'],
  ['year5', '5 שנים'],
] as const;

type StockMarketGraphProps = Omit<GraphRichTextElementFragment, '__typename'> & {
  styleExtend?: StyleExtend;
};

export default function StockMarketGraph({
  assetId = '',
  title,
  styleExtend = [],
}: StockMarketGraphProps) {
  return (
    <div className={s9(c.wrapper)}>
      <Card styleExtend={[c.base, ...styleExtend]} variant="toned">
        <LineGraphTabs>
          <TabPanels>
            {periods.map(([key, _]) => (
              <TabPanel key={key}>
                <LineGraph assetId={assetId} />
              </TabPanel>
            ))}
          </TabPanels>
        </LineGraphTabs>
      </Card>
      <Caption caption={title} />
    </div>
  );
}
