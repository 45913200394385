import * as Types from '@haaretz/s-fragments/Types';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetch } from '@haaretz/s-graphql-fetcher';
export type ReportAbuseMutationVariables = Types.Exact<{
  input: Types.ReportAbuseInputType;
}>;


export type ReportAbuseMutation = (
  { reportAbuse: (
    { error?: string | null, status?: string | null, reportSuccess?: boolean | null }
    & { __typename: 'ReportAbuseResponse' }
  ) }
  & { __typename: 'Mutation' }
);


export const ReportAbuseDocument = `
    mutation ReportAbuse($input: ReportAbuseInputType!) {
  reportAbuse(input: $input) {
    error
    status
    reportSuccess
  }
}
    `;
export const useReportAbuseMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<ReportAbuseMutation, TError, ReportAbuseMutationVariables, TContext>) =>
    useMutation<ReportAbuseMutation, TError, ReportAbuseMutationVariables, TContext>(
      ['ReportAbuse'],
      (variables?: ReportAbuseMutationVariables) => fetch<ReportAbuseMutation, ReportAbuseMutationVariables>(ReportAbuseDocument, variables)(),
      options
    );
useReportAbuseMutation.getKey = () => ['ReportAbuse'];

useReportAbuseMutation.fetcher = (variables: ReportAbuseMutationVariables, options?: RequestInit['headers']) => fetch<ReportAbuseMutation, ReportAbuseMutationVariables>(ReportAbuseDocument, variables, options);