import discardNullableValues from '@haaretz/s-common-utils/discardNullableValues';
import breakUrl from '@haaretz/s-url-utils/breakUrl';

import type { ImageCropFragment } from '@haaretz/s-fragments/ImageCrop';

const precropParams: ImageCropFragment = {
  __typename: 'ImageCrop',
  x: 0,
  y: 0,
  height: NaN,
  width: NaN,
};

export default function parseFullImageSrc(url: string | undefined) {
  if (!url) return null;
  const parsedUrl = breakUrl(url);

  const { contentId = '', path = '' } =
    /^.+(?<contentId>[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})\/(?<path>.+)/.exec(
      parsedUrl.pathname
    )?.groups || {};

  const height = +(parsedUrl.searchParams.get('height') || 0);
  const width = +(parsedUrl.searchParams.get('width') || 0);

  const precrop = parsedUrl.searchParams.get('precrop');

  if (precrop) {
    const {
      cropWidth = 0,
      cropHeight = 0,
      x = 0,
      y = 0,
    } = /^((?<cropWidth>\d+),)?((?<cropHeight>\d+),)?(x(?<x>\d+),)?(y(?<y>\d+))?$/.exec(precrop)
      ?.groups || {};

    precropParams.width = +cropWidth || NaN;
    precropParams.height = +cropHeight || NaN;
    precropParams.x = +x || 0;
    precropParams.y = +y || 0;
  }

  return {
    contentId,
    height,
    path,
    width,
    precropParams: discardNullableValues(precropParams),
  };
}
