'use client';
import color from '@haaretz/l-color.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import Icon from '@haaretz/s-icon';
import s9 from 'style9';

import { useAudioPlayer } from '../hooks/useAudioPlayer';
import { playLabel } from '../utils/audioPlayerUtils';

import ControlPanel from './AudioControls';
import ChangeSpeedButton from './ChangeSpeedButton';
import FirstPlay from './FirstPlay';
import { SliderAudio } from './SliderAudio';
import Title from './Title';
import VolumeButton from './VolumeButton';

// `c` is short for `classNames`
const c = s9.create({
  grid: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr 1fr',
    gridTemplateColumns: '1fr repeat(3, auto);',
    columnGap: space(4),
    paddingTop: space(4),
    paddingLeft: space(3),
    paddingRight: space(3),
    paddingBottom: space(4),
    ...merge(
      mq({
        from: 's',
        value: {
          gridTemplateRows: 'auto 1fr auto',
          gridTemplateColumns: 'repeat(2, auto) 1fr repeat(3, auto);',
          paddingTop: space(4),
          paddingLeft: space(4),
          paddingRight: space(4),
          paddingBottom: space(4),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          paddingTop: space(5),
          paddingLeft: space(5),
          paddingRight: space(5),
          paddingBottom: space(5),
        },
      })
    ),
  },
  iconRunButton: {
    minWidth: space(16),
    minHeight: space(16),
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'end',
    justifyContent: 'center',
    borderRadius: radius('circle'),
    backgroundColor: color('primary1000'),

    ':after': {
      position: 'absolute',
      content: '""',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    marginInlineStart: space(3),
    ...merge(
      mq({
        from: 'm',
        value: {
          marginInlineStart: space(5),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          minWidth: space(18),
          minHeight: space(18),
        },
      })
    ),
  },
  iconFirstPlay: {
    transform: 'translateX(10%)',
    width: space(8),
    height: space(8),
    ...merge(
      mq({
        from: 'xxl',
        value: {
          width: space(9),
          height: space(9),
        },
      })
    ),
  },
  wrapper: {
    position: 'relative',
  },
  notShow: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    position: 'relative',
    transitionDuration: '500ms',
  },
  transition: {
    position: 'absolute',
    width: '100%',
    transitionDelay: '0ms',
    transitionDuration: '0ms',
    transitionProperty: 'opacity',
    transitionTimingFunction: 'ease-in',
  },
});

export default function AudioPlayerControlPanel() {
  const { wasNotPlayed, onPlayButtonClick, channelName, title } = useAudioPlayer();

  return (
    <div className={s9(c.wrapper)}>
      <FirstPlay
        channelName={channelName}
        title={title}
        styleExtend={[c.transition, c.notShow, wasNotPlayed && c.show]}
      >
        <button
          className={s9(c.iconRunButton)}
          onClick={onPlayButtonClick}
          data-testid="first-play-button"
        >
          <Icon
            icon="play"
            variant="inverse"
            styleExtend={[c.iconFirstPlay]}
            screenReaderText={playLabel}
          />
        </button>
      </FirstPlay>
      <div
        className={s9(c.grid, c.transition, c.notShow, !wasNotPlayed && c.show)}
        data-testid="audio-player-controls"
      >
        <Title />
        <VolumeButton />
        <ChangeSpeedButton />
        <SliderAudio />
        <ControlPanel />
      </div>
    </div>
  );
}
