'use client';

import useDocumentEventListener from '@haaretz/s-use-native-application/useDocumentEventListener';
import React from 'react';

import { LIVE_BLOG_SCROLL_TO_ITEM_EVENT } from '../../constants';

import type { LiveBlogItemsSort } from '@haaretz/s-fragments/Types';

interface LiveBlogProviderProviderContextProps {
  firstItemId?: string | null;
  lastItemId?: string | null;
  order: LiveBlogItemsSort;
}

interface LiveBlogActionProviderProviderContextProps {
  setOrder: React.Dispatch<React.SetStateAction<LiveBlogItemsSort>>;
  setFirstItemId: React.Dispatch<
    React.SetStateAction<LiveBlogProviderProviderContextProps['firstItemId']>
  >;
  setLastItemId: React.Dispatch<
    React.SetStateAction<LiveBlogProviderProviderContextProps['lastItemId']>
  >;
}

const LiveBlogProviderContext = React.createContext<
  LiveBlogProviderProviderContextProps | undefined
>(undefined);

const LiveBlogActionProviderContext = React.createContext<
  LiveBlogActionProviderProviderContextProps | undefined
>(undefined);

export default function LiveBlogProvider({ children }: React.PropsWithChildren) {
  const [order, setOrder] = React.useState<LiveBlogItemsSort>('desc');
  const [firstItemId, setFirstItemId] = React.useState<string | null | undefined>(undefined);

  const [lastItemId, setLastItemId] = React.useState<string | null | undefined>(undefined);

  const value = React.useMemo(
    () => ({ firstItemId, lastItemId, order }),
    [firstItemId, lastItemId, order]
  );
  const actions = React.useMemo(() => ({ setFirstItemId, setLastItemId, setOrder }), []);

  useDocumentEventListener(
    LIVE_BLOG_SCROLL_TO_ITEM_EVENT,
    ({ detail }) => {
      scrollToItem();

      function scrollToItem(attempt = 0) {
        const item = document.getElementById(detail);

        if (item) {
          window.history.pushState(null, '', `#${detail}`);

          item.scrollIntoView({ behavior: 'smooth' });
        } else if (attempt < 10) {
          const nextAttempt = attempt + 1;

          setTimeout(() => scrollToItem(nextAttempt), 100 * nextAttempt);
        }
      }
    },
    true
  );

  return (
    <LiveBlogProviderContext.Provider value={value}>
      <LiveBlogActionProviderContext.Provider value={actions}>
        {children}
      </LiveBlogActionProviderContext.Provider>
    </LiveBlogProviderContext.Provider>
  );
}

export function useLiveBlog() {
  const context = React.useContext(LiveBlogProviderContext);

  if (context === undefined) {
    throw new Error('useLiveBlog must be used within a LiveBlogProvider');
  }

  return context;
}

export function useLiveBlogActions() {
  const context = React.useContext(LiveBlogActionProviderContext);

  if (context === undefined) {
    throw new Error('useLiveBlog must be used within a LiveBlogProvider');
  }

  return context;
}
