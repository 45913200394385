'use client';
/* eslint-disable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
// 1. The import order of macros matter and they must be kept in this order
// 2. Since macros are transpiled out during build, it is okay for them
//   to be imported even when they are not used.
// -- color must always be first -- //
import color from '@haaretz/l-color.macro';
// ---
import fontStack from '@haaretz/l-font-stack.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import zIndex from '@haaretz/l-z-index.macro';
// --- These return objects and must be spread or used inside `merge` --- //
import border from '@haaretz/l-border.macro';
import shadow from '@haaretz/l-shadow.macro';
import typesetter from '@haaretz/l-type.macro';
// --- These must come last --- //
import fork from '@haaretz/l-fork.macro';
import mq from '@haaretz/l-mq.macro';
import merge from '@haaretz/l-merge.macro';
/* eslint-enable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
import Icon from '@haaretz/s-icon';
import TextLink from '@haaretz/s-text-link';
import useBi from '@haaretz/s-use-bi';
import * as React from 'react';
import s9 from 'style9';

import type { FileDownloadProps } from './FileDownload';

// `c` is short for `classNames`
const c = s9.create({
  wrapper: {
    alignItems: 'center',
    display: 'grid',
    // Alignment inside article
    gridColumnStart: 'main-start',
    gridColumnEnd: 'main-end',
    gridTemplateColumns: 'auto 1fr',
    marginBottom: space(7),
    marginTop: space(7),

    ...typesetter(0),
    ...merge(
      mq({
        until: 'xl',
        value: {
          ...typesetter(1),
        },
      }),
      mq({
        from: 's',
        until: 'xl',
        value: {
          marginBottom: space(9),
          marginTop: space(9),
        },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          marginBottom: space(10),
          marginTop: space(10),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          marginBottom: space(11),
          marginTop: space(11),
        },
      })
    ),
  },
  icon: {
    '--icon-color-override': color('neutral1100'),
    alignSelf: 'start',
    fontSize: space(8),
    marginInlineEnd: space(1),
    width: space(8),
    ...merge(
      mq({
        from: 's',
        value: {
          width: space(10),
          marginInlineEnd: space(1),
          fontSize: space(10),
        },
      })
    ),
  },
});

export type FileDownloadClientProps = Omit<FileDownloadProps, 'url'> & {
  fileName: string;
  url: string;
};

export default function FileDownloadClient({ url, type, text, fileName }: FileDownloadClientProps) {
  const biAction = useBi();

  const handleOnClick = () => {
    biAction({
      action_id: 169,
      feature: 'download file in article',
      feature_type: 'Content',
      campaign_name: fileName,
    });
  };

  return (
    <TextLink onClick={handleOnClick} href={url} target="_blank" styleExtend={[c.wrapper]}>
      {type === 'pdf' ? (
        <Icon icon="pdf" styleExtend={[c.icon]} />
      ) : (
        <Icon icon="drive" styleExtend={[c.icon]} />
      )}
      {text}
    </TextLink>
  );
}
