'use client';

import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import Button from '@haaretz/s-button';
import * as React from 'react';
import s9 from 'style9';

const c = s9.create({
  content: {
    position: 'relative',
  },
  notExpand: {
    ...merge(mq({ until: 's', value: { maxHeight: space(113), overflow: 'hidden' } })),
  },
  expand: {
    maxHeight: 'auto',
  },
  eclipse: {
    height: '5lh',
    position: 'absolute',
    insetInlineEnd: 0,
    bottom: 0,
    width: '100%',
    backgroundImage: `linear-gradient(to bottom, ${color('contentBg', { opacity: 0 })} 0%, ${color(
      'contentBg'
    )} 100%)` as string,
    ...merge(mq({ from: 's', value: { display: 'none' } })),
  },
  button: {
    justifySelf: 'start',
    marginTop: space(4),
    ...typesetter(-2),
    ...merge(mq({ from: 's', value: { display: 'none' } })),
  },
});

type LiveBlogItemWrapperProps = {
  children: React.ReactNode;
};

const expandButtonText = fork({
  default: 'להרחבה',
  hdc: 'Expand',
});

export default function LiveBlogItemClientWrapper({ children }: LiveBlogItemWrapperProps) {
  const [open, setIsOpen] = React.useState(false);
  const [availableExpand, setAvailableExpand] = React.useState(false);
  const itemRef = React.useRef<HTMLDivElement | null>(null);
  React.useEffect(() => {
    if (itemRef.current?.clientHeight && itemRef.current?.clientHeight > 450) {
      setAvailableExpand(true);
    }
  }, []);

  return (
    <>
      <div
        className={s9(c.content, availableExpand && !open ? c.notExpand : c.expand)}
        ref={itemRef}
      >
        {children}
        {availableExpand && !open && <div className={s9(c.eclipse)} />}
      </div>
      {availableExpand && (
        <Button
          onClick={() => {
            setIsOpen(true);
            setAvailableExpand(false);
          }}
          priority="secondary"
          styleExtend={[c.button]}
        >
          {expandButtonText}
        </Button>
      )}
    </>
  );
}
