'use client';

import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import useRegistration from '@haaretz/s-auth-hooks/use-registration';
import { ButtonState } from '@haaretz/s-button';
import { siteId, site } from '@haaretz/s-consts';
import { useUserAlertsRegistrationPersonalMutation } from '@haaretz/s-mutations/UserAlertsRegistration';
import TextFieldWithButton from '@haaretz/s-text-field-with-button';
import useBi from '@haaretz/s-use-bi';
import emailValidator from '@haaretz/s-validators/emailValidator';
import * as React from 'react';
import s9 from 'style9';

import type { EmailProperties, RegistrationStatus } from '../../NewsletterRegistration.view';

// `c` is short for `classNames`
const c = s9.create({
  disclaimer: {
    color: color('neutral1000'),
    ...typesetter(-2),
    marginTop: space(8),
    paddingInlineEnd: space(10),
    ...merge(
      mq({
        from: 'xl',
        value: {
          paddingInlineEnd: space(15),
        },
      })
    ),
  },
  textField: {
    marginTop: space(5),
    ...merge(
      mq({
        from: 's',
        until: 'xl',
        value: {
          paddingInlineEnd: space(10),
        },
      }),
      mq({
        from: 'xl',
        value: {
          paddingInlineEnd: space(15),
        },
      })
    ),
  },
  input: {
    height: space(9),
    ...merge(
      mq({
        from: 'xl',
        value: {
          height: space(10),
        },
      })
    ),
  },
});

export interface AnonymousTextFieldProps {
  resourceType?: string | null;
  resourceId?: string | null;
  setIsRegistered: React.Dispatch<React.SetStateAction<RegistrationStatus>>;
  setEmailProperties: React.Dispatch<React.SetStateAction<EmailProperties>>;
  userEmail?: string | null;
  newsletterType?: string | null;
  title: string;
}

type EmailValidationState = 'default' | 'invalid';

export default function AnonymousTextField({
  newsletterType,
  setIsRegistered,
  resourceType,
  setEmailProperties,
  resourceId,
}: AnonymousTextFieldProps) {
  const emailInputRef = React.useRef<HTMLInputElement>(null);
  const [errorMsg, setErrorMsg] = React.useState<string | null>(null);
  const [emailValidationState, setEmailValidation] =
    React.useState<EmailValidationState>('default');
  const newsletterSignup = useUserAlertsRegistrationPersonalMutation();
  const [btnState, setBtnState] = React.useState<ButtonState['state']>('auto');
  const biAction = useBi();

  const registerToNewsletter = React.useCallback(async () => {
    setBtnState('busy');
    const data = await newsletterSignup.mutateAsync({
      input: {
        email: emailInputRef.current?.value,
        isUnsubscribe: false,
        site,
        resourceId: resourceId || '',
        resourceType,
        caller: 'server',
      },
    });
    const status = data.userAlertsRegistration.status;
    if (status === 'success' || status === 'pending') {
      biAction({
        action_id: 9,
        newsletter_email: emailInputRef?.current?.value,
        newsletter_segment_id: resourceId || '',
        newsletter_segment_name: 'newsletter',
        feature_type: 'Content',
        feature: `${newsletterType === 'personal' ? 'Personal' : 'Regular'}-Newsletter`,
        personal_feature: newsletterType === 'personal',
      });
    }
    if (status === 'success') {
      setIsRegistered('success');
    }
    if (status === 'pending') {
      if (emailInputRef?.current?.value?.endsWith('@gmail.com')) {
        setEmailProperties({ isGmail: true, email: emailInputRef.current.value });
      }
      setIsRegistered('pending');
    }
    setBtnState('auto');
  }, [
    newsletterSignup,
    resourceId,
    resourceType,
    biAction,
    newsletterType,
    setIsRegistered,
    setEmailProperties,
  ]);

  const newSiteRegistration = useRegistration({
    onSuccess: () => {
      registerToNewsletter();
    },
    onError: error => {
      setErrorMsg(error.message);
    },
  });

  const onSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setBtnState('busy');
    biAction({
      action_id: 60,
      newsletter_email: emailInputRef?.current?.value || '',
      newsletter_segment_id: resourceId || '',
      newsletter_segment_name: 'newsletter',
      feature_type: 'Content',
      feature: newsletterType === 'personal' ? 'Personal-Newsletter' : 'Regular-Newsletter',
      personal_feature: newsletterType === 'personal',
    });

    const newEmail = emailInputRef?.current?.value || '';
    const isValid = emailValidator(newEmail);
    if (!isValid.valid) {
      setErrorMsg(isValid.text);
      setEmailValidation('invalid');
    } else {
      newSiteRegistration.mutate({
        input: {
          email: newEmail,
          isEntitlement: false,
          trmsChk: 'on',
          siteId,
          restEntryType: 'NEWSLETTER_MINI_REG',
        },
      });
    }
  };

  const onChange = React.useCallback(() => {
    if (errorMsg) setErrorMsg('');
    if (emailValidationState === 'invalid') setEmailValidation('default');
    if (btnState === 'disabled') setBtnState('auto');
  }, [btnState, emailValidationState, errorMsg]);

  const textFieldDescription = errorMsg
    ? errorMsg
    : fork({
        default: 'אנא הזינו כתובת מייל',
        hdc: 'Please enter your email',
      });

  return (
    <>
      <form onSubmit={onSubmit}>
        <TextFieldWithButton
          opaque
          btnContent={'שלחו'}
          btnProps={{
            variant: 'brand',
            type: 'submit',
            ...(btnState === 'busy'
              ? { state: btnState, busyNotice: 'מעדכן הרשמה...' }
              : { state: btnState }),
          }}
          description={textFieldDescription}
          label={fork({ default: 'אימייל', hdc: 'Email' })}
          maxLength={64}
          onChange={onChange}
          onInput={onChange}
          isInvalid={emailValidationState !== 'default'}
          onInvalid={(e: React.FormEvent<HTMLInputElement>) => {
            e.preventDefault();
            setEmailValidation('invalid');
          }}
          pattern="\S+@\S+\.\S+"
          ref={emailInputRef}
          inputStyleExtend={[c.input]}
          styleExtend={[c.textField]}
          type="email"
        />
      </form>
      <p className={s9(c.disclaimer)}>
        בהרשמה אני מסכימ/ה לתנאי השימוש באתר, וכן לקבלת דיוורים והצעות למנוי מאתר הארץ / TheMarker.
      </p>
    </>
  );
}
