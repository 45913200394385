'use client';
/* eslint-disable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
// 1. The import order of macros matter and they must be kept in this order
// 2. Since macros are transpiled out during build, it is okay for them
//   to be imported even when they are not used.
// -- color must always be first -- //
import color from '@haaretz/l-color.macro';
// ---
import fontStack from '@haaretz/l-font-stack.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import zIndex from '@haaretz/l-z-index.macro';
// --- These return objects and must be spread or used inside `merge` --- //
import border from '@haaretz/l-border.macro';
import shadow from '@haaretz/l-shadow.macro';
import typesetter from '@haaretz/l-type.macro';
// --- These must come last --- //
import fork from '@haaretz/l-fork.macro';
import mq from '@haaretz/l-mq.macro';
import merge from '@haaretz/l-merge.macro';
/* eslint-enable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */

import Button from '@haaretz/s-button';
import useBi from '@haaretz/s-use-bi';
import useImpressionObserver from '@haaretz/s-use-impression-observer';
import * as React from 'react';
import s9 from 'style9';

import ArticleLink from '../ArticleLink/ArticleLink';

import type { SeriesArticlesFragment } from '@haaretz/s-fragments/SeriesArticles';

// `c` is short for `classNames`
const c = s9.create({
  ulBlock: {
    display: 'inline',
  },
  ul: {
    display: 'grid',
    rowGap: space(4),
    ...merge(
      mq({
        from: 'xl',
        value: {
          rowGap: space(5),
        },
      })
    ),
  },
  li: {
    display: 'grid',
    alignContent: 'center',
    gridTemplateColumns: `${space(6)} minmax(0, 1fr)`,
    position: 'relative',
    '::before': {
      content: '""',
      width: space(2),
      height: space(2),
      borderRadius: radius('circle'),
      backgroundColor: color('neutral800'),
      alignSelf: 'start',
      marginTop: space(2),
      marginInlineEnd: space(3),
      ...merge(
        mq({
          from: 'xl',
          until: 'xxl',
          value: {
            marginTop: space(2.5),
          },
        }),
        mq({
          from: 'xxl',
          value: {
            marginTop: space(3),
          },
        })
      ),
    },
    '::after': {
      content: "''",
      right: '2.5px',
      top: space(5.8),
      bottom: space(5),
      borderRight: `3px solid ${color('neutral400')}`,
      height: '75%',
      position: 'absolute',
      ...merge(
        mq({
          from: 'xl',
          until: 'xxl',
          value: {
            top: space(6.5),
            right: space(0.8),
          },
        }),
        mq({
          from: 'xxl',
          value: {
            top: space(7),
            right: space(0.7),
          },
        })
      ),
    },
  },
  liLastChildBlock: {
    '::after': {
      content: "''",
    },
  },
  liBlock: {
    display: 'inline',
    color: color('primary1000'),
    '::after': {
      content: "'|'",
      color: color('neutral800'),
      fontWeight: 500,
      marginInlineStart: space(1),
      marginInlineEnd: space(1),
      fontSize: space(5),
    },
  },
  dashedLine: {
    position: 'absolute',
    top: space(5.8),
    right: space(0.5),
    height: space(8),
    ...merge(
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          right: space(0.8),
          top: space(6.5),
        },
      }),

      mq({
        from: 'xxl',
        value: {
          right: space(0.7),
          top: space(7),
        },
      })
    ),
  },
  liLastChildNoDash: {
    position: 'relative',
    '::after': {
      content: "''",
      borderRight: '0px',
    },
  },
  btn: {
    marginTop: space(4),
    ...merge(
      mq({
        from: 'l',
        value: {
          marginTop: space(6),
        },
      })
    ),
  },
});

export interface ArticlesSeriesUlProps {
  articles?: SeriesArticlesFragment['articles'];
  perPage?: SeriesArticlesFragment['perPage'];
  style?: SeriesArticlesFragment['style'];
  title?: SeriesArticlesFragment['title'];
}

export default function ArticlesSeriesUl({
  articles,
  perPage,
  style,
  title,
}: ArticlesSeriesUlProps) {
  const isBlockStyle = style === 'Block';
  const ulRef = React.useRef<HTMLUListElement | null>(null);
  const biAction = useBi();
  const [articlesToDisplay, setArticlesToDisplay] = React.useState<number>(
    isBlockStyle && articles?.length ? articles?.length : perPage || 3
  );

  useImpressionObserver({
    elementRef: ulRef,
    biData: {
      feature_type: 'Content',
      feature: 'Article Series',
      campaign_name: title || 'סדרת כתבות',
    },
  });

  if (!articles?.length) return null;
  const handleOnClick = () => {
    setArticlesToDisplay(articles.length);
    biAction({
      feature_type: 'Content',
      feature: 'Article Series',
      campaign_name: title || 'סדרת כתבות',
      action_id: 148,
    });
  };
  const showMore: boolean = articlesToDisplay !== articles.length;
  const lastLi = articlesToDisplay - 1;

  return (
    <>
      <ul className={s9(isBlockStyle ? c.ulBlock : c.ul)} ref={ulRef}>
        {articles.slice(0, articlesToDisplay).map(
          (article, index) =>
            !article.currentArticle && (
              <li
                className={s9(
                  isBlockStyle ? c.liBlock : c.li,
                  index === lastLi && !isBlockStyle && c.liLastChildNoDash,
                  index === lastLi && isBlockStyle && c.liLastChildBlock
                )}
                key={article.contentId}
              >
                {index === articlesToDisplay - 1 && showMore ? (
                  <svg width="16px" className={s9(c.dashedLine)}>
                    <line
                      x1="15"
                      y1="0"
                      x2="15"
                      y2="25"
                      stroke={color('neutral400')}
                      strokeWidth="4px"
                      strokeDasharray="10 10 3"
                    />
                  </svg>
                ) : null}
                {article ? (
                  <ArticleLink
                    listStyle={isBlockStyle ? 'Block' : 'List'}
                    url={article.url}
                    headline={article.headline}
                    credit={article.credit}
                  />
                ) : null}
              </li>
            )
        )}
      </ul>
      {showMore ? (
        <Button onClick={handleOnClick} styleExtend={[c.btn]}>
          {'טען עוד כתבות בנושא'}
        </Button>
      ) : null}
    </>
  );
}
