'use client';

import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import Icon from '@haaretz/s-icon';
import Popover from '@haaretz/s-popover';
import React from 'react';
import s9 from 'style9';

import type { CommentFragment } from '@haaretz/s-fragments/Comment';

const c = s9.create({
  authorDecorationWrapper: {
    marginInlineStart: space(1),
    marginInlineEnd: space(1),
    verticalAlign: '0.1em',
    backgroundColor: 'transparent',
  },
  goldStar: {
    color: color('sales500'),
  },
  silverStar: {
    color: color('neutral600'),
  },
  starPopover: {
    maxWidth: space(58),
    paddingTop: space(2),
    paddingRight: space(2),
    paddingBottom: space(2),
    paddingLeft: space(2),
    ...typesetter(-1),

    ...merge(
      // typesetter
      mq({ from: 'l', until: 'xl', value: { ...typesetter(0) } }),
      mq({ from: 'xxl', value: { ...typesetter(-2) } }),

      // maxWidth
      mq({ from: 'l', value: { maxWidth: space(92) } })
    ),
  },
  authorRate: {
    fontWeight: 700,
  },
});

export default function StarTooltip({ authorRate }: { authorRate: CommentFragment['authorRate'] }) {
  const ratingExplanationId = React.useId();
  const ratingStarRef = React.useRef<HTMLButtonElement>(null);

  if (!authorRate || authorRate < 60) return null;

  return (
    <>
      <button
        data-testid="rating-star"
        ref={ratingStarRef}
        className={s9(c.authorDecorationWrapper)}
        aria-labelledby={ratingExplanationId}
      >
        <Icon icon="star" styleExtend={[authorRate > 80 ? c.goldStar : c.silverStar]} />
      </button>
      <Popover
        refersToRef={ratingStarRef}
        kind="tooltip"
        styleExtend={[c.starPopover]}
        placement="top-start"
        offsetValue={{ crossAxis: space(-5) }}
        data-testid="rating-star-tooltip"
        keepInView
      >
        <span className={s9(c.authorRate)} id={ratingExplanationId}>
          {fork({ default: 'דירוג מגיב/ה:', hdc: 'Commentator rating' })} {authorRate}.{' '}
        </span>

        {fork({
          default: (
            <>
              הדירוג ניתן למנויי &quot;הארץ&quot; דיגיטל בלבד ומתבסס על כמות הלייקים בתגובותיהם. על
              מנת לקבל דירוג, יש לבחור כינוי קבוע
            </>
          ),
          hdc: (
            <>
              Rating is given to Haaretz digital subscribers and reflects their comments feeback. To
              get rated, select a nickname
            </>
          ),
        })}
      </Popover>
    </>
  );
}
