'use client';
import color from '@haaretz/l-color.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import Button from '@haaretz/s-button';
import Image from '@haaretz/s-htz-image';
import parseFullImageSrc from '@haaretz/s-image-utils/parseFullImageSrc';
import * as React from 'react';
import s9 from 'style9';

import type { Event, TeamData, SportMatchData } from '../../types';

const c = s9.create({
  showEvents: {
    animationDuration: '0.3s',
    animationName: s9.keyframes({
      from: {
        visibility: 'hidden',
        gridTemplateRows: '0fr',
      },
      to: {
        gridTemplateRows: '1fr',
      },
    }),
  },
  paddingTop: {
    ...merge(
      mq({
        from: 's',
        value: {
          paddingTop: 0,
        },
      })
    ),
  },

  rotateShowEventsIcon: {
    rotate: '270deg',
  },
  hideEvents: {
    animationDuration: '0.3s',
    animationName: s9.keyframes({
      from: {
        gridTemplateRows: '1fr',
      },
      to: {
        gridTemplateRows: '0fr',
      },
    }),
  },

  innerDiv: {
    minHeight: 0,
    height: '100%',
  },
  eventIcon: {
    width: space(8),
    ...merge(
      mq({
        from: 'xl',
        value: {
          width: space(9),
        },
      })
    ),
  },
  showEventsBtn: {
    justifySelf: 'center',
  },
  showEventsIcon: {
    transition: 'rotate 0.25s',
    rotate: '90deg',
  },
  eventRowBottomBorder: {
    borderBottomColor: color('sportsMatchEventsBottomBorder'),
    borderBottomStyle: 'solid',
    paddingBottom: space(1),
    borderBottomWidth: '1px',
  },
  eventRow: {
    alignItems: 'center',
    paddingTop: space(1),
    display: 'flex',
    alignContent: 'center',
    gap: space(1),
    ...merge(
      mq({
        from: 'xxl',
        value: {
          ...typesetter(-1),
        },
      })
    ),
  },
  eventsRowOpen: {
    gridTemplateRows: '1fr',
  },

  eventsTable: {
    display: 'grid',
    overflow: 'hidden',
    gridTemplateRows: '0fr',
  },
  teamLogo: {
    width: space(8),
    aspectRatio: '1/1',
    marginInlineEnd: space(1),
    ...merge(
      mq({
        from: 'xl',
        value: {
          width: space(9),
        },
      })
    ),
  },
  teamLogoVisitor: {
    marginInlineEnd: 0,
    marginInlineStart: space(1),
  },
  eventMinute: {
    fontWeight: 700,
  },
  seperator: {
    '::after': {
      content: '"•"',
      marginInlineStart: space(1),
    },
  },
  visitorSeparator: {
    '::before': {
      content: '"•"',
      marginInlineEnd: space(1),
    },
  },
  visitorTeam: {
    flexDirection: 'row-reverse',
    textAlign: 'end',
    textAlignLast: 'end',
  },
  localTeam: {
    flexDirection: 'row',
  },
});

export interface ShowEventsButtonProps {
  events: SportMatchData['events'];
  localTeam: TeamData;
  visitorTeam: TeamData;
}

interface EventRowProps {
  event: Event;
  localTeam: TeamData;
  visitorTeam: TeamData;
  icon?: string;
  uniqueEventOrder?: 'first' | 'last';
}

const allowedTypes = ['yellowredcard', 'own-goal', 'penalty', 'missed_penalty', 'redcard', 'goal'];

type AllowedEventsType =
  | 'yellowredcard'
  | 'own-goal'
  | 'penalty'
  | 'missed_penalty'
  | 'redcard'
  | 'goal';

function filterEventsByType(events: Event[]): Event[] {
  return events.filter(event => allowedTypes.includes(event.type));
}

function EventRow({ event, visitorTeam, icon, uniqueEventOrder }: EventRowProps) {
  const { minute, team_name, player_heb_name, player_name, team_heb_name } = event;
  const isVisitorTeam =
    team_name === visitorTeam.name ? true : team_heb_name === visitorTeam.heb_name;

  const imageData = icon ? parseFullImageSrc(icon) : null;
  return (
    <div
      className={s9(
        c.eventRow,
        uniqueEventOrder !== 'last' && c.eventRowBottomBorder,
        uniqueEventOrder === 'first' ? c.paddingTop : null,
        isVisitorTeam ? c.visitorTeam : c.localTeam
      )}
      data-testid={`event-row-${event.type}`}
    >
      {imageData ? (
        <Image
          __typename="htz_image_Image"
          alt={event.type}
          contentId={imageData.contentId}
          imgData={{
            __typename: 'ImageViewModel_ImageFile',
            ...imageData,
            height: imageData.height || 1,
            width: imageData.width || 1,
            crops: {
              __typename: 'ImageCrops',
            },
          }}
          type="image"
          aspect="square"
          sizes={[{ size: '96px' }]}
          widths={[96]}
          styleExtend={[c.eventIcon]}
          // This is important, it's a workaround for the icon
          // component to work on stage enviorment
          envOverride="prod"
        />
      ) : null}
      <span className={s9(c.eventMinute, isVisitorTeam ? c.visitorSeparator : c.seperator)}>
        {minute}׳
      </span>
      {player_heb_name ? player_heb_name : player_name}
    </div>
  );
}

export default function ShowEventsButton({
  events,
  localTeam,
  visitorTeam,
}: ShowEventsButtonProps) {
  const [showEvents, setShowEvents] = React.useState<boolean>(false);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const filteredEvents = filterEventsByType(events.data);

  if (filteredEvents.length === 0) return null;

  const onClick = () => {
    if (!showEvents) setShowEvents(true);
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Button
        endIcon={'chevron'}
        priority="tertiary"
        variant="neutral"
        endIconStyleExtend={[c.showEventsIcon, isOpen && c.rotateShowEventsIcon]}
        styleExtend={[c.showEventsBtn]}
        onClick={onClick}
      >
        אירועים מרכזיים
      </Button>
      {showEvents ? (
        <div
          className={s9(
            c.eventsTable,
            isOpen ? c.showEvents : c.hideEvents,
            isOpen && c.eventsRowOpen
          )}
        >
          <div className={s9(c.innerDiv)}>
            {filteredEvents.map((event, index) => {
              const uniqueEventOrder =
                index === 0 && index !== filteredEvents.length - 1
                  ? 'first'
                  : index === filteredEvents.length - 1
                  ? 'last'
                  : undefined;

              const iconPath: string | undefined = events.icons[event.type as AllowedEventsType];
              return (
                <EventRow
                  key={event.id}
                  event={event}
                  uniqueEventOrder={uniqueEventOrder}
                  localTeam={localTeam}
                  icon={iconPath}
                  visitorTeam={visitorTeam}
                />
              );
            })}
          </div>
        </div>
      ) : null}
    </>
  );
}
