'use client';

import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import Button from '@haaretz/s-button';
import { DropdownWithMemo as Dropdown } from '@haaretz/s-dropdown';
import LogoLoadingIndicator from '@haaretz/s-logo-loading-indicator';
import useBi from '@haaretz/s-use-bi';
import useImpressionObserver from '@haaretz/s-use-impression-observer';
import useIntersectionObserver from '@haaretz/s-use-intersection-observer/common';
import * as React from 'react';
import s9 from 'style9';

import { useCommentsActionsContext, useCommentsContext } from '../CommentsContextProvider';
import getComments from '../server-actions/getComments';

import type { Option } from '@haaretz/s-dropdown';
import type { SortTypes } from '@haaretz/s-fragments/Types';

const COMMENTS_PAGINATION_COUNT = 100;
const INITIAL_COMMENTS_COUNT = 10;

// `c` is short for `classNames`
const c = s9.create({
  dropdown: {
    maxWidth: space(47),
    marginTop: space(7),

    ...merge(
      mq({
        from: 'm',
        value: {
          gridColumnStart: 2,
          gridColumnEnd: 3,
          gridRowStart: 2,
          gridRowEnd: 3,
        },
      }),
      mq({ from: 'l', value: { maxWidth: space(54) } })
    ),
  },
  dropdownText: {
    ...merge(
      mq({ from: 'l', until: 'xl', value: { ...typesetter(1) } }),
      mq({ from: 'xxl', value: { ...typesetter(-1) } })
    ),
  },
  loader: {
    width: '100%',
    justifyContent: 'center',
    marginTop: space(5),
    fontSize: space(20),

    gridColumnStart: 2,
    gridColumnEnd: 3,
  },
  getMoreCommentsBtn: {
    display: 'block',
    marginInlineStart: 'auto',
    marginInlineEnd: 'auto',
  },
  hideDropdown: {
    display: 'none',
  },
  commentsList: {
    display: 'grid',
    gridColumnEnd: 3,
    gridColumnStart: 2,
    gridRowEnd: 4,
    gridRowStart: 3,
    marginTop: space(4),
    rowGap: space(8),
  },
});

type FetchType = 'getMoreComments' | 'initialCommentsCount' | 'idle';

export interface CommentsListWrapperProps {
  commentsElementId: string;
  articleId: string;
}

interface CommentsListProps extends CommentsListWrapperProps {
  selectedOption: number | undefined;
  fetchType: FetchType;
  setFetchType: React.Dispatch<React.SetStateAction<FetchType>>;
  isPending: boolean;
  startTransition: React.TransitionStartFunction;
  commentsList: JSX.Element[];
  setCommentsList: React.Dispatch<React.SetStateAction<JSX.Element[]>>;
  totalHits: number | null;
  setTotalHits: React.Dispatch<React.SetStateAction<number | null>>;
}

type SortOption = Option & { value: SortTypes };
const sortOptions: SortOption[] = [
  { text: fork({ default: 'מהאחרונה לראשונה', hdc: 'Newest first' }), value: 'lastToFirst' },
  { text: fork({ default: 'מהראשונה לאחרונה', hdc: 'Oldest first' }), value: 'firstToLast' },
  { text: fork({ default: 'הצג לפי דירוג', hdc: 'Top-rated first' }), value: 'rate' },
  { text: fork({ default: 'בחירת העורכים', hdc: 'Editors choice' }), value: 'editorsPick' },
];

function CommentsList({
  commentsElementId,
  articleId,
  selectedOption,
  fetchType,
  isPending,
  commentsList,
  totalHits,
  setFetchType,
  startTransition,
  setCommentsList,
  setTotalHits,
}: CommentsListProps) {
  const [cursor, setCursor] = React.useState<string | null>(null);
  const loaderRef = React.useRef(null);
  const firstCommentRef = React.useRef(null);
  const { isFromEmailNotification, commentIdParam, subcommentIdParam, isInView } =
    useCommentsContext();
  const { setIsInView } = useCommentsActionsContext();
  const singleCommentLoadedRef = React.useRef(false);

  const biAction = useBi();

  useImpressionObserver({
    elementRef: firstCommentRef,
    biData: {
      feature: 'Scroll',
      feature_type: 'Content',
      campaign_name: 'Talkbacks',
    },
    config: {
      threshold: 0.06,
    },
    disabled: commentsList.length === 0,
  });

  const initGetComments = React.useCallback(
    (entries: IntersectionObserverEntry[]) => {
      if (entries[0].isIntersecting) setIsInView(true);
    },
    [setIsInView]
  );

  const observer = useIntersectionObserver({
    cb: initGetComments,
    rootMargin: '2000px',
  });

  React.useEffect(() => {
    const elem = loaderRef?.current;
    if (elem && observer) observer.observe(elem);

    return () => {
      if (elem && observer) observer.unobserve(elem);
    };
  }, [loaderRef, observer]);

  // Get single comment data - occurs only when user arrives from email notification
  React.useEffect(() => {
    if (
      !isPending &&
      isFromEmailNotification &&
      !singleCommentLoadedRef.current &&
      fetchType !== 'idle'
    ) {
      startTransition(async () => {
        try {
          const res = await getComments({
            id: commentsElementId,
            count: 1,
            cursor: null,
            sort: 'lastToFirst',
            commentId: commentIdParam as string,
            articleId,
            subcommentIdParam,
            loadedCount: 0,
            postCacheParams: ['sa-comments', commentsElementId, commentIdParam || ''],
          });

          if (!!res.html.length && res.totalHits) {
            setTotalHits(res.totalHits);
            setCommentsList(res.html);
            setFetchType('idle');
            singleCommentLoadedRef.current = true;
          }
        } catch (error) {
          console.error(error);
        }
      });
    }
  }, [
    articleId,
    commentIdParam,
    commentsElementId,
    isFromEmailNotification,
    setCommentsList,
    startTransition,
    subcommentIdParam,
    fetchType,
    setFetchType,
    isPending,
    totalHits,
    setTotalHits,
  ]);

  // Get comments data -  for initial fetch and when user loads more comments
  // or when user uses the sort dropdown
  React.useEffect(() => {
    // Does not run on initial fetch when user arrives from email notification
    if (isFromEmailNotification && !singleCommentLoadedRef.current) return;

    if (!isPending && isInView && fetchType !== 'idle') {
      startTransition(async () => {
        try {
          const res = await getComments({
            id: commentsElementId,
            count:
              fetchType === 'getMoreComments' ? COMMENTS_PAGINATION_COUNT : INITIAL_COMMENTS_COUNT,
            cursor: fetchType === 'getMoreComments' ? cursor : '',
            sort: selectedOption != null ? sortOptions[selectedOption].value : 'lastToFirst',
            articleId,
            loadedCount: fetchType === 'getMoreComments' ? commentsList.length : 0,
            postCacheParams: ['sa-comments', commentsElementId],
            prevTotalHits: totalHits,
          });
          const totalHitsData = res?.totalHits;
          // No comments to load
          if (!totalHitsData) {
            setFetchType('idle');
          }

          // Load initial comments or get more comments
          else if (totalHitsData) {
            setTotalHits(totalHitsData);
            setFetchType('idle');
            setCursor(res.next);

            if (fetchType === 'getMoreComments') {
              setCommentsList(prev => [...prev, ...res.html]);
            } else {
              setCommentsList(res.html);
            }
          }
        } catch (error) {
          console.error(error);
        }
      });
    }
  }, [
    cursor,
    isInView,
    isFromEmailNotification,
    startTransition,
    selectedOption,
    articleId,
    setCommentsList,
    commentsElementId,
    isPending,
    fetchType,
    setFetchType,
    commentsList.length,
    totalHits,
    setTotalHits,
  ]);

  if (!isInView || (isPending && fetchType === 'initialCommentsCount')) {
    return <LogoLoadingIndicator styleExtend={[c.loader]} ref={loaderRef} />;
  }

  const getMoreCommentsHandler = () => {
    setFetchType('getMoreComments');
    biAction({
      feature: 'Talkbacks',
      feature_type: 'Content',
      action_id: 148,
    });
  };

  const showGetMoreCommentsBtn =
    isFromEmailNotification && commentsList.length === 0
      ? true
      : totalHits && totalHits > commentsList.length;

  return (
    <div data-testid="comments-list" ref={firstCommentRef} className={s9(c.commentsList)}>
      {commentsList}

      {showGetMoreCommentsBtn ? (
        <Button
          styleExtend={[c.getMoreCommentsBtn]}
          onClick={getMoreCommentsHandler}
          data-testid="get-more-comments-btn"
          {...(isPending
            ? {
                state: 'busy',
                busyNotice: fork({ default: 'טוען...', hdc: 'loading...' }),
              }
            : { state: 'auto' })}
        >
          {fork({ default: 'עוד תגובות', hdc: 'More comments' })}
        </Button>
      ) : null}
    </div>
  );
}

const campaignNameBySelectedOption = [
  'Date Descending',
  'Date Ascending',
  'Comment Rating',
  'Editors Pick',
];

export default function CommentsListWrapper({
  articleId,
  commentsElementId,
}: CommentsListWrapperProps) {
  const [selectedOption, setSelectedOption] = React.useState<number | undefined>(0);
  const [fetchType, setFetchType] = React.useState<FetchType>('initialCommentsCount');
  const [isPending, startTransition] = React.useTransition();
  const [commentsList, setCommentsList] = React.useState<JSX.Element[]>([]);
  const { isFromEmailNotification } = useCommentsContext();
  const [totalHits, setTotalHits] = React.useState<number | null>(null);

  const biAction = useBi();

  const onChange = React.useCallback(
    (selected?: number) => {
      setSelectedOption(selected);
      setFetchType('initialCommentsCount');
      setTotalHits(null);
      biAction({
        feature: 'Sort talkbacks',
        feature_type: 'Content',
        campaign_name:
          selected != null ? campaignNameBySelectedOption[selected] : 'Date Descending',
        action_id: 104,
      });
    },
    [biAction]
  );

  return (
    <>
      {isFromEmailNotification || commentsList.length <= 1 ? null : (
        <Dropdown
          options={sortOptions}
          label={fork({ default: 'הצג לפי', hdc: 'Sort comment by' })}
          styleExtend={[c.dropdown, isPending && c.hideDropdown]}
          onChange={onChange}
          selectStyleExtend={[c.dropdownText]}
          optionsWrapperStyleExtend={[c.dropdownText]}
          initialSelectedOption={selectedOption}
          data-testid="sort-dropdown"
        />
      )}
      <CommentsList
        articleId={articleId}
        commentsElementId={commentsElementId}
        selectedOption={selectedOption}
        fetchType={fetchType}
        setFetchType={setFetchType}
        startTransition={startTransition}
        isPending={isPending}
        commentsList={commentsList}
        setCommentsList={setCommentsList}
        totalHits={totalHits}
        setTotalHits={setTotalHits}
      />
    </>
  );
}
