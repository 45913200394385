'use client';

import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import usePlatform from '@haaretz/s-atoms/platform';
import useReadingHistoryCount from '@haaretz/s-atoms/readingHistoryCount';
import useUser from '@haaretz/s-atoms/user';
import Button from '@haaretz/s-button';
import Card from '@haaretz/s-card';
import Icon from '@haaretz/s-icon';
import TextLink from '@haaretz/s-text-link';
import useImpressionObserver from '@haaretz/s-use-impression-observer';
import * as React from 'react';
import s9 from 'style9';

import AnonymousTextField from './components/AnonymousTextFeild/AnonymousTextField';
import RegistrationElement from './components/RegistrationElement/RegistrationElement';

import type { RegistrationRichTextElementFragment } from '@haaretz/s-fragments/RegistrationRichTextElement';
import type { StyleExtend, InlineStyles } from '@haaretz/s-types';

// `c` is short for `classNames`
const c = s9.create({
  base: {
    backgroundImage: `linear-gradient(132deg, ${color('newsletterRegGradStart')} 2%, ${color(
      'newsletterRegGradEnd'
    )} 99%)` as string,
    border: 'none',
    gridColumnEnd: 'main-end',
    gridColumnStart: 'main-start',
    marginTop: space(2),
    marginBottom: space(2),
    paddingBottom: space(9),
    paddingInlineEnd: space(9),
    paddingInlineStart: space(9),
    paddingTop: space(9),
    width: '100%',
    ...merge(
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          marginTop: space(4),
          marginBottom: space(4),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          marginTop: space(7),
          marginBottom: space(7),
        },
      })
    ),
  },
  oneClickBase: {
    ...merge(
      mq({
        from: 's',
        until: 'l',
        value: {
          paddingInlineEnd: space(10),
          paddingInlineStart: space(10),
        },
      }),
      mq({
        from: 'l',
        until: 'xl',
        value: {
          paddingInlineEnd: space(12),
          paddingInlineStart: space(12),
        },
      }),
      mq({
        from: 'xl',
        value: {
          paddingInlineEnd: space(15),
          paddingInlineStart: space(15),
        },
      })
    ),
  },
  anonymousBase: {
    ...merge(
      mq({
        from: 's',
        until: 'l',
        value: {
          paddingInlineEnd: space(10),
          paddingInlineStart: space(10),
        },
      }),
      mq({
        from: 'l',
        until: 'xl',
        value: {
          paddingInlineEnd: space(12),
          paddingInlineStart: space(12),
        },
      }),
      mq({
        from: 'xl',
        value: {
          paddingInlineEnd: space(15),
          paddingInlineStart: space(15),
        },
      })
    ),
  },
  textWrapper: {
    ...merge(
      mq({
        from: 's',
        until: 'm',
        value: {
          maxWidth: space(90),
        },
      }),
      mq({
        from: 'm',
        until: 'l',
        value: {
          maxWidth: space(105),
        },
      }),
      mq({
        from: 'l',
        until: 'xl',
        value: {
          maxWidth: space(85),
        },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          maxWidth: space(100),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          maxWidth: space(120),
        },
      })
    ),
  },
  title: {
    color: color('neutral1200'),
    ...typesetter(5),
    // ADDED this on XXl check other mq
    marginBottom: space(1),
    //
    ...merge(
      mq({
        from: 's',
        until: 'l',
        value: {
          ...typesetter(4),
        },
      })
    ),
  },
  subTitle: {
    fontWeight: 300,
    color: color('neutral1100'),
    ...typesetter(3),
    ...merge(
      mq({
        from: 's',
        until: 'l',
        value: {
          ...typesetter(2),
        },
      })
    ),
  },
  thanksWrapper: {
    ...merge(
      mq({
        from: 's',
        value: {
          paddingInlineEnd: space(9),
          paddingInlineStart: space(9),
        },
      })
    ),
  },

  checkCircle: {
    marginInlineEnd: space(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: space(9),
    aspectRatio: '1',
    borderRadius: radius('circle'),
    backgroundColor: color('primary1000'),
    fontSize: space(6),
    ...merge(
      mq({
        from: 's',
        value: {
          display: 'none',
        },
      })
    ),
  },

  thanks: {
    ...typesetter(5),
    marginTop: space(3),
    ...merge(
      mq({
        from: 's',
        value: {
          marginTop: 0,
        },
      }),
      mq({
        from: 's',
        until: 'xl',
        value: {
          ...typesetter(4),
        },
      })
    ),
  },
  pendingWithGmailTxt: {
    marginTop: space(3),
    ...typesetter(1),
  },
  pendingTxt: {
    marginTop: space(3),
    ...typesetter(1),
    ...merge(
      mq({
        from: 's',
        value: {
          marginTop: 0,
          ...typesetter(4),
          fontWeight: 300,
          paddingInlineEnd: space(15),
        },
      })
    ),
  },
  disclaimer: {
    color: color('neutral1000'),
    ...typesetter(-2),
    marginTop: space(23),
  },
  textLinkMoreNewsletters: {
    display: 'inline-block',
    marginTop: space(3),
    ...typesetter(1),
    ...merge(
      mq({
        from: 's',
        value: {
          display: 'none',
        },
      })
    ),
  },
  btnMoreNewsletters: {
    marginTop: space(4),
  },
  successTxt: {
    display: 'none',
    ...merge(
      mq({
        from: 's',
        value: {
          display: 'block',
          marginTop: 0,
          ...typesetter(4),
          fontWeight: 300,
        },
      }),
      mq({
        from: 's',
        until: 'l',
        value: {
          paddingInlineEnd: space(17),
        },
      }),
      mq({
        from: 'l',
        value: {
          paddingInlineEnd: space(10),
        },
      })
    ),
  },
});
export interface NewsletterRegistrationClientProps extends RegistrationRichTextElementFragment {
  frequency?: RegistrationRichTextElementFragment['frequency'];
  /**
   * CSS declarations to be set as inline `style` on the
   * html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   * const { styleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyNewsletterSubscriptionDialog(props) {
   *   const inlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <NewsletterSubscriptionDialog
   *      styleExtend={[ styleExtend, ]}
   *      inlineStyle={inlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  inlineStyle?: InlineStyles;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <NewsletterSubscriptionDialog styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
}

export type RegistrationStatus = 'notRegistered' | 'success' | 'pending';

export type EmailProperties = {
  isGmail?: boolean | undefined;
  email?: string | null;
};

const pendingEmailLinkForGmail = (signUpEmail: string) =>
  `https://mail.google.com/mail/u/${signUpEmail}/#search/from%3A(sysadmin@mc.haaretz.com)+in%3Aanywhere+newer_than%3A1h`;

const moreNewslettersLink = fork({
  default: 'http://bit.ly/newsletters-htz',
  tm: 'http://bit.ly/newsletters-tm',
  hdc: 'http://bit.ly/newsletters-hdc',
});

export default function NewsletterRegistrationView({
  inlineStyle,
  styleExtend = [],
  frequency,
  ...props
}: NewsletterRegistrationClientProps) {
  const { title, subscribersOnly, subtitle, resourceId, type, contentId, resourceType } = props;
  const [registrationStatus, setRegistrationStatus] =
    React.useState<RegistrationStatus>('notRegistered');
  const [emailProperties, setEmailProperties] = React.useState<EmailProperties>({
    isGmail: false,
    email: undefined,
  });
  const elementRef = React.useRef<HTMLDivElement>(null);
  const platform = usePlatform();
  const readingHistoryCount = useReadingHistoryCount();
  const user = useUser('serverValue');
  const isAnonymousFlow = user.userType === 'anonymous' && !subscribersOnly;

  useImpressionObserver({
    elementRef,
    biData: {
      newsletter_segment_id: resourceId ? resourceId : contentId,
      newsletter_segment_name: title || 'newsletter',
      feature_type: 'Content',
      feature: type === 'personal' ? 'Personal-Newsletter' : 'Regular-Newsletter',
      personal_feature: type === 'personal',
    },
  });

  const freqShouldDisplay = frequency === 0 || !frequency || readingHistoryCount % frequency === 0;

  if ((platform === 'app' || !freqShouldDisplay) && resourceType !== 'writer') return null;
  return (
    <Card
      styleExtend={[c.base, isAnonymousFlow ? c.anonymousBase : c.oneClickBase, ...styleExtend]}
      ref={elementRef}
      inlineStyle={inlineStyle}
    >
      {registrationStatus === 'notRegistered' ? (
        <>
          <div className={s9(c.textWrapper)}>
            <h3 className={s9(c.title)}>{title}</h3>
            <p className={s9(c.subTitle)}>{subtitle}</p>
          </div>
          {isAnonymousFlow ? (
            <AnonymousTextField
              title={title || ''}
              newsletterType={type}
              setEmailProperties={setEmailProperties}
              resourceId={resourceId}
              resourceType={resourceType}
              setIsRegistered={setRegistrationStatus}
            />
          ) : (
            <RegistrationElement
              resourceType={resourceType}
              resourceId={resourceId}
              setEmailProperties={setEmailProperties}
              userType={user.userType}
              userEmail={user.userMail}
              anonymousId={user.anonymousId}
              setIsRegistered={setRegistrationStatus}
              subscribersOnly={subscribersOnly}
              newsletterType={type}
              title={title || ''}
            />
          )}
        </>
      ) : (
        <div className={s9(c.thanksWrapper)}>
          <span className={s9(c.checkCircle)}>
            <Icon icon="check" variant="inverse" />
          </span>
          <h3 className={s9(c.thanks)}>תודה שנרשמת!</h3>
          {registrationStatus === 'success' ? (
            <>
              <p className={s9(c.successTxt)}>יש לנו דיוורים נוספים שעשויים לעניין אותך</p>
              <Button
                as="a"
                href={moreNewslettersLink}
                target="_blank"
                rel="noopener noreferrer"
                styleExtend={[c.btnMoreNewsletters]}
              >
                לדיוורים נוספים
              </Button>
            </>
          ) : null}
          {registrationStatus === 'pending' ? (
            <>
              {emailProperties.isGmail && emailProperties.email ? (
                <p className={s9(c.pendingWithGmailTxt)}>
                  לאימות כתובתך{' '}
                  <TextLink
                    href={pendingEmailLinkForGmail(emailProperties.email)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    יש ללחוץ על הלינק באימייל
                  </TextLink>
                </p>
              ) : (
                <p className={s9(c.pendingTxt)}>לאימות כתובת המייל יש ללחוץ על הלינק באימייל</p>
              )}
              <p className={s9(c.disclaimer)}>
                בהרשמה אני מסכימ/ה לתנאי השימוש באתר, וכן לקבלת דיוורים והצעות למנוי מאתר הארץ /
                TheMarker.
              </p>
            </>
          ) : null}
        </div>
      )}
    </Card>
  );
}
