'use client';

import space from '@haaretz/l-space.macro';
import dynamic from 'next/dynamic';
import React from 'react';
import s9 from 'style9';

import { useReportAbuseContext, useReportAbuseActionsContext } from './ReportAbuseContext';

import type { ReCAPTCHA as ReCAPTCHAType } from 'react-google-recaptcha';

const c = s9.create({
  recaptcha: {
    display: 'none',
    justifyContent: 'flex-end',
    marginTop: space(2),
  },
  showRecaptcha: {
    display: 'flex',
  },
});

const ReCAPTCHA = dynamic(() => import('@haaretz/s-recaptcha').then(x => x.default), {
  ssr: false,
});

export default function ReCAPTCHAClient() {
  const { reportAbuseStatus, shouldInitCaptcha } = useReportAbuseContext();
  const { setReportAbuseStatus, setCaptchaKey, setShouldInitCaptcha, setBtnState } =
    useReportAbuseActionsContext();

  const recaptchaOnSuccess = (token: string | null) => {
    setCaptchaKey(token);
  };

  const recaptchaOnError = () => {
    setReportAbuseStatus('error');
    setShouldInitCaptcha(false);
    setBtnState('auto');
  };

  const recaptchaRefCb = React.useCallback(
    (node: ReCAPTCHAType) => {
      if (node !== null && shouldInitCaptcha) {
        node.execute();
      }
    },
    [shouldInitCaptcha]
  );

  if (!shouldInitCaptcha) return null;

  return (
    <ReCAPTCHA
      className={s9(c.recaptcha, reportAbuseStatus === 'success' && c.showRecaptcha)}
      sitekey="6LcC3usUAAAAAByOPVWv3pn9KXAwstot5vYjk1Gb"
      onChange={recaptchaOnSuccess}
      onErrored={recaptchaOnError}
      size="invisible"
      forwardedRef={recaptchaRefCb}
      badge="inline"
    />
  );
}
