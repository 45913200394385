'use server';
import { fetchTenRecipesServerActionQuery } from '@haaretz/s-server-queries/TenRecipes.server.action';

import ArticleTeaser from '../components/ArticleTeaser/ArticleTeaser';

import type { BaseServerActionParams } from '@haaretz/s-types';

const defaultResponse = {
  next: null,
  html: [] as JSX.Element[],
} as const;

export default async function loadMore({
  contentId,
  lastTeaserId,
  remainingArticlesNum,
}: {
  contentId: string | null;
  lastTeaserId: string | null;
  remainingArticlesNum?: number | null;
} & BaseServerActionParams): Promise<{
  next: string | null;
  html: JSX.Element[];
}> {
  if (!lastTeaserId) return defaultResponse;

  if (!contentId) {
    console.error('TenRecipes contentId is required');

    return defaultResponse;
  }

  try {
    const result = await fetchTenRecipesServerActionQuery({
      id: contentId,
      cursor: lastTeaserId,
      count: remainingArticlesNum || 12,
    })();

    const items = result.TenRecipes?.items;

    if (!Array.isArray(items) || items.length === 0) return defaultResponse;

    const spare = items.length % 3;

    return {
      next: items.at(-1)?.contentId || null,
      html: items.map((item, index) => {
        if (spare === 1) {
          if (index === items.length - 1) {
            return <ArticleTeaser key={item.contentId} {...item} orphanKind={'single'} />;
          }
        } else if (spare === 2) {
          if (index === items.length - 1) {
            return <ArticleTeaser key={item.contentId} {...item} orphanKind={'dual2'} />;
          }
          if (index === items.length - 2) {
            return <ArticleTeaser key={item.contentId} {...item} orphanKind={'dual1'} />;
          }
        }
        return <ArticleTeaser key={item.contentId} {...item} />;
      }),
    };
  } catch (error) {
    console.error(`10 article server action failed: ${(error as Error).message}`);

    return defaultResponse;
  }
}
