'use client';
import * as React from 'react';
import s9 from 'style9';

import type { GifsFragment } from '@haaretz/s-fragments/embeds/Gifs.embeds';
import type { GiphyFragment } from '@haaretz/s-fragments/embeds/Giphy.embeds';
import type { StyleExtend } from '@haaretz/s-types';

// `c` is short for `classNames`
const c = s9.create({
  iframe: {
    borderWidth: 0,
    aspectRatio: '1/1',
    width: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
  },
  wrapper: {
    width: '100%',
    aspectRatio: '1/1',
    position: 'relative',
  },
  defaultRatio: { aspectRatio: 'auto' },
  relative: { position: 'relative' },
  hidden: { visibility: 'hidden' },
});

export interface GiphyProps
  extends Omit<GiphyFragment, '__typename'>,
    Omit<GifsFragment, '__typename'> {
  styleExtend?: StyleExtend;
  children?: React.ReactNode;
  __typename: GiphyFragment['__typename'] | GifsFragment['__typename'];
}

export default function Giphy({ src, width, height, children, __typename }: GiphyProps) {
  const [showPlaceHolder, setShowPlaceHolder] = React.useState<boolean>(true);

  const iframeRef = React.useRef<HTMLIFrameElement | null>(null);
  const wrapperRef = React.useRef<HTMLDivElement | null>(null);
  React.useEffect(() => {
    const onLoad = () => {
      setShowPlaceHolder(false);
    };
    const iframe = iframeRef.current;
    if (iframe) {
      if (src) {
        iframe.src = src;
      }
      iframe.addEventListener('load', onLoad);

      return () => {
        iframe.removeEventListener('load', onLoad);
      };
    }
    return () => null;
  }, [src]);

  React.useEffect(() => {
    if (iframeRef.current && wrapperRef.current && width && height) {
      iframeRef.current.style.aspectRatio = `${width}/${height}`;
      wrapperRef.current.style.aspectRatio = `${width}/${height}`;
    }
  }, [height, width]);

  if (!src) return null;
  return (
    <div ref={wrapperRef} className={s9(c.wrapper)}>
      {showPlaceHolder && children}
      <iframe
        ref={iframeRef}
        className={s9(c.iframe, showPlaceHolder ? c.hidden : c.relative)}
        src={src}
        title={`${__typename}-${src}`}
        allowFullScreen
        loading="lazy"
      />
    </div>
  );
}
