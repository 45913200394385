'use client';

import React from 'react';

import CommentForm from '../../CommentForm';
import { useCommentsActionsContext, useCommentsContext } from '../../CommentsContextProvider';

type CommentFormClientProps = {
  articleId: string;
  commentId: string;
};

export default function CommentFormClient({ articleId, commentId }: CommentFormClientProps) {
  const { replyToCommentId, replyToAuthor } = useCommentsContext();
  const { cancelReplyForm } = useCommentsActionsContext();

  const commentFormRefCb = React.useCallback((node: HTMLFormElement) => {
    if (node !== null) {
      node.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, []);

  if (replyToCommentId !== commentId) return null;

  return (
    <CommentForm
      isReplyForm
      articleId={articleId}
      cancelReplyForm={cancelReplyForm}
      ref={commentFormRefCb}
      replyTo={replyToAuthor}
      parentCommentId={commentId}
    />
  );
}
