'use client';

/* eslint-disable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
// 1. The import order of macros matter and they must be kept in this order
// 2. Since macros are transpiled out during build, it is okay for them
//   to be imported even when they are not used.
// -- color must always be first -- //
import color from '@haaretz/l-color.macro';
// ---
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
// --- These return objects and must be spread or used inside `merge` --- //
import border from '@haaretz/l-border.macro';
import typesetter from '@haaretz/l-type.macro';
// --- These must come last --- //
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
/* eslint-enable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */

import ClickArea from '@haaretz/s-click-area';
import Dialog from '@haaretz/s-dialog';
import Icon from '@haaretz/s-icon';
import React from 'react';
import s9 from 'style9';

import { CommentFormProps, DialogType } from '../CommentForm';

import type { StyleExtend } from '@haaretz/s-types';

// `c` is short for `classNames`
const c = s9.create({
  base: {
    minHeight: space(60),
    paddingRight: space(6),
    paddingLeft: space(6),
    marginTop: space(3),
    position: 'relative',
    backgroundColor: color('neutral200'),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: radius('xLarge'),

    ...border({
      style: 'none',
      side: 'all',
    }),

    ...mq({ from: 'xxl', value: { minHeight: space(69.5) } }),
  },
  closeBtn: {
    position: 'absolute',
    top: space(2),
    insetInlineEnd: space(2),
  },
});

export type DialogWrapperProps = {
  /** The Children to be rendered inside `<FormStage>` */
  children?: React.ReactNode;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <Button styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
  dialogType: DialogType;
  setDialogType: React.Dispatch<React.SetStateAction<DialogType>>;
  setErrorNameField: React.Dispatch<React.SetStateAction<string | null>>;
  isReplyForm?: CommentFormProps['isReplyForm'];
  cancelReplyForm?: CommentFormProps['cancelReplyForm'];
};

export type SharedWrapperProps = Pick<
  DialogWrapperProps,
  'dialogType' | 'setDialogType' | 'setErrorNameField'
>;

export default function DialogWrapper({
  children,
  dialogType,
  setDialogType,
  setErrorNameField,
  isReplyForm,
  cancelReplyForm,
  styleExtend = [],
}: DialogWrapperProps) {
  React.useEffect(() => {
    return () => {
      setErrorNameField(null);
    };
  }, [setErrorNameField]);

  const onClick = () => {
    if (isReplyForm && cancelReplyForm) cancelReplyForm();
    setDialogType('none');
  };

  return (
    <Dialog styleExtend={[c.base, ...styleExtend]} isOpen={dialogType !== 'none'}>
      <ClickArea onClick={onClick} styleExtend={[c.closeBtn]}>
        <Icon icon="close" />
      </ClickArea>
      {children}
    </Dialog>
  );
}
