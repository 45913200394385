'use client';

/* eslint-disable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
// 1. The import order of macros matter and they must be kept in this order
// 2. Since macros are transpiled out during build, it is okay for them
//   to be imported even when they are not used.
// -- color must always be first -- //
import color from '@haaretz/l-color.macro';
// ---
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
// --- These return objects and must be spread or used inside `merge` --- //
import border from '@haaretz/l-border.macro';
import typesetter from '@haaretz/l-type.macro';
// --- These must come last --- //
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
/* eslint-enable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */

import Button from '@haaretz/s-button';
import React from 'react';
import s9 from 'style9';

import DialogWrapper, { SharedWrapperProps } from './DialogWrapper';

// `c` is short for `classNames`
const c = s9.create({
  errorStageWrapper: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingTop: space(8),
    paddingBottom: space(8),
    width: '100%',
  },
  errorTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: space(5),
  },
  bold: {
    fontWeight: 700,
  },
});

type ErrorStageProps = {
  type?: 'generic' | 'used';
  sharedWrapperProps: SharedWrapperProps;
};

const textByType = {
  generic: {
    text: fork({
      default: 'משהו השתבש והתגובה לא נקלטה במערכת שלנו.',
      hdc: 'Something went wrong an your comment was not submitted to our site',
    }),
    boldText: fork({ default: 'שננסה שוב?', hdc: 'Try again?' }),
  },
  used: {
    text: fork({ default: 'השם שנבחר כבר נמצא בשימוש.', hdc: 'The nickname is already in use' }),
    boldText: fork({ default: 'אנא בחרו שם אחר', hdc: 'Please pick a different nickname' }),
  },
};

export default function ErrorStage({ type = 'generic', sharedWrapperProps }: ErrorStageProps) {
  return (
    <DialogWrapper {...sharedWrapperProps} styleExtend={[c.errorStageWrapper]}>
      <div className={s9(c.errorTextWrapper)}>
        {textByType[type].text}
        <span className={s9(c.bold)}>{textByType[type].boldText}</span>
      </div>
      <Button onClick={() => sharedWrapperProps.setDialogType('none')}>חזרה</Button>
    </DialogWrapper>
  );
}
