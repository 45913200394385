'use client';

import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import radius from '@haaretz/l-radius.macro';
import shadow from '@haaretz/l-shadow.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import usePlatform from '@haaretz/s-atoms/platform';
import ClickArea from '@haaretz/s-click-area';
import Icon from '@haaretz/s-icon';
import useBi from '@haaretz/s-use-bi';
import VisuallyHidden from '@haaretz/s-visually-hidden';
import * as React from 'react';
import s9 from 'style9';

import type { StyleExtend, InlineStyles } from '@haaretz/s-types';

// `c` is short for `classNames`
const c = s9.create({
  base: {},
  shareBtn: {
    justifySelf: 'end',
    alignSelf: 'center',
    width: space(7),
    height: space(7),
  },
  shareBtnIcon: {
    fontSize: space(4),
    '--icon-color-override': color('neutral1300'),
  },
  clickAreaColor: {
    backgroundColor: color('primary400'),
    ':hover': {
      backgroundColor: color('primary600'),
    },
    ':focus': {
      backgroundColor: color('primary600'),
      ...shadow('medium'),
    },
  },

  clickAreaGeneralStyling: {
    borderRadius: radius('circle'),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  coppiedSuccess: {
    backgroundColor: color('quaternary400'),
  },
  copyBtn: {
    width: space(9),
    height: space(9),
  },
  copyText: {
    animationDuration: '0.3s',
    animationName: s9.keyframes({
      from: { opacity: 0 },
      to: { opacity: 1 },
    }),
    ...typesetter(-1),
    ...merge(
      mq({ from: 'xl', until: 'xxl', value: { ...typesetter(-2) } }),
      mq({ from: 'xxl', value: { ...typesetter(-3) } })
    ),
  },
  copyIcon: {
    fontSize: space(5),
    '--icon-color-override': color('neutral1300'),
  },
  copyBtnWrapper: {
    alignSelf: 'center',
    justifySelf: 'end',
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    columnGap: space(2),
  },
});

export interface LiveBlogItemShareButtonProps {
  itemUrl: string;
  /** The Children to be rendered inside `<LiveBlogItemShareButton>` */
  children?: React.ReactNode;
  /**
   * CSS declarations to be set as inline `style` on the
   * html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   * const { styleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyLiveBlogItemShareButton(props) {
   *   const inlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <LiveBlogItemShareButton
   *      styleExtend={[ styleExtend, ]}
   *      inlineStyle={inlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  inlineStyle?: InlineStyles;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <LiveBlogItemShareButton styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
}

interface ButtonProps {
  itemUrl?: string;
  shareData: { url: string };
}

function ShareButton({ shareData }: ButtonProps) {
  const biAction = useBi();

  async function onClick() {
    try {
      await navigator.share(shareData);
      biAction({
        feature: 'Live blog item - Share Button',
        feature_type: 'Content',
        action_id: 14,
      });
    } catch (err) {
      console.error(err instanceof Error ? err.message : err);
    }
  }

  return (
    <ClickArea
      styleExtend={[c.shareBtn, c.clickAreaGeneralStyling, c.clickAreaColor]}
      rippleSize="small"
      data-testid="share-btn"
      onClick={onClick}
      title={fork({ default: 'שיתוף', hdc: 'Share' })}
    >
      <Icon icon="share" styleExtend={[c.shareBtnIcon]} variant="neutral" />
      <VisuallyHidden>{fork({ default: 'שיתוף', hdc: 'Share' })}</VisuallyHidden>
    </ClickArea>
  );
}

function CopyLinkButton({ itemUrl }: Pick<ButtonProps, 'itemUrl'>) {
  const [hasBeenCopied, setHasBeenCopied] = React.useState(false);
  const biAction = useBi();
  const copyOnClick = () => {
    if (itemUrl) {
      navigator?.clipboard?.writeText(itemUrl);
      setHasBeenCopied(true);
      biAction({
        // TODO: Add the correct bi data
        action_id: 169,
        campaign_details: 'Live blog item - copy link',
      });
    }
  };

  const copyBtnText = hasBeenCopied
    ? fork({ default: 'הקישור הועתק', hdc: 'Link copied' })
    : fork({ default: 'העתק לינק', hdc: 'Copy the link' });
  return (
    <div className={s9(c.copyBtnWrapper)}>
      <ClickArea
        styleExtend={[
          c.copyBtn,
          c.clickAreaGeneralStyling,
          hasBeenCopied ? c.coppiedSuccess : c.clickAreaColor,
        ]}
        rippleSize="small"
        data-testid="copy-btn"
        onClick={copyOnClick}
        title={copyBtnText}
      >
        <Icon
          icon={hasBeenCopied ? 'check' : 'link'}
          styleExtend={[c.copyIcon]}
          variant="neutral"
        />
        <VisuallyHidden>{copyBtnText}</VisuallyHidden>
      </ClickArea>
      {hasBeenCopied && <span className={s9(c.copyText)}>{copyBtnText}</span>}
    </div>
  );
}

export default function LiveBlogItemShareButton({ itemUrl }: LiveBlogItemShareButtonProps) {
  const platform = usePlatform();
  const [shouldRender, setShouldRender] = React.useState<'shareBtn' | 'copyLinkBtn'>(
    platform !== 'desktop' ? 'shareBtn' : 'copyLinkBtn'
  );
  const shareData = React.useMemo(() => {
    return { url: itemUrl };
  }, [itemUrl]);
  React.useEffect(() => {
    const shareSupported = navigator.canShare && navigator.canShare(shareData);

    if (!shareSupported) setShouldRender('copyLinkBtn');
  }, [setShouldRender, shareData]);

  if (!itemUrl) return null;

  if (shouldRender === 'shareBtn') {
    return <ShareButton shareData={shareData} />;
  } else {
    return <CopyLinkButton itemUrl={itemUrl} />;
  }
}
