import * as Types from '@haaretz/s-fragments/Types';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetch } from '@haaretz/s-graphql-fetcher';
export type SubmitCommentNotificationEmailMutationVariables = Types.Exact<{
  input: Types.SubmitCommentNotificationEmailInputType;
}>;


export type SubmitCommentNotificationEmailMutation = (
  { submitCommentNotificationEmail: (
    { error?: string | null, status?: string | null }
    & { __typename: 'SubmitCommentNotificationEmailResponse' }
  ) }
  & { __typename: 'Mutation' }
);


export const SubmitCommentNotificationEmailMutationDocument = `
    mutation SubmitCommentNotificationEmailMutation($input: SubmitCommentNotificationEmailInputType!) {
  submitCommentNotificationEmail(input: $input) {
    error
    status
  }
}
    `;
export const useSubmitCommentNotificationEmailMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<SubmitCommentNotificationEmailMutation, TError, SubmitCommentNotificationEmailMutationVariables, TContext>) =>
    useMutation<SubmitCommentNotificationEmailMutation, TError, SubmitCommentNotificationEmailMutationVariables, TContext>(
      ['SubmitCommentNotificationEmailMutation'],
      (variables?: SubmitCommentNotificationEmailMutationVariables) => fetch<SubmitCommentNotificationEmailMutation, SubmitCommentNotificationEmailMutationVariables>(SubmitCommentNotificationEmailMutationDocument, variables)(),
      options
    );
useSubmitCommentNotificationEmailMutation.getKey = () => ['SubmitCommentNotificationEmailMutation'];

useSubmitCommentNotificationEmailMutation.fetcher = (variables: SubmitCommentNotificationEmailMutationVariables, options?: RequestInit['headers']) => fetch<SubmitCommentNotificationEmailMutation, SubmitCommentNotificationEmailMutationVariables>(SubmitCommentNotificationEmailMutationDocument, variables, options);