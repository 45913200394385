'use client';

import fork from '@haaretz/l-fork.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import usePlatform from '@haaretz/s-atoms/platform';
import useUser from '@haaretz/s-atoms/user';
import { useZenModeAtom } from '@haaretz/s-atoms/zenMode';
import ClickArea from '@haaretz/s-click-area';
import FeatureBlockModal from '@haaretz/s-feature-block-modal';
import Icon from '@haaretz/s-icon';
import useBi from '@haaretz/s-use-bi';
import React from 'react';
import s9 from 'style9';

import type { ActionBtnProps } from '../../types';

// `c` is short for `classNames`
const c = s9.create({
  base: {
    whiteSpace: 'nowrap',
    ...mq({ until: 'l', value: { display: 'none' } }),
  },
  icon: {
    transition: 'transform 0.25s ease-in-out',
    transform: 'rotate(0deg)',
  },
  zenModeOn: {
    transform: 'rotate(90deg)',
  },
  text: {
    marginInlineStart: space(1),
  },
});

export default function ZenBtn({
  clickAreaStyleExtend = [],
  iconStyleExtend = [],
  textStyleExtend = [],
  id,
}: ActionBtnProps) {
  const zenBtnRef = React.useRef<HTMLButtonElement>(null);
  const [zenMode, setZenMode] = useZenModeAtom();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const user = useUser('cookieValue');
  const platform = usePlatform();

  const biAction = useBi('action');

  if (platform !== 'desktop') return null;

  const isPayingUser = user.userType === 'paying';

  function onZenBtnClick() {
    biAction({
      feature: 'Zen',
      feature_type: 'Content',
      campaign_name: 'Zen_button',
      action_id: 92,
    });

    if (isPayingUser) {
      setZenMode();
    } else {
      openModal();
    }
  }

  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  return (
    <>
      <ClickArea
        ref={zenBtnRef}
        styleExtend={[...clickAreaStyleExtend, c.base]}
        rippleSize="small"
        onClick={() => onZenBtnClick()}
        data-testid="zen-btn"
        title={fork({ default: 'קריאה ללא פרסומות', hdc: 'Zen Reading' })}
        id={id}
      >
        <Icon
          icon="zen"
          styleExtend={[c.icon, zenMode && c.zenModeOn, ...iconStyleExtend]}
          variant={zenMode ? 'brand' : 'neutral'}
        />
        <span className={s9(c.text, ...textStyleExtend)}>
          {zenMode
            ? fork({ default: 'ביטול זן', hdc: 'Cancel Zen' })
            : fork({ default: 'קריאת זן', hdc: 'Zen Reading' })}
        </span>
      </ClickArea>
      <FeatureBlockModal type="zen" isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
}
