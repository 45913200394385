let rootFontSize: number | undefined;

export default function remToPx(remValue: `${number}rem`) {
  if (typeof window === 'undefined') return 0;

  rootFontSize =
    rootFontSize == null
      ? Number.parseInt(getComputedStyle(document.documentElement).fontSize) || 0
      : rootFontSize;

  if (rootFontSize) {
    return Number.parseFloat(remValue) * rootFontSize || 0;
  }

  return 0;
}

export function overrideRootFontSize(value: typeof rootFontSize) {
  // NOTE: should be used only in tests
  rootFontSize = value;
}
