'use client';

import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import useIntersectionObserver from '@haaretz/s-use-intersection-observer/common';
import * as React from 'react';
import s9 from 'style9';

import { useScrollytellingUpdate } from '../ScrollytellingProvider';

// `c` is short for `classNames`
const c = s9.create({
  group: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    rowGap: '50vh',
    width: space(85.75),
    ...merge(
      mq({
        from: 's',
        value: {
          width: space(137.5),
        },
      }),
      mq({
        from: 'l',
        value: {
          width: space(101),
        },
      }),
      mq({
        from: 'xl',
        value: {
          width: space(126.5),
        },
      })
    ),
  },
});

interface TextGroupWrapperProps {
  index: number;
}
export default function TextGroupWrapper({
  children,
  index,
}: React.PropsWithChildren<TextGroupWrapperProps>) {
  const setImgCur = useScrollytellingUpdate();
  const ref = React.useRef(null);

  const sentry = React.useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        const id = entry.target.getAttribute('data-id');
        if (id && entry.isIntersecting) setImgCur(+id);
      });
    },
    [setImgCur]
  );

  const observer = useIntersectionObserver({ cb: sentry, rootMargin: '-25% 0% -25% 0%' });

  React.useEffect(() => {
    const elem = ref.current;
    if (elem && observer) observer.observe(elem);

    return () => {
      if (elem && observer) observer.unobserve(elem);
    };
  }, [observer]);

  return (
    <div className={s9(c.group)} ref={ref} data-id={index} data-testid="scrolli-group">
      {children}
    </div>
  );
}
