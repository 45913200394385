'use client';

import color from '@haaretz/l-color.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import discardNullableValues from '@haaretz/s-common-utils/discardNullableValues';
import throwError from '@haaretz/s-common-utils/throwError';
import LogoLoadingIndicator from '@haaretz/s-logo-loading-indicator';
import useOnce from '@haaretz/s-use-once';
import React from 'react';
import s9 from 'style9';

import loadMoreLiveBlogItems from '../../utils/loadMoreLiveBlogItems';
import { useLiveBlog, useLiveBlogActions } from '../LiveBlogProvider';

interface LiveBlogBaseItemsProps {
  initFirstItemId?: string | null;
  initLastItemId?: string | null;
  children: React.ReactNode[];
}

const c = s9.create({
  logoLoadingIndicator: {
    fontSize: space(15),
    ...merge(
      mq({
        from: 's',
        until: 'l',
        value: {
          fontSize: space(17),
        },
      }),
      mq({
        from: 'l',
        value: {
          fontSize: space(20),
        },
      })
    ),
  },
  loadingWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pendingDiv: {
    position: 'absolute',
    top: 0,
    left: 0,
    paddingTop: space(8),
    height: '102%',
    width: '105%',
    margin: space(-4),
    zIndex: 2,
    justifyContent: 'center',
    backdropFilter: 'blur(2px)',
    backgroundColor: color('contentBg', { opacity: 0.7 }),
  },
});

export default function LiveBlogBaseItems({
  children,
  initFirstItemId,
  initLastItemId,
}: React.PropsWithChildren<LiveBlogBaseItemsProps>) {
  const { order } = useLiveBlog();
  const prevOrderRef = React.useRef(order);
  const { setFirstItemId, setLastItemId } = useLiveBlogActions();
  const [itemsJSX, setItemsJSX] = React.useState<React.ReactNode[]>([]);
  const [isPending, startTransition] = React.useTransition();

  const handleLoadMoreItems = React.useCallback(
    function handleLoadMore() {
      startTransition(async () => {
        try {
          const args = discardNullableValues<Parameters<typeof loadMoreLiveBlogItems>[0]>({
            lastItemId: undefined,
            area: 'bottom',
            postCacheParams: 'sa-live-blog-items',
            sort: order,
            count: 10,
          });
          const response = await loadMoreLiveBlogItems(args);

          setItemsJSX(response.html);
          setLastItemId(response.lastItemId);

          if (order === 'desc') {
            setFirstItemId(response.firstItemId);
          }
        } catch (error) {
          throwError(error as Error);
        }
      });
    },
    [order, setFirstItemId, setLastItemId]
  );

  React.useEffect(() => {
    if (prevOrderRef.current !== order) {
      prevOrderRef.current = order;
      handleLoadMoreItems();
    }
  }, [order, handleLoadMoreItems]);

  useOnce(
    () => {
      setFirstItemId(initFirstItemId);
      setLastItemId(initLastItemId);
    },
    !!(initFirstItemId || initLastItemId)
  );

  return (
    <>
      {isPending ? (
        <div className={s9(c.pendingDiv)}>
          <div className={s9(c.loadingWrapper)}>
            <LogoLoadingIndicator styleExtend={[c.logoLoadingIndicator]} />
          </div>
        </div>
      ) : null}
      {itemsJSX.length ? itemsJSX : children}
    </>
  );
}
