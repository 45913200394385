import * as Types from '@haaretz/s-fragments/Types';

export type LineGraphFragment = (
  { change: number, time: number, yieldSpread?: number | null, value: number, volume?: number | null }
  & { __typename: 'LineGraphData' }
);


export type LineGraphFragmentVariables = Types.Exact<{ [key: string]: never; }>;

export const LineGraphFragmentGql = `
    fragment LineGraphFragment on LineGraphData {
  __typename
  change
  time
  yieldSpread
  value
  volume
}
    `;