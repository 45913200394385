'use client';

/* eslint-disable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
// 1. The import order of macros matter and they must be kept in this order
// 2. Since macros are transpiled out during build, it is okay for them
//   to be imported even when they are not used.
// -- color must always be first -- //
import color from '@haaretz/l-color.macro';
// ---
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
// --- These return objects and must be spread or used inside `merge` --- //
import border from '@haaretz/l-border.macro';
import typesetter from '@haaretz/l-type.macro';
// --- These must come last --- //
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
/* eslint-enable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */

import FormfieldDescription from '@haaretz/s-formfield-description';
import Icon from '@haaretz/s-icon';
import TextLink from '@haaretz/s-text-link';
import * as React from 'react';
import s9 from 'style9';

const commentFieldBorderSpacing = 2;
const toolBarBtnWidth = space(9);
const toolBarColumnGap = space(0.5);
const gapBetweenButtonsAndLine = space(2);

// `c` is short for `classNames`
const c = s9.create({
  commentFieldWrapper: {
    borderRadius: radius('small'),
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    height: space(30),
    marginTop: space(4),

    ...border({
      color: color('neutral1200', { opacity: 0.2 }),
      spacing: commentFieldBorderSpacing,
      style: 'solid',
      width: '1px',
      side: 'all',
    }),

    ...merge(
      mq({ from: 'xl', value: { height: space(40) } }),
      mq({ from: 'xxl', value: { ...typesetter(-1) } })
    ),

    ':hover': {
      borderTopColor: color('neutral1200'),
      borderBottomColor: color('neutral1200'),
      borderInlineStartColor: color('neutral1200'),
      borderInlineEndColor: color('neutral1200'),
    },
    ':focus': {
      borderTopColor: color('neutral1200'),
      borderBottomColor: color('neutral1200'),
      borderInlineStartColor: color('neutral1200'),
      borderInlineEndColor: color('neutral1200'),
    },
  },
  bold: {
    fontWeight: 700,
  },
  contentEditableBorder: {
    '--bottom-border-height': '1px',
  },
  contentEditableNoBorder: {
    '--bottom-border-height': '0',
  },
  toolbarWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',

    ':after': {
      content: '""',
      position: 'absolute',
      top: '100%',
      height: 'var(--bottom-border-height)',
      backgroundColor: color('neutral600'),

      // width of the border line to make it X pixles from the buttons
      width: `calc(100% + ${space(
        commentFieldBorderSpacing
      )} - (${toolBarBtnWidth} * 2) - ${toolBarColumnGap} - ${gapBetweenButtonsAndLine})`,
    },
  },
  toolbarButtons: {
    display: 'flex',
    columnGap: toolBarColumnGap,
    marginTop: space(-1.75),
    marginInlineEnd: space(-1.75),
  },
  toolbarBtn: {
    height: space(9),
    width: toolBarBtnWidth,
    backgroundColor: color('neutral200'),

    ':hover': {
      backgroundColor: color('neutral300'),
    },
  },
  toggled: {
    color: color('neutral100'),
    backgroundColor: color('neutral1200'),

    ':hover': {
      backgroundColor: color('neutral1000'),
    },
  },
  italicBtn: {
    fontStyle: 'italic',
  },
  commentField: {
    outlineWidth: 0,
    paddingRight: space(2),
    paddingBottom: space(2),
    paddingLeft: space(2),
  },
  commentFieldDisabled: {
    pointerEvents: 'none',
  },
  initialCursor: {
    cursor: 'initial',
  },
  commentFieldDescritpion: {
    color: color('neutral900'),
    marginTop: space(1),

    ...typesetter(-2),
    ...mq({ from: 'xl', value: { ...typesetter(-3) } }),
  },
  descriptionTextLink: {
    color: color('neutral1200'),
  },
  borderTransition: {
    transitionProperty: 'border-color',
    transitionDuration: '0.2s',
    transitionTimingFunction: 'ease-in-out',
  },
  errorBorderColor: {
    borderTopColor: color('secondary900', { opacity: 0.2 }),
    borderBottomColor: color('secondary900', { opacity: 0.2 }),
    borderInlineStartColor: color('secondary900', { opacity: 0.2 }),
    borderInlineEndColor: color('secondary900', { opacity: 0.2 }),

    ':hover': {
      borderTopColor: color('secondary900'),
      borderBottomColor: color('secondary900'),
      borderInlineStartColor: color('secondary900'),
      borderInlineEndColor: color('secondary900'),
    },
  },
  contentEditableWrapper: {
    overflowY: 'auto',
    marginTop: space(3),
  },
});

const MAX_LENGTH_COMMENT = 1000;

export type CommentFormProps = {
  articleId: string;
  /**
   * The form shown when user clicks on reply button.
   */
  isReplyForm?: boolean;
  /**
   * Removes the reply form.
   */
  cancelReplyForm?: () => void;
  /**
   * The author (name or nickname) the user replies to.
   */
  replyTo?: string;
  /**
   * The parent comment id the user replies to.
   */
  parentCommentId?: string;
};

export type DialogType = 'paywall' | 'edit' | 'success' | 'none' | 'error' | 'used';

type CommentFieldProps = {
  errorCommentField: string | null;
  setErrorCommentField: React.Dispatch<React.SetStateAction<string | null>>;
  dialogType: DialogType;
  isLoading?: boolean;
};

const CommentField = React.forwardRef<HTMLDivElement, CommentFieldProps>(function CommentField(
  { errorCommentField, setErrorCommentField, dialogType: dialogType, isLoading }: CommentFieldProps,
  commentFieldRef
) {
  const [isContentEditable, setIsContentEditable] = React.useState(false);
  const [isBold, setIsBold] = React.useState(false);
  const [isItalic, setIsItalic] = React.useState(false);
  const italicId = React.useId();
  const boldId = React.useId();
  const toolbarWrapperId = React.useId();
  const commentFieldId = React.useId();

  if (typeof commentFieldRef === 'function') {
    throw new TypeError('You cannot pass a ref callback');
  }

  const toggleItalic = () => {
    document.execCommand('italic');
    setIsItalic(document.queryCommandState('italic'));
  };

  const toggleBold = () => {
    document.execCommand('bold');
    setIsBold(document.queryCommandState('bold'));
  };

  const onClickCommentField = () => {
    setIsBold(document.queryCommandState('bold'));
    setIsItalic(document.queryCommandState('italic'));
  };

  const onClickCommentFieldWrapper = () => {
    if (commentFieldRef?.current) commentFieldRef.current.focus();
    setIsContentEditable(true);
  };

  const onFocusCommentFieldWrapper = () => {
    setIsContentEditable(true);
  };

  const onBlurCommentFieldWrapper = (e: React.FocusEvent<HTMLDivElement, Element>) => {
    const isItalicBtn = e.relatedTarget?.id === italicId;
    const isBoldBtn = e.relatedTarget?.id === boldId;

    if (!isItalicBtn && !isBoldBtn) {
      setIsContentEditable(false);
    }
  };

  const label = (
    <span className={s9(isContentEditable && c.bold)}>
      {fork({ default: 'תגובה', hdc: 'Comment' })}
    </span>
  );

  const preventTyping = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const inputValue = commentFieldRef?.current?.textContent;
    if (inputValue) {
      const isNotExcludedKey =
        e.key !== 'Backspace' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight';

      const shouldPreventTyping = inputValue.length >= MAX_LENGTH_COMMENT && isNotExcludedKey;

      if (shouldPreventTyping) {
        e.preventDefault();
      }
    }
  };

  const commentFieldHandler = () => {
    const inputValue = commentFieldRef?.current?.textContent;

    if (inputValue) {
      if (inputValue.length > MAX_LENGTH_COMMENT) {
        setErrorCommentField(
          fork({
            default: `אין להזין יותר מ-${MAX_LENGTH_COMMENT.toLocaleString(
              'en-US'
            )} תווים בתוכן התגובה`,
            hdc: `Comment max length is ${MAX_LENGTH_COMMENT} chars`,
          })
        );
      } else {
        setErrorCommentField(null);
      }
    } else {
      setErrorCommentField(
        fork({ default: 'נא להזין את תוכן התגובה', hdc: 'Comment is required' })
      );
    }
  };

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        className={s9(
          c.commentFieldWrapper,
          isContentEditable && c.initialCursor,
          !!errorCommentField && c.errorBorderColor,
          c.borderTransition,
          isLoading && c.commentFieldDisabled
        )}
        onClick={onClickCommentFieldWrapper}
        onFocus={onFocusCommentFieldWrapper}
        onBlur={onBlurCommentFieldWrapper}
      >
        <div
          className={s9(
            c.toolbarWrapper,
            isContentEditable ? c.contentEditableBorder : c.contentEditableNoBorder
          )}
          id={toolbarWrapperId}
        >
          {isContentEditable ? (
            <>
              {label}
              <div className={s9(c.toolbarButtons)}>
                <button
                  className={s9(c.italicBtn, c.toolbarBtn, isItalic && c.toggled)}
                  onClick={toggleItalic}
                  id={italicId}
                  type="button"
                >
                  <Icon icon="italics" id={italicId} />
                </button>
                <button
                  className={s9(c.toolbarBtn, isBold && c.toggled)}
                  onClick={toggleBold}
                  id={boldId}
                  type="button"
                >
                  <Icon icon="bold" id={boldId} />
                </button>
              </div>
            </>
          ) : (
            label
          )}
        </div>
        {/* We use this wrapper to avoid a weird behavior where clicking outside the elemenet with
        contentEditable causes to focus it.
        https://stackoverflow.com/questions/34354085/clicking-outside-a-contenteditable-div-stills-give-focus-to-it
        */}
        <div className={s9(c.contentEditableWrapper)}>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div
            className={s9(c.commentField, !isContentEditable && c.commentFieldDisabled)}
            contentEditable
            ref={commentFieldRef}
            onKeyDown={preventTyping}
            onInput={commentFieldHandler}
            onBlur={commentFieldHandler}
            onClick={onClickCommentField}
            onKeyUp={onClickCommentField}
            aria-describedby={commentFieldId}
            data-testid="comment-input"
            dangerouslySetInnerHTML={dialogType === 'success' ? { __html: '' } : undefined}
          />
        </div>
      </div>
      {errorCommentField ? (
        <FormfieldDescription id={commentFieldId} isInvalid={!!errorCommentField}>
          {errorCommentField}
        </FormfieldDescription>
      ) : (
        <div className={s9(c.commentFieldDescritpion)}>
          {fork({
            default: 'משלוח תגובה מהווה הסכמה ל',
            hdc: 'By adding a comment, I agree to this site`s',
          })}
          <TextLink
            href={fork({
              default: 'https://www.haaretz.co.il/misc/terms-of-use',
              tm: 'https://www.themarker.com/misc/site-policy',
              hdc: 'https://www.haaretz.com/misc/terms-and-conditions',
            })}
            target="_blank"
            rel="noreferrer"
            styleExtend={[c.descriptionTextLink]}
          >
            {fork({ default: 'תנאי השימוש', hdc: 'terms and conditions' })}
          </TextLink>{' '}
          {fork({ default: 'של אתר הארץ', tm: 'של אתר TheMarker' })}
        </div>
      )}
    </>
  );
});

export default CommentField;
