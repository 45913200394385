import fork from '@haaretz/l-fork.macro';

import type { UserCredentialsTypeFragment } from '@haaretz/s-fragments/UserCredentialsType';

const hostname = fork({
  default: 'https://login.haaretz.co.il',
  tm: 'https://login.themarker.com',
  hdc: 'https://login.haaretz.com',
});

export default function handleResponse(data: UserCredentialsTypeFragment) {
  const abuse = data.user?.abuse;
  const success = data.success;
  const messages = data.messages;
  const isAbuse = success === false && messages?.[0] === 'abuse-logout';

  if (isAbuse && abuse) {
    const token = data?.user?.abuse.redirect;
    const redirect = typeof token === 'string' ? /(\/abuse):(.*):(.*)/.exec(token) : null;

    const redirectInfo = redirect
      ? {
          url: `${hostname}${redirect[1]}`,
          email: redirect[2],
          sso: redirect[3],
        }
      : null;

    if (redirectInfo) {
      const loginData =
        typeof window === 'undefined'
          ? /* istanbul ignore next */
            Buffer.from(JSON.stringify(redirectInfo)).toString('base64')
          : window.btoa(JSON.stringify(redirectInfo));

      const url = `${redirectInfo.url}?loginData=${loginData}`;

      window.location.assign(url);
    }
  }
}
