'use client';

import color from '@haaretz/l-color.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import Caption from '@haaretz/s-caption';
import Card from '@haaretz/s-card';
import HtzImage from '@haaretz/s-htz-image';
import Icon from '@haaretz/s-icon';
import * as React from 'react';
import s9 from 'style9';

import type { ChatItemFragment } from '@haaretz/s-fragments/infographic/Chat.infographic';

const c = s9.create({
  wrapper: {
    gridColumnStart: 'main-start',
    gridColumnEnd: 'main-end',
    marginBottom: space(2),
    marginTop: space(2),
    width: '100%',

    /* Media queries for width of the card */
    ...merge(
      mq({
        from: 's',
        until: 'xl',
        value: {
          width: space(91),
        },
      }),
      mq({
        from: 'xl',
        value: {
          marginBottom: space(4),
          marginTop: space(4),
        },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          width: space(95),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          width: space(94),
        },
      })
    ),
  },
  restMessages: {
    opacity: 0,
    transform: 'scale(0.6, 0.6)',
  },
  animation: {
    opacity: 1,
    transform: 'scale(1, 1)',
    transitionDelay: 'var(--transitionDelay)',
    transitionDuration: '350ms',
    transitionProperty: 'transform, opacity',
    transitionTimingFunction: 'ease-in-out',
  },
  base: {
    paddingTop: space(7),
    paddingBottom: space(7),
    paddingInlineStart: space(3),
    paddingInlineEnd: space(3),

    /* Media queries for paddings */
    ...merge(
      mq({
        from: 'm',
        until: 'xl',
        value: {
          paddingTop: space(10),
          paddingBottom: space(11),
        },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          paddingTop: space(13),
          paddingBottom: space(13),
          paddingRight: space(5),
          paddingLeft: space(5),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          paddingTop: space(12),
          paddingBottom: space(12),
          paddingRight: space(6),
          paddingLeft: space(6),
        },
      })
    ),
  },
  title: {
    paddingBottom: space(4),
    fontWeight: '700',
    ...typesetter(2),

    ...merge(
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          paddingBottom: space(6),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          paddingBottom: space(6),
          ...typesetter(1),
        },
      })
    ),
  },
  messageList: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: space(7),

    ...merge(
      mq({
        from: 'm',
        value: {
          rowGap: space(6),
        },
      })
    ),
  },
  author: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginInlineStart: 'auto',
    marginInlineEnd: 'auto',
    minWidth: space(15),
    maxWidth: space(17),
    rowGap: space(1),
  },
  message: {
    display: 'flex',
    columnGap: space(3),
  },
  messageRegular: {
    flexDirection: 'row',
    paddingInlineStart: space(1),

    ...merge(
      mq({
        from: 's',
        until: 'l',
        value: {
          columnGap: space(5),
        },
      })
    ),
  },
  messageReversed: {
    flexDirection: 'row-reverse',
    paddingInlineEnd: space(1),
  },
  avatar: {
    width: space(10),
    height: space(10),
    borderRadius: radius('circle'),
  },
  name: {
    color: color('neutral1200'),
    fontWeight: '700',
    textAlign: 'center',
    ...typesetter(-1),
    ...merge(
      mq({
        from: 'xxl',
        value: { ...typesetter(-2) },
      })
    ),
  },
  messageText: {
    backgroundColor: color('infoChatMessageBg'),
    borderRadius: radius('xLarge'),
    flex: '1',
    height: 'fit-content',
    paddingBottom: space(3),
    paddingInlineEnd: space(2),
    paddingInlineStart: space(2),
    paddingTop: space(3),

    ...merge(
      mq({
        from: 'm',
        value: {
          paddingBottom: space(2),
          paddingTop: space(2),
        },
      })
    ),
  },
  avatarIcon: {
    color: color('textLightAllModes'),
    height: space(10),
    width: space(10),
    backgroundColor: color('infoChatUserPlaceholder'),
    borderRadius: radius('circle'),
  },
});

export type ChatElems = { item: ChatItemFragment; richText: JSX.Element | null }[];

export interface InfographicChatProps {
  title?: string | null;
  elems: ChatElems;
}

const options: IntersectionObserverInit = {
  root: null,
  rootMargin: '0px',
  threshold: 0.5,
};

export default function InfographicChat({ elems, title }: Readonly<InfographicChatProps>) {
  const [isInView, setIsInView] = React.useState(false);
  const observedRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIsInView(true);
        observer.disconnect();
      }
    }, options);

    const curr = observedRef.current;

    if (curr && observer) {
      observer.observe(curr);

      return () => observer.disconnect();
    }

    return () => undefined;
  }, []);

  return (
    <figure ref={observedRef} className={s9(c.wrapper)}>
      {title ? <Caption as="figcaption" styleExtend={[c.title]} caption={title} /> : null}
      <Card variant="toned" styleExtend={[c.base, c.messageList]}>
        {elems.map(({ item, richText }, index) => {
          return (
            <div
              key={item.text}
              className={s9(
                c.message,
                index % 2 === 0 ? c.messageRegular : c.messageReversed,
                index > 0 && c.restMessages,
                isInView && c.animation
              )}
              style={{
                '--transitionDelay': `${index * 300}ms`,
              }}
            >
              <div className={s9(c.author)}>
                {item.speaker?.image ? (
                  <HtzImage
                    {...item.speaker?.image}
                    imgData={item.speaker.image.files[0]}
                    aspect="square"
                    widths={[40]}
                    styleExtend={[c.avatar]}
                    sizes={[{ size: '40px' }]}
                    contentId={item.speaker.image.contentId}
                    type="image"
                  />
                ) : (
                  <Icon icon="user" styleExtend={[c.avatarIcon]} />
                )}
                {item.speaker?.name ? <p className={s9(c.name)}>{item.speaker.name}</p> : null}
              </div>
              <div className={s9(c.messageText)}>{richText}</div>
            </div>
          );
        })}
      </Card>
    </figure>
  );
}
