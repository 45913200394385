import * as Types from '@haaretz/s-fragments/Types';

export type AreaGraphFragment = (
  { avgRatio?: number | null, peRatio?: number | null, time?: number | null, value?: number | null }
  & { __typename: 'AreaGraphData' }
);


export type AreaGraphFragmentVariables = Types.Exact<{ [key: string]: never; }>;

export const AreaGraphFragmentGql = `
    fragment AreaGraphFragment on AreaGraphData {
  __typename
  avgRatio
  peRatio
  time
  value
}
    `;