'use client';

import React from 'react';

const STProvider = React.createContext<number | null | undefined>(undefined);

const STProviderUpdateContext = React.createContext<
  React.Dispatch<React.SetStateAction<number>> | undefined
>(undefined);

export default function ScrollytellingProvider({ children }: React.PropsWithChildren<unknown>) {
  const [imgCur, setImgCur] = React.useState<number>(0);

  return (
    <STProvider.Provider value={imgCur}>
      <STProviderUpdateContext.Provider value={setImgCur}>
        {children}
      </STProviderUpdateContext.Provider>
    </STProvider.Provider>
  );
}

export function useScrollytelling() {
  const context = React.useContext(STProvider);

  if (context === undefined) {
    throw new Error('useScrollytelling must be used within a STProvider');
  }

  return context;
}

export function useScrollytellingUpdate() {
  const context = React.useContext(STProviderUpdateContext);

  if (context === undefined) {
    throw new Error('useScrollytellingUpdate must be used within a STProviderUpdateContext');
  }

  return context;
}
