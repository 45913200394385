'use client';

import React from 'react';

import type { PeriodType } from '@haaretz/s-fragments/Types';

type SelectedPeriodContextValue = PeriodType;

type SelectedPeriodContextUpdaterValue = React.Dispatch<React.SetStateAction<PeriodType>>;

export const SelectedPeriodContextUpdater = React.createContext<SelectedPeriodContextUpdaterValue>(
  () => {}
);

export const SelectedPeriodContext = React.createContext<SelectedPeriodContextValue>('year1');

export function SelectedPeriodProvider({ children }: { children: React.ReactNode }) {
  const [selectedPeriod, setSelectedPeriod] = React.useState<PeriodType>('week');

  return (
    <SelectedPeriodContext.Provider value={selectedPeriod}>
      <SelectedPeriodContextUpdater.Provider value={setSelectedPeriod}>
        {children}
      </SelectedPeriodContextUpdater.Provider>
    </SelectedPeriodContext.Provider>
  );
}
