'use client';

import fork from '@haaretz/l-fork.macro';
import space from '@haaretz/l-space.macro';
import Button from '@haaretz/s-button';
import discardNullableValues from '@haaretz/s-common-utils/discardNullableValues';
import throwError from '@haaretz/s-common-utils/throwError';
import useIntersectionObserver from '@haaretz/s-use-intersection-observer/common';
import useDocumentEventListener from '@haaretz/s-use-native-application/useDocumentEventListener';
import { usePathname, useRouter } from 'next/navigation';
import React from 'react';
import s9 from 'style9';

import { LIVE_BLOG_SCROLL_TO_ITEM_EVENT, LIVE_BLOG_LOAD_ALL_ITEMS_EVENT } from '../../constants';
import loadMoreLiveBlogItems from '../../utils/loadMoreLiveBlogItems';
import { useLiveBlog } from '../LiveBlogProvider';

const c = s9.create({
  loadMoreButton: {
    marginTop: space(8),
    display: 'flex',
    margin: '0 auto',
  },
});

function LiveBlogEndItems() {
  const [itemsJSX, setItemsJSX] = React.useState<React.ReactNode>(null);
  const [isPending, startTransition] = React.useTransition();
  const [showMoreItems, setShowMoreItems] = React.useState(false);
  const pathname = usePathname();
  const router = useRouter();
  const { lastItemId, order } = useLiveBlog();

  React.useEffect(() => {
    setItemsJSX([]);
  }, [order]);

  const loadMoreButtonRef = React.useRef<HTMLButtonElement>(null);

  const handleLoadMoreItems = React.useCallback(
    function handleLoadMore(clickedItemId?: string) {
      startTransition(async () => {
        try {
          const args = discardNullableValues<Parameters<typeof loadMoreLiveBlogItems>[0]>({
            lastItemId,
            area: 'bottom',
            postCacheParams: 'sa-live-blog-items',
            sort: order,
          });
          const response = await loadMoreLiveBlogItems(args);

          if (response.html?.length) {
            setItemsJSX(response.html);

            if (clickedItemId) {
              const scrollEvent: CustomEvent<string> = new CustomEvent(
                LIVE_BLOG_SCROLL_TO_ITEM_EVENT,
                {
                  detail: clickedItemId,
                }
              );

              document.dispatchEvent(scrollEvent);
            }
          } else {
            setShowMoreItems(true);
          }
        } catch (error) {
          throwError(error as Error);
        }
      });
    },
    [lastItemId, order]
  );

  const loadMoreObserverCb = React.useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const element = entries[0];
      const isFullyInViewPort = element.isIntersecting;

      if (isFullyInViewPort) {
        handleLoadMoreItems();
      }
    },
    [handleLoadMoreItems]
  );

  useDocumentEventListener(
    LIVE_BLOG_LOAD_ALL_ITEMS_EVENT,
    ({ detail }) => {
      setShowMoreItems(true);
      handleLoadMoreItems(detail);
    },
    true
  );

  const observer = useIntersectionObserver({
    rootMargin: '2000px 0px 2000px 0px',
    cb: loadMoreObserverCb,
  });

  React.useEffect(() => {
    const observedElement = loadMoreButtonRef.current;

    if (observedElement && observer) observer.observe(observedElement);

    return () => {
      if (observedElement && observer) observer.unobserve(observedElement);
    };
  }, [observer]);

  return (
    <>
      {showMoreItems ? (
        itemsJSX
      ) : (
        <Button
          size="large"
          styleExtend={[c.loadMoreButton]}
          ref={loadMoreButtonRef}
          onClick={() => {
            router.push(pathname, { scroll: false });

            setShowMoreItems(true);
          }}
          {...(isPending
            ? { state: 'busy', busyNotice: fork({ default: 'טוען...', hdc: 'loading...' }) }
            : { state: 'auto' })}
        >
          {fork({ default: 'לעדכונים נוספים', hdc: 'Load more' })}
        </Button>
      )}
    </>
  );
}

export default function LiveBlogEndItemsWrapper() {
  const { lastItemId } = useLiveBlog();

  if (!lastItemId) return null;

  return <LiveBlogEndItems />;
}
