'use client';

import color from '@haaretz/l-color.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import Button, { ButtonState } from '@haaretz/s-button';
import useBi from '@haaretz/s-use-bi';
import * as React from 'react';
import s9 from 'style9';

import loadMore from '../../utils/loadMore';

import type { Breadcrumbs } from '@haaretz/s-data-structure-types';

// `c` is short for `classNames`
const c = s9.create({
  base: {
    //
    gridColumnStart: '1',
    //
    width: '100%',
    marginTop: space(4),
    display: 'grid',
    gap: space(4),
    ...merge(
      mq({
        from: 's',
        until: 'xl',
        value: {
          gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
          gap: space(8),
          marginTop: space(8),
        },
      }),
      mq({
        from: 'xl',
        value: {
          gridTemplateColumns: 'reapeat(6, minmax(0, 1fr))',
          gap: space(8),
          marginTop: space(8),
        },
      })
    ),
  },

  loadMoreWrapper: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
  },
  loadMoreBtn: {
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor: color('neutral400'),
    width: '100%',
    height: space(23),
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 1,
    backgroundColor: color('contentBg'),
    ...merge(
      mq({
        from: 's',
        until: 'l',
        value: {
          height: space(26),
        },
      }),
      mq({
        from: 'l',
        until: 'xxl',
        value: { height: space(31) },
      }),
      mq({
        from: 'xxl',
        value: { height: space(32) },
      })
    ),
  },
  button: {
    marginTop: space(-5),
    position: 'absolute',
  },
  endIcon: {
    transform: 'rotate(90deg)',
  },
});

export interface TenArticlesPaginatorProps {
  breadcrumbs: Breadcrumbs | null;
  tenArticlesContentId: string;
  loadMoreButtonText?: string;
  lastItemId: string | null;
  remainingArticlesNum?: number | null;
}

export default function TenArticlesPaginator({
  breadcrumbs,
  tenArticlesContentId,
  loadMoreButtonText,
  lastItemId,
  remainingArticlesNum,
}: TenArticlesPaginatorProps) {
  const [lastItem, setLastItem] = React.useState<string | null>(lastItemId);
  const [nextItems, setNextItems] = React.useState<JSX.Element[]>([]);
  const [items, setItems] = React.useState<JSX.Element[]>([]);
  const [isLoading, startTransition] = React.useTransition();
  const biAction = useBi();
  const subSection = breadcrumbs?.find(breadcrumb => Boolean(breadcrumb?.pathSegment));
  const campaignName = subSection?.pathSegment || 'n/a';

  const handleLoadMore = React.useCallback(
    function handleLoadMore() {
      if (!isLoading) {
        startTransition(async () => {
          try {
            setItems(prev => {
              const res = [...prev, ...nextItems];
              return res;
            });
            const response = await loadMore({
              contentId: tenArticlesContentId,
              lastTeaserId: lastItem,
              remainingArticlesNum,
              postCacheParams: ['sa-10recipes', tenArticlesContentId],
            });
            setNextItems(response.html);
            setLastItem(response.next);
          } catch (error) {
            console.error(error);
          }
        });
        biAction({
          action_id: 169,
          feature_type: 'Content',
          feature: 'Load more',
          campaign_name: campaignName,
        });
      }
    },
    [
      isLoading,
      biAction,
      campaignName,
      tenArticlesContentId,
      lastItem,
      remainingArticlesNum,
      nextItems,
    ]
  );

  React.useEffect(() => {
    handleLoadMore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const BtnStateAndNotice: ButtonState = isLoading
    ? {
        state: 'busy',
        busyNotice: 'loading',
      }
    : {
        state: 'auto',
        busyNotice: undefined,
      };

  return (
    <>
      {items.length > 0 ? items : null}
      {lastItem ? (
        <div className={s9(c.loadMoreWrapper)}>
          <div className={s9(c.loadMoreBtn)}>
            <Button
              {...BtnStateAndNotice}
              endIcon={'chevron'}
              size="large"
              onClick={handleLoadMore}
              styleExtend={[c.button]}
              endIconStyleExtend={[c.endIcon]}
            >
              {loadMoreButtonText}
            </Button>
          </div>
        </div>
      ) : null}
    </>
  );
}
