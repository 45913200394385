'use client';

import React from 'react';

export default function SingleCommentWrapper({ children }: React.PropsWithChildren) {
  const subCommentRefCb = React.useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      node.scrollIntoView({ behavior: 'auto', block: 'center' });
    }
  }, []);

  return <div ref={subCommentRefCb}>{children}</div>;
}
