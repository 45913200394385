'use client';

import color from '@haaretz/l-color.macro';
import space from '@haaretz/l-space.macro';
import * as React from 'react';
import s9 from 'style9';

const c = s9.create({
  timeline: {
    position: 'relative',
    gridRowStart: 2,
    gridRowEnd: 3,
    gridColumnStart: 1,
    gridColumnEnd: 2,
    backgroundColor: color('infoTimeline'),
    width: space(1),
    height: 0,
    right: space(0.5),
    transitionProperty: 'height',
    transitionDuration: '1s',
    transitionTimingFunction: 'ease-in',
  },
  animation: {
    height: `calc(100% - ${space(3)})`,
  },
});

const options: IntersectionObserverInit = {
  root: null,
  rootMargin: '0px',
  threshold: 0.5,
};

export function Line() {
  const [isAnimating, setIsAnimating] = React.useState(false);
  const observedRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIsAnimating(true);
        observer.disconnect();
      }
    }, options);

    const curr = observedRef.current;

    if (curr && observer) {
      observer.observe(curr);

      return () => observer.disconnect();
    }

    return () => undefined;
  }, []);

  return <div ref={observedRef} className={s9(c.timeline, isAnimating && c.animation)} />;
}
