'use client';
import space from '@haaretz/l-space.macro';
import * as React from 'react';
import s9 from 'style9';

import type { SpotifyFragment } from '@haaretz/s-fragments/embeds/Spotify.embeds';
import type { StyleExtend } from '@haaretz/s-types';

// `c` is short for `classNames`
const c = s9.create({
  iframe: {
    width: '100%',
    height: space(38),
    borderWidth: 0,
    position: 'absolute',
    left: 0,
    top: 0,
  },
  wrap: {
    position: 'relative',
    height: space(38),
    width: '100%',
  },
  relative: { position: 'relative' },
  hidden: { visibility: 'hidden' },
});

export interface SpotifyProps extends SpotifyFragment {
  styleExtend?: StyleExtend;
  children?: React.ReactNode;
}

export default function Spotify({ source, children }: SpotifyProps) {
  const [showPlaceHolder, setShowPlaceHolder] = React.useState<boolean>(true);
  const iframeRef = React.useRef<HTMLIFrameElement | null>(null);
  React.useEffect(() => {
    const onLoad = () => {
      setShowPlaceHolder(false);
    };
    const iframe = iframeRef.current;
    if (iframe) {
      if (source) {
        iframe.src = source;
      }
      iframe.addEventListener('load', onLoad);

      return () => {
        iframe.removeEventListener('load', onLoad);
      };
    }
    return () => null;
  }, [source]);
  if (!source) return null;

  return (
    <div className={s9(c.wrap)}>
      {showPlaceHolder && children}
      <iframe
        ref={iframeRef}
        className={s9(c.iframe, showPlaceHolder ? c.hidden : c.relative)}
        src={source}
        title={`Spotify-${source}`}
        loading="lazy"
      />
    </div>
  );
}
