'use client';

import fork from '@haaretz/l-fork.macro';
import mq from '@haaretz/l-mq.macro';
import usePlatform from '@haaretz/s-atoms/platform';
import ClickArea from '@haaretz/s-click-area';
import Icon from '@haaretz/s-icon';
import useBi from '@haaretz/s-use-bi';
import VisuallyHidden from '@haaretz/s-visually-hidden';
import React from 'react';
import s9 from 'style9';

import type { ActionBarProps, ActionBtnProps } from '../../types';

const c = s9.create({
  base: {
    ...mq({ from: 's', value: { display: 'none' } }),
  },
});

interface ShareBtnProps extends ActionBtnProps {
  canonicalUrl: ActionBarProps['canonicalUrl'];
}

export default function ShareBtn({
  clickAreaStyleExtend,
  iconStyleExtend,
  canonicalUrl,
  id,
}: ShareBtnProps) {
  const platform = usePlatform();
  const [shouldRender, setShouldRender] = React.useState(platform === 'mobile');
  const biAction = useBi();

  const shareData = React.useMemo(() => {
    return { url: canonicalUrl };
  }, [canonicalUrl]);

  React.useEffect(() => {
    // On the client, check if the browser supports the Web Share API
    const shareSupported = navigator.canShare && navigator.canShare(shareData);

    // Don't render on desktop or if the browser doesn't support the Web Share API
    setShouldRender(platform === 'mobile' && shareSupported);
  }, [platform, shareData]);

  if (!shouldRender) return null;

  async function onClick() {
    try {
      await navigator.share(shareData);
      biAction({
        feature: 'Article Page - Share Button',
        feature_type: 'Content',
        action_id: 14,
      });
    } catch (err) {
      console.error(err instanceof Error ? err.message : err);
    }
  }

  return (
    <ClickArea
      styleExtend={[c.base, ...(clickAreaStyleExtend || [])]}
      rippleSize="small"
      data-testid="share-btn"
      onClick={onClick}
      title={fork({ default: 'שיתוף', hdc: 'Share' })}
      id={id}
    >
      <Icon icon="share" styleExtend={iconStyleExtend} variant="neutral" />
      <VisuallyHidden>{fork({ default: 'שיתוף', hdc: 'Share' })}</VisuallyHidden>
    </ClickArea>
  );
}
