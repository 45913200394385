import * as Types from '@haaretz/s-fragments/Types';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetch } from '@haaretz/s-graphql-fetcher';
export type SetUserNicknameMutationVariables = Types.Exact<{
  input: Types.SetUserNicknameInputType;
}>;


export type SetUserNicknameMutation = (
  { setUserNickname: (
    { error?: string | null, status?: string | null, nickname?: string | null }
    & { __typename: 'SetUserNicknameResponse' }
  ) }
  & { __typename: 'Mutation' }
);


export const SetUserNicknameMutationDocument = `
    mutation SetUserNicknameMutation($input: SetUserNicknameInputType!) {
  setUserNickname(input: $input) {
    error
    status
    nickname
  }
}
    `;
export const useSetUserNicknameMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<SetUserNicknameMutation, TError, SetUserNicknameMutationVariables, TContext>) =>
    useMutation<SetUserNicknameMutation, TError, SetUserNicknameMutationVariables, TContext>(
      ['SetUserNicknameMutation'],
      (variables?: SetUserNicknameMutationVariables) => fetch<SetUserNicknameMutation, SetUserNicknameMutationVariables>(SetUserNicknameMutationDocument, variables)(),
      options
    );
useSetUserNicknameMutation.getKey = () => ['SetUserNicknameMutation'];

useSetUserNicknameMutation.fetcher = (variables: SetUserNicknameMutationVariables, options?: RequestInit['headers']) => fetch<SetUserNicknameMutation, SetUserNicknameMutationVariables>(SetUserNicknameMutationDocument, variables, options);