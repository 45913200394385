'use client';

import color from '@haaretz/l-color.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import React from 'react';
import s9 from 'style9';

import { SelectedAssetContext } from './SelectedLineAssetProvider';

import type { PeriodType } from '@haaretz/s-fragments/Types';

const c = s9.create({
  tooltip: {
    height: space(15),
    backgroundColor: color('neutral300'),
    borderRadius: radius('xLarge'),
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    paddingTop: space(2),
    paddingBottom: space(2),
    color: color('neutral1200'),
    ...merge(mq({ from: 'xl', value: { height: space(20) } })),
  },
  tooltipForHomepage: {
    paddingTop: space(1),
    paddingBottom: space(1),
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    ...merge(mq({ from: 'xl', value: { height: space(14) } })),
  },
  loading: {
    opacity: 0.7,
    backgroundColor: color('neutral300'),
    backgroundImage: `linear-gradient(95deg, transparent 0%, transparent 15%, ${color(
      'neutral600',
      { opacity: 0.4 }
    )} 50%, transparent 85%)` as string,
    backgroundSize: '300% 300%',
    animationName: s9.keyframes({
      '0%': { backgroundPositionX: '-50%' },
      '100%': { backgroundPositionX: '150%' },
    }),
    animationDuration: '3s',
    animationTimingFunction: 'ease-in-out',
    animationIterationCount: 'infinite',
  },
  tooltipItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  middle: {
    position: 'relative',
    ':before': {
      content: "''",
      backgroundColor: color('neutral200'),
      position: 'absolute',
      right: 0,
      height: space(10),
      width: 1,
    },
    ':after': {
      content: "''",
      backgroundColor: color('neutral200'),
      position: 'absolute',
      left: 0,
      height: space(10),
      width: 1,
    },
  },
  itemLabel: {
    fontWeight: '700',
    ...typesetter(-2),
  },
  itemLabelHomepage: {
    ...merge(
      mq({
        from: 's',
        until: 'l',
        value: {
          ...typesetter(-1),
        },
      }),
      mq({
        from: 'xl',
        value: {
          ...typesetter(-2),
        },
      })
    ),
  },
  itemValue: {
    ...typesetter(1),
  },
  itemValueHomepage: {
    ...typesetter(0),
    ...merge(
      mq({
        from: 'l',
        until: 'xl',
        value: {
          ...typesetter(0),
        },
      }),
      mq({
        from: 'xl',
        value: {
          ...typesetter(-2),
        },
      })
    ),
  },
  hiddenOnHomepage: {
    display: 'none',
  },
  itemValueNegative: {
    color: color('secondary900'),
  },
  itemValuePositive: {
    color: color('quaternary700'),
  },
});

const formattedDates: Record<PeriodType, Intl.DateTimeFormatOptions> = {
  day: { hour: '2-digit', minute: '2-digit' },
  week: { day: '2-digit', month: '2-digit' },
  month: { day: '2-digit', month: '2-digit' },
  year1: { day: '2-digit', month: '2-digit', year: '2-digit' },
  year3: { day: '2-digit', month: '2-digit', year: '2-digit' },
  year5: { day: '2-digit', month: '2-digit', year: '2-digit' },
  max: { year: 'numeric' },
};

export default function InfoBlock({
  period,
  homepage,
}: {
  period: PeriodType;
  homepage?: boolean;
}) {
  const selectedAsset = React.useContext(SelectedAssetContext);

  const formattedDate = selectedAsset ? formatDates(selectedAsset?.time, period) : null;

  return (
    <div className={s9(c.tooltip, homepage && c.tooltipForHomepage, !selectedAsset && c.loading)}>
      <div dir="ltr" className={s9(c.tooltipItem)}>
        <p className={s9(c.itemLabel, homepage && c.itemLabelHomepage)}>% שינוי</p>
        {selectedAsset ? (
          <p
            dir="ltr"
            className={s9(
              c.itemValue,
              homepage && c.itemValueHomepage,
              selectedAsset?.change < 0 && c.itemValueNegative,
              selectedAsset?.change > 0 && c.itemValuePositive
            )}
          >
            {`${selectedAsset?.change}%` ?? ''}
          </p>
        ) : null}
      </div>
      <div className={s9(c.tooltipItem, c.middle)}>
        <p className={s9(c.itemLabel, homepage && c.itemLabelHomepage)}>שער</p>
        {selectedAsset ? (
          <p className={s9(c.itemValue, homepage && c.itemValueHomepage)}>
            {selectedAsset?.value ?? ''}
          </p>
        ) : null}
      </div>
      <div className={s9(c.tooltipItem)}>
        <p className={s9(c.itemLabel, homepage && c.itemLabelHomepage)}>
          {period === 'day' ? 'שעה' : 'תאריך'}
        </p>
        {selectedAsset ? (
          <p className={s9(c.itemValue, homepage && c.itemValueHomepage)}>{formattedDate ?? ''}</p>
        ) : null}
      </div>
    </div>
  );
}

function formatDates(date: number, period: PeriodType) {
  const parsedDate = new Date(date);
  let formattedDate;

  if (period === 'day') {
    formattedDate = parsedDate.toLocaleTimeString('en-GB', formattedDates[period]);
  } else {
    formattedDate = parsedDate.toLocaleDateString('en-GB', formattedDates[period]);
  }

  return formattedDate;
}
