import * as Types from '@haaretz/s-fragments/Types';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetch } from '@haaretz/s-graphql-fetcher';
export type UserAlertsRegistrationPersonalMutationVariables = Types.Exact<{
  input: Types.UserAlertsRegistrationInput;
}>;


export type UserAlertsRegistrationPersonalMutation = (
  { userAlertsRegistration: (
    { status: string }
    & { __typename: 'UserAlertsRegistrationResponse' }
  ) }
  & { __typename: 'Mutation' }
);


export const UserAlertsRegistrationPersonalMutationDocument = `
    mutation UserAlertsRegistrationPersonalMutation($input: UserAlertsRegistrationInput!) {
  userAlertsRegistration(input: $input) {
    status
  }
}
    `;
export const useUserAlertsRegistrationPersonalMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<UserAlertsRegistrationPersonalMutation, TError, UserAlertsRegistrationPersonalMutationVariables, TContext>) =>
    useMutation<UserAlertsRegistrationPersonalMutation, TError, UserAlertsRegistrationPersonalMutationVariables, TContext>(
      ['UserAlertsRegistrationPersonalMutation'],
      (variables?: UserAlertsRegistrationPersonalMutationVariables) => fetch<UserAlertsRegistrationPersonalMutation, UserAlertsRegistrationPersonalMutationVariables>(UserAlertsRegistrationPersonalMutationDocument, variables)(),
      options
    );
useUserAlertsRegistrationPersonalMutation.getKey = () => ['UserAlertsRegistrationPersonalMutation'];

useUserAlertsRegistrationPersonalMutation.fetcher = (variables: UserAlertsRegistrationPersonalMutationVariables, options?: RequestInit['headers']) => fetch<UserAlertsRegistrationPersonalMutation, UserAlertsRegistrationPersonalMutationVariables>(UserAlertsRegistrationPersonalMutationDocument, variables, options);