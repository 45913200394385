'use client';
import fork from '@haaretz/l-fork.macro';
import space from '@haaretz/l-space.macro';
import Button from '@haaretz/s-button';
import { site } from '@haaretz/s-consts';
import { useUserAlertsRegistrationPersonalMutation } from '@haaretz/s-mutations/UserAlertsRegistration';
import useBi from '@haaretz/s-use-bi';
import * as React from 'react';
import s9 from 'style9';

import type { RegistrationStatus, EmailProperties } from '../../NewsletterRegistration.view';
import type { ButtonState } from '@haaretz/s-button';
import type { UserType } from '@haaretz/s-fragments/Types';

// `c` is short for `classNames`
const c = s9.create({
  oneClickReg: {
    marginTop: space(4),
  },
  purchaseBtn: {
    marginTop: space(5),
  },
});

export type RegistrationElementProps = {
  resourceId?: string | null;
  resourceType?: string | null;
  setIsRegistered: React.Dispatch<React.SetStateAction<RegistrationStatus>>;
  setEmailProperties: React.Dispatch<React.SetStateAction<EmailProperties>>;
  subscribersOnly?: boolean | null;
  userEmail?: string | null;
  userType: UserType;
  anonymousId?: string;
  newsletterType?: string | null;
  title: string;
};

const checkoutUrl = fork({
  default:
    'https://promotion.haaretz.co.il/offers?htm_source=site&htm_medium=button&htm_campaign=TLDR&htm_content=subscribe',
  tm: 'https://promotion.themarker.com/offers?htm_source=site&htm_medium=button&htm_campaign=sub_only_mail&htm_con%20tent=subscribe',
  hdc: 'https://promotion.haaretz.com/?htm_source=site&htm_medium=button&htm_campaign=sub_only_mail&htm_content=subscribe',
});

export default function RegistrationElement({
  resourceId,
  setEmailProperties,
  setIsRegistered,
  resourceType,
  subscribersOnly,
  userType,
  userEmail,
  newsletterType,
  title,
}: RegistrationElementProps) {
  const [btnState, setBtnState] = React.useState<ButtonState['state']>('auto');
  const newsletterSignup = useUserAlertsRegistrationPersonalMutation();
  const biAction = useBi();

  const BtnStateAndNotice =
    btnState === 'busy'
      ? {
          state: btnState,
          busyNotice: 'מעדכן הרשמה...',
        }
      : {
          state: btnState,
          busyNotice: undefined,
        };

  const registerToNewsletter = async () => {
    setBtnState('busy');
    biAction({
      action_id: 9,
      newsletter_email: userEmail || '',
      newsletter_segment_id: resourceId || '',
      newsletter_segment_name: title,
      feature_type: 'Content',
      feature: newsletterType === 'personal' ? 'Personal-Newsletter' : 'Regular-Newsletter',
      personal_feature: newsletterType === 'personal',
    });
    const data = await newsletterSignup.mutateAsync({
      input: {
        email: userEmail,
        resourceType,
        isUnsubscribe: false,
        site,
        resourceId,
      },
    });
    const status = data.userAlertsRegistration.status;
    if (status === 'success') {
      setIsRegistered('success');
    }
    if (status === 'pending') {
      if (userEmail?.endsWith('@gmail.com')) {
        setEmailProperties({ isGmail: true, email: userEmail });
      }
      setIsRegistered('pending');
    }
    setBtnState('auto');
  };

  if (subscribersOnly && userType !== 'paying') {
    return (
      <Button as="a" variant="sales" href={checkoutUrl} styleExtend={[c.purchaseBtn]}>
        לרכישת מנוי דיגיטלי
      </Button>
    );
  } else {
    return (
      <Button {...BtnStateAndNotice} onClick={registerToNewsletter} styleExtend={[c.oneClickReg]}>
        {'הרשמה בקליק'}
      </Button>
    );
  }
}
