'use client';

import color from '@haaretz/l-color.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import typesetter from '@haaretz/l-type.macro';
import Link from 'next/link';
import s9 from 'style9';

import {
  LIVE_BLOG_SCROLL_TO_ITEM_EVENT,
  LIVE_BLOG_LOAD_ALL_ITEMS_EVENT,
} from '../../../../constants';

import type { KeyEventModelFragment } from '@haaretz/s-fragments/KeyEventModel';

const c = s9.create({
  keyEvent: {
    textDecoration: 'underline',
    textDecorationColor: color('primary600'),
    textDecorationThickness: '0.1em',
    textUnderlineOffset: '0.35em',
    ...merge(
      mq({
        from: 'l',
        value: {
          ...typesetter(-1),
        },
      })
    ),
  },
});

export default function LiveBlogKeyEventsLink(keyEvent: KeyEventModelFragment) {
  return (
    <Link
      href={`#${keyEvent.itemId}`}
      onClick={event => {
        if (!keyEvent.itemId) return;

        event.preventDefault();

        const item = document.getElementById(keyEvent.itemId);

        const scrollEvent: CustomEvent<string> = new CustomEvent(
          item ? LIVE_BLOG_SCROLL_TO_ITEM_EVENT : LIVE_BLOG_LOAD_ALL_ITEMS_EVENT,
          {
            detail: keyEvent.itemId,
          }
        );

        document.dispatchEvent(scrollEvent);
      }}
      className={s9(c.keyEvent)}
    >
      {keyEvent.title}
    </Link>
  );
}
