import { useSetUser } from '@haaretz/s-atoms/user';
import { useRegistrationMutation } from '@haaretz/s-lgn-mutations/Registration';
import handleSsoResponse from '@haaretz/s-sso-utils/handle-response';

import type {
  RegistrationMutation,
  RegistrationMutationVariables,
} from '@haaretz/s-lgn-mutations/Registration';
import type { UseMutationOptions } from '@tanstack/react-query';

export default function useRegistration<TError = Error, TContext = unknown>(
  options?: UseMutationOptions<
    RegistrationMutation,
    TError,
    RegistrationMutationVariables,
    TContext
  >
) {
  const updateUser = useSetUser();

  const registration = useRegistrationMutation({
    ...options,
    onSuccess: async (data, variables, context) => {
      handleSsoResponse(data?.registration);

      updateUser('login');

      /* istanbul ignore else */
      if (typeof options?.onSuccess === 'function') {
        options.onSuccess(data, variables, context);
      }
    },
  });

  return registration;
}
