'use client';

import fork from '@haaretz/l-fork.macro';
import Button from '@haaretz/s-button';
import React from 'react';
import s9 from 'style9';

import { useCommentsActionsContext } from '../../CommentsContextProvider';

import type { CommentFragment } from '@haaretz/s-fragments/Comment';

const c = s9.create({
  replyBtn: {
    paddingInlineStart: 0,
    paddingInlineEnd: 0,
  },
});

type ReplyBtnProps = {
  authorName: CommentFragment['authorName'];
  parentCommentId?: string;
};

export default function ReplyBtn({ authorName, parentCommentId }: ReplyBtnProps) {
  const { setReplyToAuthor, setReplyToCommentId } = useCommentsActionsContext();

  const replyHandler = () => {
    setReplyToCommentId(parentCommentId);
    if (authorName) setReplyToAuthor(authorName);
  };

  return (
    <Button
      priority="tertiary"
      styleExtend={[c.replyBtn]}
      onClick={replyHandler}
      data-testid="reply-btn"
    >
      {fork({ default: 'הגיבו', hdc: 'Reply' })}
    </Button>
  );
}
