'use client';

import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import s9 from 'style9';

import { useAudioPlayer } from '../hooks/useAudioPlayer';
import { playbackRate } from '../utils/audioPlayerUtils';

const c = s9.create({
  base: {
    gridColumnStart: '1',
    gridColumnEnd: '2',
    gridRowStart: '3',
    gridRowEnd: '4',
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'flex-start',
    ...merge(
      mq({
        from: 's',
        value: {
          alignItems: 'center',
          gridColumnStart: '2',
          gridColumnEnd: '3',
          gridRowStart: '2',
          gridRowEnd: '3',
        },
      })
    ),
  },
  button: {
    backgroundColor: 'inherit',
    fontWeight: 700,
    userSelect: 'none',
    minWidth: space(11),
    textAlign: 'start',
    ...typesetter(-1),
    ...merge(
      mq({
        from: 's',
        value: {
          marginInlineEnd: space(-4),
        },
      }),
      mq({
        from: 'xl',
        value: {
          ...typesetter(-2),
        },
      })
    ),
  },
});

export default function ChangeSpeedButton() {
  const { playbackIndex, onNextPlayBackRate } = useAudioPlayer();
  return (
    <div className={s9(c.base)}>
      <button onClick={onNextPlayBackRate} className={s9(c.button)} data-testid="changeSpeedButton">
        {`${playbackRate[playbackIndex]}X`}
      </button>
    </div>
  );
}
