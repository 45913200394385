'use client';

/* eslint-disable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
// 1. The import order of macros matter and they must be kept in this order
// 2. Since macros are transpiled out during build, it is okay for them
//   to be imported even when they are not used.
// -- color must always be first -- //
import color from '@haaretz/l-color.macro';
// ---
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
// --- These return objects and must be spread or used inside `merge` --- //
import border from '@haaretz/l-border.macro';
import typesetter from '@haaretz/l-type.macro';
// --- These must come last --- //
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
/* eslint-enable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */

import Button from '@haaretz/s-button';
import { hostname } from '@haaretz/s-consts';
import useBi from '@haaretz/s-use-bi';
import useImpressionObserver from '@haaretz/s-use-impression-observer';
import React from 'react';
import s9 from 'style9';

import DialogWrapper, { SharedWrapperProps } from './DialogWrapper';

// `c` is short for `classNames`
const c = s9.create({
  paywallButtonsWrapper: {
    alignSelf: 'flex-start',
    marginTop: space(6),
  },
});

const acceptBtnText = fork({ default: 'כן גם אני רוצה', hdc: 'I want a nickname' });

const url = `https://promotion.${hostname}/offers?htm_source=site&htm_medium=Talkbacks&htm_campaign=Nickname_Promotion&htm_content=${acceptBtnText}`;

export default function PaywallDialog({
  sharedWrapperProps,
}: {
  sharedWrapperProps: SharedWrapperProps;
}) {
  const biAction = useBi();

  const buttonsWrapperRef = React.useRef(null);

  const biData = {
    feature: 'Talkbacks',
    feature_type: 'Marketing',
    campaign_name: fork({ default: 'Nickname Promotion', hdc: 'Subs join faster' }),
    campaign_details: acceptBtnText,
  };

  useImpressionObserver({
    elementRef: buttonsWrapperRef,
    biData,
  });

  return (
    <DialogWrapper {...sharedWrapperProps}>
      {fork({
        default:
          'מנויי הארץ דיגיטל יכולים לבחור כינוי קבוע בתגובות, לקבל דירוגים מיוחדים וליהנות מפרסום אוטומטי ומהיר של תגובותיהם',
        hdc: 'Subscribers can pick a permanent nickname, get comment rating and enjoy automatic approval',
      })}
      <div className={s9(c.paywallButtonsWrapper)} ref={buttonsWrapperRef}>
        <Button
          variant="sales"
          as="a"
          href={url}
          onClick={() =>
            biAction({
              ...biData,
              action_id: 3,
            })
          }
        >
          {acceptBtnText}
        </Button>
        <Button priority="tertiary" onClick={() => sharedWrapperProps.setDialogType('none')}>
          {fork({ default: 'לא תודה', hdc: 'No thanks' })}
        </Button>
      </div>
    </DialogWrapper>
  );
}
