'use client';

import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import zIndex from '@haaretz/l-z-index.macro';
import Chip from '@haaretz/s-chip';
import discardNullableValues from '@haaretz/s-common-utils/discardNullableValues';
import throwError from '@haaretz/s-common-utils/throwError';
import Icon from '@haaretz/s-icon';
import { useSearchParams } from 'next/navigation';
import React from 'react';
import s9 from 'style9';

import loadMoreLiveBlogItems from '../../utils/loadMoreLiveBlogItems';
import FaviconBadge from '../../utils/loadMoreLiveBlogItems/FaviconBadge';
import { useLiveBlogActions, useLiveBlog } from '../LiveBlogProvider';

import type { Site, SiteName } from '@haaretz/s-types';

const c = s9.create({
  wrapper: {
    display: 'contents',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    transition: 'height 0.3s ease-in-out',
  },
  button: {
    ...typesetter(-1),
    top: space(6),
    position: 'sticky',
    height: space(11),
    zIndex: zIndex('above'),
    display: 'flex',
    gridColumnStart: '1',
    gridColumnEnd: '-1',
    width: 'fit-content',
    justifySelf: 'center',
    alignItems: 'center',
    transform: 'scale(0)',
    animationFillMode: 'both',
    paddingInlineStart: space(5),
    paddingInlineEnd: space(5),
    animationDuration: '0.3s',
    animationTimingFunction: 'ease-in',
    animationName: s9.keyframes({
      '0%': {
        transform: 'scale(0)',
      },
      '1%': {
        transform: 'scale(0.75)',
      },
      '20%': {
        transform: 'scale(0.8)',
      },
      '50%': {
        transform: 'scale(1.05)',
      },
      '100%': {
        transform: 'scale(1)',
      },
    }),
    ...merge(
      mq({
        from: 'l',
        until: 'xl',
        value: {
          ...typesetter(0),
          paddingInlineStart: space(8),
          paddingInlineEnd: space(8),
        },
      }),
      mq({
        from: 'xl',
        value: {
          ...typesetter(-1),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          ...typesetter(-2),
        },
      })
    ),
  },
  chevron: {
    transform: 'rotate(270deg)',
  },
  anchorPixel: {
    width: '1px',
    height: '1px',
    backgroundColor: 'transparent',
    position: 'absolute',
    top: 0,
  },
  bold: {
    fontWeight: '700',
  },
});

const isStorybook = typeof window !== 'undefined' && !!window?.__IS_STORYBOOK__;

const UPDATE_MIN_INTERVAL = 1;

const UPDATE_INTERVAL = 1000 * 60 * UPDATE_MIN_INTERVAL;

function LiveBlogStartItems() {
  const [itemsJSX, setItemsJSX] = React.useState<JSX.Element[]>([]);
  const [itemsJSXToDisplay, setItemsJSXToDisplay] = React.useState<JSX.Element[]>([]);
  const [, startTransition] = React.useTransition();
  const searchParams = useSearchParams();
  const { firstItemId, order } = useLiveBlog();
  const { setFirstItemId } = useLiveBlogActions();

  const anchorRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    setItemsJSX([]);
  }, [order]);

  const featureLiveBlogItem = searchParams.get('liveBlogItemId');

  const handleLoadMoreItems = React.useCallback(
    function handleLoadMore() {
      startTransition(async () => {
        try {
          const args = discardNullableValues<Parameters<typeof loadMoreLiveBlogItems>[0]>({
            lastItemId: firstItemId,
            area: 'top',
            postCacheParams: 'sa-live-blog-items',
            sort: order,
          });
          const response = await loadMoreLiveBlogItems(args);

          response.firstItemId && setFirstItemId(response.firstItemId);
          setItemsJSX(prevItems => [...response.html, ...prevItems]);
        } catch (error) {
          throwError(error as Error);
        }
      });
    },
    [firstItemId, order, setFirstItemId]
  );

  React.useEffect(() => {
    if (firstItemId === featureLiveBlogItem) {
      handleLoadMoreItems();
    }
  }, [handleLoadMoreItems, firstItemId, featureLiveBlogItem]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      handleLoadMoreItems();
    }, UPDATE_INTERVAL); // NOTE: Every minute

    if (isStorybook) {
      handleLoadMoreItems();
    }

    return () => clearInterval(interval);
  }, [handleLoadMoreItems]);

  function displayItems() {
    if (itemsJSX.length) {
      setItemsJSXToDisplay(prevItems => [...itemsJSX, ...prevItems]);
      setItemsJSX([]);
      anchorRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }

  const site: Site = (process.env.site as Site) || 'htz';
  const appName: SiteName = site;

  React.useEffect(() => {
    if (!isStorybook) {
      FaviconBadge(appName, itemsJSX.length);
    }
  }, [appName, itemsJSX.length]);

  const chipTextStart = fork({
    default: itemsJSX.length === 1 ? 'נוסף' : 'נוספו',
    hdc: itemsJSX.length === 1 ? 'New' : '',
  });

  const chipBoldText = fork({
    default:
      itemsJSX.length === 1 ? 'עדכון' : itemsJSX.length > 1 ? `${itemsJSX.length} עדכונים` : '',
    hdc: itemsJSX.length > 1 ? `${itemsJSX.length} new` : '',
  });

  const chipTextEnd = fork({
    default: itemsJSX.length === 1 ? 'חדש' : 'חדשים',
    hdc: itemsJSX.length === 1 ? 'update' : 'updates',
  });

  return (
    <div className={s9(c.wrapper)}>
      <div ref={anchorRef} className={s9(c.anchorPixel)} />
      {itemsJSX.length ? (
        <Chip onClick={displayItems} styleExtend={[c.button]} variant="liveBlog">
          {chipTextStart}
          <span className={s9(c.bold)}>{chipBoldText}</span>
          {chipTextEnd}
          <Icon icon="chevron" styleExtend={[c.chevron]} />
        </Chip>
      ) : null}
      {itemsJSXToDisplay}
    </div>
  );
}

export default function LiveBlogStartItemsWrapper() {
  const { firstItemId, order } = useLiveBlog();

  if (!firstItemId || order === 'asc') return null;

  return <LiveBlogStartItems />;
}
