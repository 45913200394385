import * as Types from '@haaretz/s-fragments/Types';

import { UserCredentialsTypeFragmentGql } from '@haaretz/s-fragments/UserCredentialsType';
import { UserCredentialsTypeFragment } from '@haaretz/s-fragments/UserCredentialsType';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetch } from '@haaretz/s-graphql-fetcher';
export type RegistrationMutationVariables = Types.Exact<{
  input: Types.RegistrationInputType;
}>;


export type RegistrationMutation = (
  { registration: (
    UserCredentialsTypeFragment
    & { __typename: 'UserCredentialsType' }
  ) }
  & { __typename: 'Mutation' }
);


export const RegistrationMutationDocument = `
    mutation RegistrationMutation($input: RegistrationInputType!) {
  registration(input: $input) {
    ...UserCredentialsTypeFragment
  }
}
    ${UserCredentialsTypeFragmentGql}`;
export const useRegistrationMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<RegistrationMutation, TError, RegistrationMutationVariables, TContext>) =>
    useMutation<RegistrationMutation, TError, RegistrationMutationVariables, TContext>(
      ['RegistrationMutation'],
      (variables?: RegistrationMutationVariables) => fetch<RegistrationMutation, RegistrationMutationVariables>(RegistrationMutationDocument, variables)(),
      options
    );
useRegistrationMutation.getKey = () => ['RegistrationMutation'];

useRegistrationMutation.fetcher = (variables: RegistrationMutationVariables, options?: RequestInit['headers']) => fetch<RegistrationMutation, RegistrationMutationVariables>(RegistrationMutationDocument, variables, options);