'use client';

import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import typesetter from '@haaretz/l-type.macro';
import usePlatform from '@haaretz/s-atoms/platform';
import isNextLink from '@haaretz/s-url-utils/isNextLink';
import useDocumentEventListener from '@haaretz/s-use-native-application/useDocumentEventListener';
import { useRouter } from 'next/navigation';
import React from 'react';
import s9 from 'style9';

import ArticleBodyScrollObserver from '../ArticleBodyScrollObserver';
import Pixels from '../Pixels';
import Stock from '../Stock';

const c = s9.create({
  wrapper: {
    display: 'contents',
  },
  'p-2': {
    ...merge(
      mq({
        until: 's',
        value: {
          '--app-p-fz': typesetter(-1, { lines: 6, returnValue: 'fontSize' }),
          '--app-p-lh': typesetter(-1, { lines: 6, returnValue: 'lineHeight' }),
        },
      })
    ),
  },
  'p-1': {
    ...merge(
      mq({
        until: 's',
        value: {
          '--app-p-fz': typesetter(0, { lines: 7, returnValue: 'fontSize' }),
          '--app-p-lh': typesetter(0, { lines: 7, returnValue: 'lineHeight' }),
        },
      })
    ),
  },
  p: {
    ...merge(
      mq({
        until: 's',
        value: {
          '--app-p-fz': typesetter(1, { lines: 8, returnValue: 'fontSize' }),
          '--app-p-lh': typesetter(1, { lines: 8, returnValue: 'lineHeight' }),
        },
      })
    ),
  },
  'p+1': {
    ...merge(
      mq({
        until: 's',
        value: {
          '--app-p-fz': typesetter(2, { lines: 9, returnValue: 'fontSize' }),
          '--app-p-lh': typesetter(2, { lines: 9, returnValue: 'lineHeight' }),
        },
      })
    ),
  },
  'p+2': {
    ...merge(
      mq({
        until: 's',
        value: {
          '--app-p-fz': typesetter(4, { lines: 11, returnValue: 'fontSize' }),
          '--app-p-lh': typesetter(4, { lines: 11, returnValue: 'lineHeight' }),
        },
      })
    ),
  },
});

const pClasses = [c['p-2'], c['p-1'], c.p, c['p+1'], c['p+2']];
const maxFontSizeIdx = pClasses.length - 1;

interface ArticleBodyWrapperProps {
  hasNextLink?: boolean;
}
export default function ArticleBodyWrapper({
  children,
  hasNextLink,
}: React.PropsWithChildren<ArticleBodyWrapperProps>) {
  const router = useRouter();
  const bodyWrapperRef = React.useRef<HTMLDivElement>(null);
  const [fontSizeIdx, setFontSizeIdx] = React.useState(2);

  const platform = usePlatform();

  const changeFontSize = React.useCallback(
    function changeFontSize(delta: number) {
      if (platform !== 'app') return;
      const newFontSizeIdx = delta === 0 ? fontSizeIdx - 1 : fontSizeIdx + delta;
      setFontSizeIdx(Math.max(0, Math.min(newFontSizeIdx, maxFontSizeIdx)));
    },
    [fontSizeIdx, platform]
  );

  const useChangeFontSize = React.useCallback(
    (evt: CustomEvent<[number]>) => {
      const value = evt.detail?.[0] || -1;
      changeFontSize(value as number);
    },
    [changeFontSize]
  );

  useDocumentEventListener('changeFontSize', useChangeFontSize, true);

  React.useEffect(() => {
    const bodyWrapper = bodyWrapperRef.current;

    if (!hasNextLink || !bodyWrapper) return () => undefined;

    const clickHandler = (event: MouseEvent) => {
      if (event.ctrlKey || event.metaKey || event.altKey || event.shiftKey || event.button !== 0) {
        // Early return if clicked element is not simple left click
        return;
      }

      const { target } = event;
      if (!target) return;

      const link =
        'closest' in target &&
        typeof target.closest === 'function' &&
        (target.closest('a[href][target="_router"]') as HTMLAnchorElement | null);

      if (!link) {
        // Early return if clicked element is not a link
        return;
      }

      const href = link?.getAttribute('href');

      if (
        // Not a anchor element being clicked
        !link ||
        // No href attribute
        !href ||
        // External link
        link.origin !== window.location.origin ||
        // hash link within the same page
        /^#/.test(href) ||
        // Not an explicit router link
        !/^(_router)?$/i.test(link.target) ||
        // Not a next app url
        !isNextLink(href)
      ) {
        // Early return if clicked element is not a app router link
        return;
      }

      event.preventDefault();

      router.push(href);
    };

    bodyWrapper.addEventListener('click', clickHandler);

    return () => {
      bodyWrapper.removeEventListener('click', clickHandler);
    };
  }, [hasNextLink, platform, router]);

  return (
    <section
      className={s9(c.wrapper, platform === 'app' && pClasses[fontSizeIdx])}
      ref={bodyWrapperRef}
      data-testid="article-body-wrapper"
    >
      <ArticleBodyScrollObserver articleBodyWrapperRef={bodyWrapperRef}>
        <Pixels />
      </ArticleBodyScrollObserver>
      <Stock bodyRef={bodyWrapperRef} />
      {children}
    </section>
  );
}
