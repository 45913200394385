'use client';
import space from '@haaretz/l-space.macro';
import * as React from 'react';
import s9 from 'style9';

import type { NytFragment } from '@haaretz/s-fragments/embeds/NYT.embeds';
import type { StyleExtend } from '@haaretz/s-types';

// `c` is short for `classNames`
const c = s9.create({
  iframe: {
    borderWidth: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'absolute',
    left: 0,
    top: 0,
  },
  wrapper: {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    // HACK for aspect ratio 16:9 for video and text conteiner 72px
    paddingTop: `calc(56.25% + ${space(18)})`,
  },
  hidden: { visibility: 'hidden' },
});

export interface NYTProps extends NytFragment {
  styleExtend?: StyleExtend;
  children?: React.ReactNode;
}

export default function NYT({ source, children }: NYTProps) {
  const [showPlaceHolder, setShowPlaceHolder] = React.useState<boolean>(true);

  const iframeRef = React.useRef<HTMLIFrameElement | null>(null);
  React.useEffect(() => {
    const onLoad = () => {
      setShowPlaceHolder(false);
    };
    const iframe = iframeRef.current;
    if (iframe) {
      if (source) {
        iframe.src = source;
      }
      iframe.addEventListener('load', onLoad);

      return () => {
        iframe.removeEventListener('load', onLoad);
      };
    }
    return () => null;
  }, [source]);

  if (!source) return null;

  return (
    <div className={s9(c.wrapper)}>
      {showPlaceHolder && children}
      <iframe
        className={s9(c.iframe, showPlaceHolder && c.hidden)}
        title={'New York Times Video - Embed Player'}
        allowFullScreen
        src={source}
        ref={iframeRef}
        loading="lazy"
      />
    </div>
  );
}
