import * as Types from '@haaretz/s-fragments/Types';

export type ScatterGraphFragment = (
  { id?: string | null, name?: string | null, x?: number | null, y?: number | null }
  & { __typename: 'ScatterGraphData' }
);


export type ScatterGraphFragmentVariables = Types.Exact<{ [key: string]: never; }>;

export const ScatterGraphFragmentGql = `
    fragment ScatterGraphFragment on ScatterGraphData {
  __typename
  id
  name
  x
  y
}
    `;