import * as Types from '@haaretz/s-fragments/Types';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetch } from '@haaretz/s-graphql-fetcher';
export type SubmitNewVoteMutationVariables = Types.Exact<{
  input: Types.SubmitNewVoteInputType;
}>;


export type SubmitNewVoteMutation = (
  { submitNewVote: (
    { error?: string | null, status?: string | null }
    & { __typename: 'SubmitNewVoteResponse' }
  ) }
  & { __typename: 'Mutation' }
);


export const SubmitNewVoteDocument = `
    mutation SubmitNewVote($input: SubmitNewVoteInputType!) {
  submitNewVote(input: $input) {
    error
    status
  }
}
    `;
export const useSubmitNewVoteMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<SubmitNewVoteMutation, TError, SubmitNewVoteMutationVariables, TContext>) =>
    useMutation<SubmitNewVoteMutation, TError, SubmitNewVoteMutationVariables, TContext>(
      ['SubmitNewVote'],
      (variables?: SubmitNewVoteMutationVariables) => fetch<SubmitNewVoteMutation, SubmitNewVoteMutationVariables>(SubmitNewVoteDocument, variables)(),
      options
    );
useSubmitNewVoteMutation.getKey = () => ['SubmitNewVote'];

useSubmitNewVoteMutation.fetcher = (variables: SubmitNewVoteMutationVariables, options?: RequestInit['headers']) => fetch<SubmitNewVoteMutation, SubmitNewVoteMutationVariables>(SubmitNewVoteDocument, variables, options);