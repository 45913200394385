'use client';

import config from '@haaretz/l-config';
import usePlatform from '@haaretz/s-atoms/platform';
import useDocumentEventListener from '@haaretz/s-use-native-application/useDocumentEventListener';
import useOnce from '@haaretz/s-use-once';
import React from 'react';

/**
 * This hook is used to check if the native application has showing the webview.
 * If used in the web browser, it will return true immediately.
 *
 * @param onLoad - A callback to be called when the native application has showing the webview.
 */
export default function useLoadEvent(onLoad?: () => void) {
  const env = config.get('env');

  const platform = usePlatform();

  const [isShowing, setIsShowing] = React.useState(platform === 'app' && env === 'prod');

  const onShowingAudioReader = React.useCallback((event: CustomEvent<(string | undefined)[]>) => {
    const args = event.detail || [];

    const [action] = args;

    setIsShowing(action === 'show');
  }, []);

  useOnce(
    () => {
      onLoad?.();
    },
    isShowing && typeof onLoad === 'function'
  );

  useDocumentEventListener('toggleAudioReader', onShowingAudioReader, false);

  return isShowing;
}
