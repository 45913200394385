import * as Types from '@haaretz/s-fragments/Types';

import { AreaGraphFragmentGql } from '@haaretz/s-fragments/AreagGraph';
import { LineGraphFragmentGql } from '@haaretz/s-fragments/LineGraph';
import { ScatterGraphFragmentGql } from '@haaretz/s-fragments/ScatterGraph';
import { AreaGraphFragment } from '@haaretz/s-fragments/AreagGraph';
import { LineGraphFragment } from '@haaretz/s-fragments/LineGraph';
import { ScatterGraphFragment } from '@haaretz/s-fragments/ScatterGraph';
import { useQuery, useInfiniteQuery, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { fetch } from '@haaretz/s-graphql-fetcher';
export type StockMarketGraphQueryVariables = Types.Exact<{
  assetId: Types.Scalars['String'];
  period: Types.PeriodType;
  type: Types.GraphType;
  subType?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type StockMarketGraphQuery = (
  { graph?: (
    { dataSource: Array<(
      AreaGraphFragment
      & { __typename: 'AreaGraphData' }
    ) | (
      LineGraphFragment
      & { __typename: 'LineGraphData' }
    ) | (
      ScatterGraphFragment
      & { __typename: 'ScatterGraphData' }
    )> }
    & { __typename: 'financeGraph' }
  ) | null }
  & { __typename: 'Query' }
);


export const StockMarketGraphQueryDocument = `
    query StockMarketGraphQuery($assetId: String!, $period: PeriodType!, $type: GraphType!, $subType: String) {
  graph(id: $assetId, period: $period, type: $type, subType: $subType) {
    __typename
    dataSource {
      __typename
      ...AreaGraphFragment
      ...LineGraphFragment
      ...ScatterGraphFragment
    }
  }
}
    ${AreaGraphFragmentGql}
${LineGraphFragmentGql}
${ScatterGraphFragmentGql}`;
export const useStockMarketGraphQuery = <
      TData = StockMarketGraphQuery,
      TError = Error
    >(
      variables: StockMarketGraphQueryVariables,
      options?: UseQueryOptions<StockMarketGraphQuery, TError, TData>
    ) =>
    useQuery<StockMarketGraphQuery, TError, TData>(
      ['StockMarketGraphQuery', variables],
      fetch<StockMarketGraphQuery, StockMarketGraphQueryVariables>(StockMarketGraphQueryDocument, variables),
      options
    );
useStockMarketGraphQuery.document = StockMarketGraphQueryDocument;


useStockMarketGraphQuery.getKey = (variables: StockMarketGraphQueryVariables) => ['StockMarketGraphQuery', variables];
;

export const useInfiniteStockMarketGraphQuery = <
      TData = StockMarketGraphQuery,
      TError = Error
    >(
      pageParamKey: keyof StockMarketGraphQueryVariables,
      variables: StockMarketGraphQueryVariables,
      options?: UseInfiniteQueryOptions<StockMarketGraphQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<StockMarketGraphQuery, TError, TData>(
      ['StockMarketGraphQuery.infinite', variables],
      (metaData) => fetch<StockMarketGraphQuery, StockMarketGraphQueryVariables>(StockMarketGraphQueryDocument, {...variables, ...(metaData.pageParam ?? {})})(metaData),
      options
    )};


useInfiniteStockMarketGraphQuery.getKey = (variables: StockMarketGraphQueryVariables) => ['StockMarketGraphQuery.infinite', variables];
;

useStockMarketGraphQuery.fetcher = (variables: StockMarketGraphQueryVariables, options?: RequestInit['headers']) => fetch<StockMarketGraphQuery, StockMarketGraphQueryVariables>(StockMarketGraphQueryDocument, variables, options);