'use client';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import * as React from 'react';
import s9 from 'style9';

import type { GoogleMapsFragment } from '@haaretz/s-fragments/embeds/GoogleMaps.embeds';
import type { StyleExtend } from '@haaretz/s-types';

// `c` is short for `classNames`
const c = s9.create({
  iframe: {
    borderWidth: 0,
    position: 'absolute',
    left: 0,
    top: 0,
  },
  size: {
    width: '100%',
    aspectRatio: '9/13',
    ...merge(
      mq({
        from: 's',
        value: {
          aspectRatio: '16/9',
        },
      })
    ),
  },
  relative: { position: 'relative' },
  hidden: { visibility: 'hidden' },
});

export interface GoogleMapsProps extends GoogleMapsFragment {
  styleExtend?: StyleExtend;
  children?: React.ReactNode;
}

export default function GoogleMaps({ source, children }: GoogleMapsProps) {
  const [showPlaceHolder, setShowPlaceHolder] = React.useState<boolean>(true);
  const iframeRef = React.useRef<HTMLIFrameElement | null>(null);
  React.useEffect(() => {
    const onLoad = () => {
      setShowPlaceHolder(false);
    };
    const iframe = iframeRef.current;
    if (iframe) {
      if (source) {
        iframe.src = source;
      }
      iframe.addEventListener('load', onLoad);

      return () => {
        iframe.removeEventListener('load', onLoad);
      };
    }
    return () => null;
  }, [source]);
  if (!source) return null;

  return (
    <div className={s9(c.relative, c.size)}>
      {showPlaceHolder && children}
      <iframe
        ref={iframeRef}
        className={s9(c.iframe, showPlaceHolder ? c.hidden : c.relative, c.size)}
        loading="lazy"
        title={`GoogleMap-${source}`}
      />
    </div>
  );
}
