'use client';

/* eslint-disable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
// 1. The import order of macros matter and they must be kept in this order
// 2. Since macros are transpiled out during build, it is okay for them
//   to be imported even when they are not used.
// -- color must always be first -- //
import color from '@haaretz/l-color.macro';
// ---
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
// --- These return objects and must be spread or used inside `merge` --- //
import border from '@haaretz/l-border.macro';
import typesetter from '@haaretz/l-type.macro';
// --- These must come last --- //
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
/* eslint-enable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */

import Button from '@haaretz/s-button';
// TODO: Convert this to a dynamic import after the Next.js bug is fixed
//       (https://github.com/vercel/next.js/issues/63918)
import FomoTooltip from '@haaretz/s-fomo-tooltip';
import EmailField from '@haaretz/s-form-fields/EmailField';
import { useSubmitCommentNotificationEmailMutation } from '@haaretz/s-mutations/SubmitCommentNotificationEmail';
import useBi from '@haaretz/s-use-bi';
import useImpressionObserver from '@haaretz/s-use-impression-observer';
import React from 'react';
import s9 from 'style9';

import { CommentFormProps } from '../CommentForm';

import DialogWrapper, { SharedWrapperProps } from './DialogWrapper';

import type { ButtonState } from '@haaretz/s-button';
import type { State } from '@haaretz/s-form-fields/EmailField';
import type { InputMaybe } from '@haaretz/s-fragments/Types';

// `c` is short for `classNames`
const c = s9.create({
  successTextWrapper: {
    marginBottom: space(4),

    ...merge(
      mq({ from: 'l', until: 'xl', value: { ...typesetter(1) } }),
      mq({ from: 'xl', value: { ...typesetter(-1) } })
    ),
  },
  successTextBold: {
    fontWeight: 700,
  },

  displayByMq: {
    display: 'block',

    ...merge(mq({ from: 's', value: { display: 'inline' } })),
  },
  emailField: {
    marginBottom: space(7),

    ...mq({ from: 'xxl', value: { marginTop: space(2) } }),
  },
  successStageWrapper: {
    paddingRight: space(4),
    paddingLeft: space(4),
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingTop: space(11),
    paddingBottom: space(9),

    ...merge(
      mq({
        from: 'xl',
        until: 'xxl',
        value: { paddingRight: space(12.5), paddingLeft: space(12.5), paddingTop: space(14) },
      }),
      mq({
        from: 'xxl',
        value: {
          paddingRight: space(15),
          paddingLeft: space(15),
          paddingTop: space(12.5),
          paddingBottom: space(12.5),
        },
      })
    ),
  },
  fomoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    gap: space(3),
    fontWeight: 700,
    marginTop: space(3),
    paddingTop: space(3),
    paddingBottom: space(3),
    paddingLeft: space(4),
    paddingRight: space(4),
    ...border({
      color: color('neutral900'),
      spacing: 3,
      style: 'solid',
      side: 'top',
      width: 1,
    }),
    ...merge(
      mq({
        from: 's',
        value: {
          paddingTop: space(7),
          marginTop: space(7),
          flexDirection: 'row',
          gap: space(5),
          paddingInlineStart: space(0),
          paddingInlineEnd: space(0),
        },
      })
    ),
    ...merge(
      mq({ until: 's', value: { ...typesetter(-2) } }),
      mq({ from: 's', until: 'xl', value: { ...typesetter(0) } }),
      mq({ from: 'xl', until: 'xxl', value: { ...typesetter(-1) } }),
      mq({ from: 'xxl', value: { ...typesetter(-2) } })
    ),
  },
  fomoText: {
    whiteSpace: 'nowrap',
  },
  fomoButton: {
    border: 'none',
    ...merge(
      mq({
        until: 's',
        value: { ...typesetter(-2), paddingTop: space(0.5), paddingBottom: space(0.5) },
      }),
      mq({ from: 's', until: 'xxl', value: { ...typesetter(0) } }),
      mq({ from: 'xxl', value: { ...typesetter(-1) } })
    ),
  },
});

type SuccessDialogProps = {
  commentId: string | null;
  setCommentId: React.Dispatch<React.SetStateAction<string | null>>;
  userMail: string;
  isReplyForm?: CommentFormProps['isReplyForm'];
  cancelReplyForm?: CommentFormProps['cancelReplyForm'];
  sharedWrapperProps: SharedWrapperProps;
};

const successUpdateBtnText = fork({ default: 'עדכנו אותי', hdc: 'Keep me posted' });

export default function SuccessDialog({
  commentId,
  setCommentId,
  userMail,
  isReplyForm,
  cancelReplyForm,
  sharedWrapperProps,
}: SuccessDialogProps) {
  const [description, setDescription] = React.useState('');
  const [state, setState] = React.useState<State>('default');
  const submitNotificationEmail = useSubmitCommentNotificationEmailMutation();
  const formRef = React.useRef(null);
  const biAction = useBi();

  const biData = {
    feature: 'Talkbacks',
    feature_type: 'Content',
    campaign_name: 'new comment alert',
    campaign_details: successUpdateBtnText,
  };

  useImpressionObserver({
    elementRef: formRef,
    biData,
  });

  const buttonStateProps: ButtonState = submitNotificationEmail.isLoading
    ? { state: 'busy', busyNotice: fork({ default: 'ממתין לתשובה', hdc: 'Waiting for response' }) }
    : { state: 'auto' };

  const errorHandler = () => {
    setDescription(
      fork({
        default: 'אירעה שגיאה, אנא נסו מאוחר יותר',
        hdc: 'Something went wrong, please try again later',
      })
    );
    setState('invalid');
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const emailInput = e.currentTarget.elements.namedItem('email') as InputMaybe<HTMLInputElement>;

    biAction({
      ...biData,
      action_id: 173,
    });

    if (emailInput && commentId) {
      submitNotificationEmail.mutate(
        { input: { authorEmail: emailInput.value, commentId } },
        {
          onSuccess: ({ submitCommentNotificationEmail: { status } }) => {
            if (status === 'ok') {
              sharedWrapperProps.setDialogType('none');
              setDescription('');
              setState('default');
              setCommentId(null);
              if (isReplyForm && cancelReplyForm) cancelReplyForm();
            } else {
              errorHandler();
            }
          },
          onError: () => {
            errorHandler();
          },
        }
      );
    }
  };

  return (
    <DialogWrapper
      {...sharedWrapperProps}
      styleExtend={[c.successStageWrapper]}
      cancelReplyForm={cancelReplyForm}
      isReplyForm={isReplyForm}
    >
      <form onSubmit={onSubmit} ref={formRef}>
        <div className={s9(c.successTextWrapper)}>
          <span className={s9(c.successTextBold)}>
            {fork({ default: 'תגובתך נקלטה בהצלחה, ', hdc: 'Thank you!' })}
          </span>
          {fork({
            default: 'ותפורסם על פי מדיניות המערכת.',
            hdc: 'Your comment was successfully submitted and will be published in accordance with site policy. If you would like to be notified when your comment is published, please fill in your email address in the form below.',
          })}
          <span className={s9(c.displayByMq)}>
            {fork({ default: 'באפשרותך לקבל התראה באימייל כאשר תגובתך תאושר ותפורסם' })}
          </span>
        </div>
        <EmailField
          styleExtend={[c.emailField]}
          externalState={{
            descriptionState: description,
            stateProp: state,
            setStateProp: setState,
            setDescriptionProp: setDescription,
          }}
          defaultValue={userMail}
          required
        />
        <Button priority="secondary" type="submit" {...buttonStateProps}>
          {successUpdateBtnText}
        </Button>
      </form>
      <FomoTooltip
        text={fork({
          default: 'רוצה להמשיך ליהנות מקדימות בתגובות?',
          hdc: 'Want to continue enjoying priority in comments?',
        })}
        buttonText={fork({
          default: 'לחידוש המינוי',
          hdc: 'Renew subscription',
        })}
        wrapperStyleExtend={[c.fomoWrapper]}
        textStyleExtend={[c.fomoText]}
        buttonStyleExtend={[c.fomoButton]}
        biData={{
          feature: 'New comment alert',
          feature_type: 'Marketing',
          campaign_name: 'fomo',
          campaign_details: 'new comment',
        }}
      />
    </DialogWrapper>
  );
}
