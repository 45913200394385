import * as Types from '@haaretz/s-fragments/Types';

import { useQuery, useInfiniteQuery, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { fetch } from '@haaretz/s-graphql-fetcher';
export type GetUserNicknamePersonalQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetUserNicknamePersonalQuery = (
  { getUserNickname: (
    { error?: string | null, status?: string | null, nickname?: string | null }
    & { __typename: 'GetUserNicknameResponse' }
  ) }
  & { __typename: 'Query' }
);


export const GetUserNicknamePersonalQueryDocument = `
    query GetUserNicknamePersonalQuery {
  getUserNickname {
    error
    status
    nickname
  }
}
    `;
export const useGetUserNicknamePersonalQuery = <
      TData = GetUserNicknamePersonalQuery,
      TError = Error
    >(
      variables?: GetUserNicknamePersonalQueryVariables,
      options?: UseQueryOptions<GetUserNicknamePersonalQuery, TError, TData>
    ) =>
    useQuery<GetUserNicknamePersonalQuery, TError, TData>(
      variables === undefined ? ['GetUserNicknamePersonalQuery'] : ['GetUserNicknamePersonalQuery', variables],
      fetch<GetUserNicknamePersonalQuery, GetUserNicknamePersonalQueryVariables>(GetUserNicknamePersonalQueryDocument, variables),
      options
    );
useGetUserNicknamePersonalQuery.document = GetUserNicknamePersonalQueryDocument;


useGetUserNicknamePersonalQuery.getKey = (variables?: GetUserNicknamePersonalQueryVariables) => variables === undefined ? ['GetUserNicknamePersonalQuery'] : ['GetUserNicknamePersonalQuery', variables];
;

export const useInfiniteGetUserNicknamePersonalQuery = <
      TData = GetUserNicknamePersonalQuery,
      TError = Error
    >(
      pageParamKey: keyof GetUserNicknamePersonalQueryVariables,
      variables?: GetUserNicknamePersonalQueryVariables,
      options?: UseInfiniteQueryOptions<GetUserNicknamePersonalQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetUserNicknamePersonalQuery, TError, TData>(
      variables === undefined ? ['GetUserNicknamePersonalQuery.infinite'] : ['GetUserNicknamePersonalQuery.infinite', variables],
      (metaData) => fetch<GetUserNicknamePersonalQuery, GetUserNicknamePersonalQueryVariables>(GetUserNicknamePersonalQueryDocument, {...variables, ...(metaData.pageParam ?? {})})(metaData),
      options
    )};


useInfiniteGetUserNicknamePersonalQuery.getKey = (variables?: GetUserNicknamePersonalQueryVariables) => variables === undefined ? ['GetUserNicknamePersonalQuery.infinite'] : ['GetUserNicknamePersonalQuery.infinite', variables];
;

useGetUserNicknamePersonalQuery.fetcher = (variables?: GetUserNicknamePersonalQueryVariables, options?: RequestInit['headers']) => fetch<GetUserNicknamePersonalQuery, GetUserNicknamePersonalQueryVariables>(GetUserNicknamePersonalQueryDocument, variables, options);