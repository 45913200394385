import * as Types from '@haaretz/s-fragments/Types';

import { SubmitNewCommentResponseFragmentGql } from '@haaretz/s-fragments/SubmitNewCommentResponse';
import { SubmitNewCommentResponseFragment } from '@haaretz/s-fragments/SubmitNewCommentResponse';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetch } from '@haaretz/s-graphql-fetcher';
export type SubmitNewCommentPersonalMutationVariables = Types.Exact<{
  input: Types.SubmitNewCommentInputType;
}>;


export type SubmitNewCommentPersonalMutation = (
  { submitNewComment: (
    SubmitNewCommentResponseFragment
    & { __typename: 'SubmitNewCommentResponse' }
  ) }
  & { __typename: 'Mutation' }
);


export const SubmitNewCommentPersonalMutationDocument = `
    mutation SubmitNewCommentPersonalMutation($input: SubmitNewCommentInputType!) {
  submitNewComment(input: $input) {
    ...SubmitNewCommentResponseFragment
  }
}
    ${SubmitNewCommentResponseFragmentGql}`;
export const useSubmitNewCommentPersonalMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<SubmitNewCommentPersonalMutation, TError, SubmitNewCommentPersonalMutationVariables, TContext>) =>
    useMutation<SubmitNewCommentPersonalMutation, TError, SubmitNewCommentPersonalMutationVariables, TContext>(
      ['SubmitNewCommentPersonalMutation'],
      (variables?: SubmitNewCommentPersonalMutationVariables) => fetch<SubmitNewCommentPersonalMutation, SubmitNewCommentPersonalMutationVariables>(SubmitNewCommentPersonalMutationDocument, variables)(),
      options
    );
useSubmitNewCommentPersonalMutation.getKey = () => ['SubmitNewCommentPersonalMutation'];

useSubmitNewCommentPersonalMutation.fetcher = (variables: SubmitNewCommentPersonalMutationVariables, options?: RequestInit['headers']) => fetch<SubmitNewCommentPersonalMutation, SubmitNewCommentPersonalMutationVariables>(SubmitNewCommentPersonalMutationDocument, variables, options);