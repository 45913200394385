'use client';
import space from '@haaretz/l-space.macro';
import * as React from 'react';
import s9 from 'style9';

import type { RainRadarFragment } from '@haaretz/s-fragments/embeds/RainRadar.embeds';
import type { StyleExtend } from '@haaretz/s-types';

// `c` is short for `classNames`
const c = s9.create({
  iframe: {
    borderWidth: 0,
    position: 'absolute',
    left: 0,
    top: 0,
  },
  wrap: {
    position: 'relative',
    width: '100%',
    height: '100vw',
    maxWidth: space(200),
    maxHeight: space(150),
  },
  relative: { position: 'relative' },
  hidden: { visibility: 'hidden' },
  size: {
    width: '100%',
    aspectRatio: '1',
    maxWidth: space(200),
    maxHeight: space(150),
  },
});

export interface RainRadarProps extends RainRadarFragment {
  styleExtend?: StyleExtend;
  children?: React.ReactNode;
}

export default function RainRadar({ source, children }: RainRadarProps) {
  const [showPlaceHolder, setShowPlaceHolder] = React.useState<boolean>(true);

  const onLoad = () => {
    setShowPlaceHolder(false);
  };
  if (!source) return null;

  return (
    <div className={s9(c.wrap)}>
      {showPlaceHolder && children}
      <iframe
        className={s9(c.iframe, c.size, showPlaceHolder ? c.hidden : c.relative)}
        src={source}
        title={`RainRadar-${source}`}
        loading="lazy"
        onLoad={onLoad}
      />
    </div>
  );
}
