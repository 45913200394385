'use client';

import React from 'react';

import type { LineGraphFragment } from '@haaretz/s-fragments/LineGraph';

type SelectedAssetContextValue = LineGraphFragment | null;

type SelectedAssetContextUpdaterValue = React.Dispatch<
  React.SetStateAction<LineGraphFragment | null>
>;

export const SelectedAssetContextUpdater = React.createContext<SelectedAssetContextUpdaterValue>(
  () => {}
);

export const SelectedAssetContext = React.createContext<SelectedAssetContextValue>(null);

export function SelectedAssetProvider({ children }: { children: React.ReactNode }) {
  const [selectedAsset, setSelectedAsset] = React.useState<LineGraphFragment | null>(null);

  return (
    <SelectedAssetContext.Provider value={selectedAsset}>
      <SelectedAssetContextUpdater.Provider value={setSelectedAsset}>
        {children}
      </SelectedAssetContextUpdater.Provider>
    </SelectedAssetContext.Provider>
  );
}
