'use client';
import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import space from '@haaretz/l-space.macro';
import { widthBpsInEm } from '@haaretz/l-theme-shared/lib/consts';
import zIndex from '@haaretz/l-z-index.macro';
import Caption from '@haaretz/s-caption';
import ClickArea from '@haaretz/s-click-area';
import HtzImage from '@haaretz/s-htz-image';
import HtzLink from '@haaretz/s-htz-link';
import PictureSource from '@haaretz/s-htz-picture/source';
import Icon from '@haaretz/s-icon';
import getAspectData from '@haaretz/s-image-utils/getAspectData';
import useBreakpoint from '@haaretz/s-use-breakpoint';
import VisuallyHidden from '@haaretz/s-visually-hidden';
import * as React from 'react';
import s9 from 'style9';

import type { ImageFragment } from '@haaretz/s-fragments/HTZ_image_Image';
import type { HtzImageProps } from '@haaretz/s-htz-image';
import type { SizesType } from '@haaretz/s-image-utils/buildSizesString';

const c = s9.create({
  container: {
    position: 'relative',
  },
  zoomIn: {
    cursor: 'zoom-in',
  },
  background: {
    position: 'fixed',
    opacity: 0,
    top: 0,
  },
  backgroundClicked: {
    backgroundColor: color('neutral100'),
    bottom: 0,
    cursor: 'zoom-out',
    left: 0,
    opacity: 1,
    position: 'fixed',
    right: 0,
    top: 0,
    transitionDelay: '0',
    transitionDuration: '200ms',
    transitionProperty: 'opacity',
    transitionTimingFunction: 'ease',
    zIndex: zIndex('modal'),
  },
  fullImg: {
    opacity: 0,
    pointerEvents: 'none',
    position: 'absolute',
    transitionDelay: '200ms, 0ms, 0ms, 0ms, 0ms',
    transitionDuration: '0ms, 200ms, 200ms, 200ms,200ms',
    transitionProperty: 'opacity, top, left, width, height',
    transitionTimingFunction: 'ease-in, ease-in, ease-in, ease-in, ease-in',
    width: '100%',
    zIndex: zIndex('modal'),
  },
  visible: {
    opacity: 1,
    pointerEvents: 'auto',
    transitionDelay: '0ms, 0ms, 0ms, 0ms, 0ms',
  },
  close: {
    left: space(6),
    position: 'fixed',
    top: space(6),
  },
  icon: {
    backgroundColor: color('neutral100'),
    fontSize: space(4.5),
  },
  caption: {
    opacity: 0,
    transitionDelay: '0ms',
    transitionDuration: '0ms',
    transitionProperty: 'opacity',
    transitionTimingFunction: 'ease-in',
  },
  captionVisible: {
    opacity: 1,
    transitionDelay: '200ms',
  },
  img: {
    opacity: 1,
    transitionDelay: '200ms',
    transitionDuration: '0ms',
    transitionProperty: 'opacity',
    transitionTimingFunction: 'ease-in',
  },
  imgHidden: {
    opacity: 0,
    transitionDelay: '0ms',
  },
  fullImgCaption: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
});

export type OpenedImageProps = Omit<HtzImageProps, 'widths' | 'sizes' | 'imgData'> & {
  files: ImageFragment['files'];
};

const fullImgSizes: SizesType = [
  { from: 'xxl', size: '1532px' },
  { from: 'xl', size: '1180px' },
  { from: 'l', size: '900px' },
  { size: '100vw' },
];
const fullImgWidths = [420, 600, 768, 900, 1180, 1500];
const L_PADDING = 80;
const XL_PADDING = 100;
const XXL_PADDING = 108;
const MIN_PADDING = 48;

const OpenedImage = function OpenedImage(imgProps: OpenedImageProps) {
  const { caption, credit, aspect, link, type, contentId, files } = imgProps;

  const fullImgCaptionHeight = caption && credit ? 40 : 20;
  const [isOpen, setIsOpen] = React.useState<boolean | null>(null);
  const imgRef = React.useRef<HTMLDivElement>(null);
  const imgFullRef = React.useRef<HTMLDivElement>(null);
  const closeRef = React.useRef<HTMLButtonElement>(null);
  const breakpoint = useBreakpoint();

  const onScroll = React.useCallback(() => {
    setIsOpen(false);
  }, []);
  const onEscapeButtonClick = React.useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsOpen(false);
    }
  }, []);

  const [firstImageFile, secondImageFile] = files;
  const imgData = secondImageFile || firstImageFile;

  const imgDataInfographic =
    breakpoint && ['default'].includes(breakpoint) ? secondImageFile : firstImageFile;

  const imgDataResult = type === 'infographic' ? imgDataInfographic : imgData;

  const { aspectData } = getAspectData(aspect, imgDataResult);
  const { height, width } = aspectData
    ? aspectData
    : { width: imgDataResult.width, height: imgDataResult.height };

  React.useEffect(() => {
    if (isOpen) {
      if (
        imgFullRef.current?.clientHeight &&
        imgRef.current?.getBoundingClientRect().y &&
        closeRef.current
      ) {
        if (breakpoint) {
          closeRef.current.style.display = 'none';

          const isVertical =
            (aspect === 'vertical' && ['default', 's', 'm'].includes(breakpoint)) ||
            (['full', 'fullimage'].includes(aspect) && width / height < 1);

          const openImgWidths = {
            default: window.innerWidth,
            s: window.innerWidth,
            m: window.innerWidth,
            l: window.innerWidth - L_PADDING * 2,
            xl: window.innerWidth - XL_PADDING * 2,
            xxl: window.innerWidth - XXL_PADDING * 2,
          };

          const captionHeight = caption && fullImgCaptionHeight ? fullImgCaptionHeight : 0;
          const fullImgHeightWithCaption =
            openImgWidths[breakpoint] * (height / width) + captionHeight + MIN_PADDING;

          // if client height less then height of img containter
          // resize img containteiner to max height with saving aspect ratio
          let fullImgWidth =
            window.innerHeight > fullImgHeightWithCaption
              ? openImgWidths[breakpoint]
              : (window.innerHeight - captionHeight - MIN_PADDING) * (width / height);

          if (isVertical) {
            const marginIcon = 24;
            fullImgWidth = fullImgWidth - marginIcon * 4;
          }

          // position img in middle screen
          const rectImg = imgRef.current?.getBoundingClientRect();
          const left = -(rectImg.left - (window.innerWidth - fullImgWidth) / 2);
          imgFullRef.current.style.left = `${left}px`;

          const resizedFullImgHeightWithCaption = fullImgWidth * (height / width) + captionHeight;

          const top = -(rectImg.top - (window.innerHeight - resizedFullImgHeightWithCaption) / 2);

          imgFullRef.current.style.top = `${top}px`;

          // changing size our img
          imgFullRef.current.style.width = `${fullImgWidth}px`;
          imgFullRef.current.style.height = `${fullImgWidth * (height / width)}px`;
        }
      }
      document.addEventListener('scroll', onScroll);
      document.addEventListener('keydown', onEscapeButtonClick);
    } else {
      // reset sizes
      if (closeRef.current) {
        closeRef.current.style.display = 'none';
      }
      if (imgFullRef.current?.clientHeight && imgRef.current?.getBoundingClientRect().y) {
        imgFullRef.current.style.top = '0px';
        imgFullRef.current.style.left = '0px';
        imgFullRef.current.style.width = '100%';
        imgFullRef.current.style.height = '100%';
      }
      document.removeEventListener('scroll', onScroll);
      document.removeEventListener('keydown', onEscapeButtonClick);
    }
  }, [
    breakpoint,
    aspect,
    isOpen,
    onEscapeButtonClick,
    onScroll,
    height,
    width,
    caption,
    fullImgCaptionHeight,
  ]);

  if (link) {
    return (
      <HtzLink href={link}>
        <HtzImage {...imgProps} imgData={imgData} sizes={fullImgSizes} widths={fullImgWidths} />
      </HtzLink>
    );
  }

  const ImageOrPicture =
    type === 'infographic' ? (
      <picture>
        <PictureSource
          key={firstImageFile.path}
          imgData={firstImageFile}
          aspect="full"
          contentId={contentId}
          media={`(min-width:${widthBpsInEm.s}em)`}
          widths={fullImgWidths}
          sizes={fullImgSizes}
        />
        <HtzImage
          {...imgProps}
          imgData={secondImageFile || firstImageFile}
          sizes={fullImgSizes}
          widths={fullImgWidths}
          styleExtend={[c.zoomIn]}
          onClick={() => {
            setIsOpen(true);
          }}
        />
      </picture>
    ) : (
      <HtzImage
        {...imgProps}
        imgData={imgData}
        sizes={fullImgSizes}
        widths={fullImgWidths}
        styleExtend={[c.zoomIn]}
        onClick={() => {
          setIsOpen(true);
        }}
      />
    );
  return (
    <div className={s9(c.container)} suppressHydrationWarning>
      <div ref={imgRef} className={s9(c.img, isOpen && c.imgHidden)} data-testid="opened-img">
        {ImageOrPicture}
      </div>
      <div // eslint-disable-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
        className={s9(isOpen ? c.backgroundClicked : c.background)}
        onClick={() => setIsOpen(false)}
      />
      <div
        className={s9(c.fullImg, isOpen && c.visible)}
        onTransitionEnd={() => {
          if (closeRef.current && isOpen) closeRef.current.style.display = 'block';
        }}
        ref={imgFullRef}
        data-testid="opened-full-img"
      >
        <HtzImage
          {...imgProps}
          imgData={type === 'infographic' ? imgDataInfographic : imgData}
          sizes={fullImgSizes}
          widths={fullImgWidths}
        />
        <ClickArea
          styleExtend={[c.close]}
          disableRipple
          onClick={() => setIsOpen(false)}
          ref={closeRef}
          title={fork({ default: 'סגירה', hdc: 'Close' })}
        >
          <Icon icon="close" styleExtend={[c.icon]} />
          <VisuallyHidden>{fork({ default: 'סגירה', hdc: 'Close' })}</VisuallyHidden>
        </ClickArea>
        {caption && (
          <div className={s9(c.caption, isOpen && c.captionVisible)}>
            <Caption caption={caption} credit={credit} styleExtend={[c.fullImgCaption]} />
          </div>
        )}
      </div>
    </div>
  );
};

export default OpenedImage;
