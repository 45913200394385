'use client';
import fork from '@haaretz/l-fork.macro';
import space from '@haaretz/l-space.macro';
import * as React from 'react';
import s9 from 'style9';

import type { OmnyStudioFragment } from '@haaretz/s-fragments/embeds/OmnyStudio.embeds';
import type { StyleExtend } from '@haaretz/s-types';

// `c` is short for `classNames`
const c = s9.create({
  iframe: {
    borderWidth: 0,
    width: '100%',
    height: space(37.5),
    position: 'absolute',
    left: 0,
    top: 0,
  },
  wrap: {
    position: 'relative',
    width: '100%',
    height: space(37.5),
  },
  relative: { position: 'relative' },
  hidden: { visibility: 'hidden' },
});
export interface OmnyStudioProps extends OmnyStudioFragment {
  styleExtend?: StyleExtend;
  children?: React.ReactNode;
}

export default function OmnyStudio({ source, description, fileUrl, children }: OmnyStudioProps) {
  const [showPlaceHolder, setShowPlaceHolder] = React.useState<boolean>(true);
  const iframeRef = React.useRef<HTMLIFrameElement | null>(null);
  const highlight = fork({
    default: '09a5d9',
    tm: '00c800',
  });
  const src = `${source}?&download=${fileUrl}&description=${description}&foreground=222222&background=f3f3f3&highlight=${highlight}`;

  React.useEffect(() => {
    const onLoad = () => {
      setShowPlaceHolder(false);
    };
    const iframe = iframeRef.current;
    if (iframe) {
      if (src) {
        iframe.src = src;
      }
      iframe.addEventListener('load', onLoad);

      return () => {
        iframe.removeEventListener('load', onLoad);
      };
    }
    return () => null;
  }, [src]);

  return (
    <div className={s9(c.wrap)}>
      {showPlaceHolder && children}
      <iframe
        ref={iframeRef}
        className={s9(c.iframe, showPlaceHolder ? c.hidden : c.relative)}
        src={src}
        title={`OmnyStudio-${source}`}
        loading="lazy"
      />
    </div>
  );
}
