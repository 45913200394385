import * as Types from '@haaretz/s-fragments/Types';

export type SubmitNewCommentResponseFragment = (
  { error?: string | null, status?: string | null, commentId?: string | null, errorCode?: number | null }
  & { __typename: 'SubmitNewCommentResponse' }
);


export type SubmitNewCommentResponseFragmentVariables = Types.Exact<{ [key: string]: never; }>;

export const SubmitNewCommentResponseFragmentGql = `
    fragment SubmitNewCommentResponseFragment on SubmitNewCommentResponse {
  error
  status
  commentId
  errorCode
}
    `;