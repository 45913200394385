'use client';

import color from '@haaretz/l-color.macro';
import config from '@haaretz/l-config';
import fork from '@haaretz/l-fork.macro';
import space from '@haaretz/l-space.macro';
import useArticleId from '@haaretz/s-atoms/articleId';
import usePlatform from '@haaretz/s-atoms/platform';
import useUser from '@haaretz/s-atoms/user';
import ClickArea from '@haaretz/s-click-area';
import Icon from '@haaretz/s-icon';
import { useGenerateGiftTokenPersonalMutation } from '@haaretz/s-mutations/generateGiftToken';
import Popover from '@haaretz/s-popover';
import useBi from '@haaretz/s-use-bi';
import VisuallyHidden from '@haaretz/s-visually-hidden';
import * as React from 'react';
import s9 from 'style9';

import ActionBtnPopover from '../../ActionBtnPopover';

import type { ActionBtnWithOffsetProps, ActionBarProps } from '../../types';

// `c` is short for `classNames`
const c = s9.create({
  popover: {
    maxWidth: space(73),
  },
  countText: {
    color: color('primary900'),
    fontWeight: 700,
  },
  exceededText: {
    paddingInlineEnd: space(2),
  },
  exceededPopover: {
    minWidth: space(68),
    maxWidth: space(68),
  },
  popoverBorder: {
    padding: 0,
  },
});

export type PopoverType = 'success' | 'exceeded' | 'error' | 'nonPaying';

interface GiftBtnProps extends ActionBtnWithOffsetProps {
  canonicalUrl: ActionBarProps['canonicalUrl'];
  title: ActionBarProps['title'];
}

function removeStrongTag(htmlString: string | null | undefined) {
  if (!htmlString) return null;
  const updatedHtmlString = htmlString.replace(/<\/?strong\b[^>]*>/g, '');
  return updatedHtmlString;
}

const promotionUrl = config.get('promotionUrl');
const promotionsLink = `${promotionUrl}?htm_source=site&htm_medium=button&htm_campaign=gift&htm_content=subscribe`;

const BI_BASE_DATA = {
  feature: 'Article Page - Gift Icon',
  feature_type: 'Content',
  campaign_name: 'Gift article',
};

export default function GiftBtn({
  clickAreaStyleExtend,
  iconStyleExtend,
  offsetValue,
  canonicalUrl,
  title,
  id,
}: GiftBtnProps) {
  const articleId = useArticleId();
  const platform = usePlatform();
  const user = useUser('cookieValue');
  const biAction = useBi();

  const isPayingUser = user.userType === 'paying';

  const refersToRef = React.useRef(null);
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const [giftCount, setGiftCount] = React.useState(10);
  const [popoverType, setPopoverType] = React.useState<PopoverType | null>(
    isPayingUser ? null : 'nonPaying'
  );
  const generateGiftTokenMutation = useGenerateGiftTokenPersonalMutation();
  const [hasBeenCopied, setHasBeenCopied] = React.useState(false);
  const [shouldMutate, setShouldMutate] = React.useState(true);
  const [url, setUrl] = React.useState<null | string>(null);
  const isMobile = platform === 'mobile';

  const updatedTitle = removeStrongTag(title);

  const navigatorShareTitle = `${fork({
    default: 'קיבלת במתנה כתבה מהארץ!',
    tm: 'קיבלת במתנה כתבה מ-TheMarker!',
  })}\n${updatedTitle || title}`;

  const shareToWhatsapp = () => {
    if (!url) return;

    const msg = encodeURIComponent(`${navigatorShareTitle}\n${url}`);
    const whatsappUrl = `${isMobile ? 'whatsapp://' : 'https://web.whatsapp.com/'}send?text=${msg}`;

    if (isMobile) {
      window.location.href = whatsappUrl;
    } else {
      window.open(
        whatsappUrl,
        'popup',
        'width=635,height=800,scrollbars=no,resizable=no,toolbar=no,directories=no,location=no,menubar=no,status=no'
      );
    }

    biAction({
      ...BI_BASE_DATA,
      action_id: 169,
      campaign_details: 'Gift sent from subscriber - whatsapp',
    });
  };

  const onSuccessHandler = async (urlWithToken: string) => {
    const shareData = {
      text: navigatorShareTitle,
      url: urlWithToken,
    };

    const nativeShareSupported = navigator?.canShare && navigator?.canShare(shareData);

    if (nativeShareSupported && isMobile) {
      await navigator.share(shareData);
    } else {
      setPopoverType('success');
    }
  };

  const onClick = async () => {
    if (isPayingUser) {
      // prevent an extra netwrok request when closing the popover clicking on the reference element
      setShouldMutate(!isPopoverOpen);

      if (shouldMutate && !url) {
        generateGiftTokenMutation.mutate(
          {
            input: {
              articleId: articleId || '',
            },
          },
          {
            onSuccess: async ({ generateGiftToken: { data } }) => {
              if (data?.status === 'exceeded') {
                setGiftCount(data.count);
                setPopoverType('exceeded');
              } else if (data?.status === 'ok' && data?.token) {
                const urlWithToken = `${canonicalUrl}?gift=${data.token}`;
                setUrl(urlWithToken);
                onSuccessHandler(urlWithToken);
              } else {
                setPopoverType('error');
              }
            },
            onError: err => {
              console.error(err);
              setPopoverType('error');
            },
          }
        );
      } else if (url) {
        onSuccessHandler(url);
      }
    }
  };

  function onToggle(isOpenFromPopover: boolean) {
    setIsPopoverOpen(isOpenFromPopover);

    if (isOpenFromPopover) {
      biAction({
        ...BI_BASE_DATA,
        action_id: 169,
      });
    } else {
      hasBeenCopied && setHasBeenCopied(false);
    }
  }

  const copyOnClick = () => {
    if (url) {
      navigator?.clipboard?.writeText(url);
      setHasBeenCopied(true);
      biAction({
        ...BI_BASE_DATA,
        action_id: 169,
        campaign_details: 'Gift sent from subscriber - copy link',
      });
    }
  };

  const sharedProps = {
    styleExtend: [c.popover],
    onToggle,
    onClose: () => {
      setIsPopoverOpen(false);
      hasBeenCopied && setHasBeenCopied(false);
    },
    popoverType,
  };

  const popoverByType = {
    success: (
      <ActionBtnPopover
        title={fork({ default: 'כתבה במתנה' })}
        subTitle={fork({ default: 'לשלוח את הכתבה ללא תשלום לחברים' })}
        btn1={{
          text: hasBeenCopied
            ? fork({ default: 'הלינק הועתק', hdc: 'Link copied' })
            : fork({ default: 'העתק לינק', hdc: 'Copy the link' }),
          icon: hasBeenCopied ? 'check' : 'link',
          iconVariant: hasBeenCopied ? 'success' : 'brand',
          onClick: copyOnClick,
        }}
        btn2={{
          text: fork({ default: 'שתף בוואטסאפ', hdc: 'Share on WhatsApp' }),
          icon: 'whatsapp',
          onClick: shareToWhatsapp,
        }}
        {...sharedProps}
        biData={{
          feature: 'Web gift popup - subs',
          feature_type: 'Content',
          campaign_name: 'Gift article',
          campaign_details: 'Gift article',
        }}
        enableFomoTooltip={true}
        fomoTooltipProps={{
          text: fork({
            default: 'רוצה להמשיך לשלוח כתבות במתנה?',
            hdc: 'Want to continue gifting articles?',
          }),
          buttonText: fork({ default: 'לחידוש המינוי', hdc: 'Renew subscription' }),
        }}
      />
    ),
    exceeded: (
      <ActionBtnPopover
        {...sharedProps}
        styleExtend={[c.exceededPopover]}
        biData={{
          feature: 'Web gift popup - subs',
          feature_type: 'Content',
          campaign_name: 'Gift article',
          campaign_details: 'subscriber exceeded max gifts',
        }}
      >
        <div className={s9(c.exceededText)}>
          שלחת <span className={s9(c.countText)}>{giftCount}</span> כתבות במתנה החודש. החל מחודש הבא
          ניתן יהיה לשלוח {giftCount} כתבות נוספות
        </div>
      </ActionBtnPopover>
    ),
    error: (
      <ActionBtnPopover {...sharedProps}>
        {fork({ default: 'לא ניתן לשלוח כרגע מתנות. אנא נסו שוב מאוחר יותר' })}
      </ActionBtnPopover>
    ),
    nonPaying: (
      <ActionBtnPopover
        title={fork({ default: 'כתבה במתנה' })}
        subTitle={fork({ default: 'רכשו מינוי ותוכלו לשלוח כתבות במתנה לחברים ולמשפחה' })}
        btn1={{
          text: fork({ default: 'לרכישה' }),
          buttonComponent: {
            variant: 'sales',
            href: promotionsLink,
          },
          onClick: () => {
            biAction({
              feature: 'Web gift popup - non subs',
              feature_type: 'Content',
              campaign_name: 'Gift article',
              campaign_details: 'Subscribe to send gift article',
              action_id: 3,
            });
          },
        }}
        {...sharedProps}
        biData={{
          feature: 'Web gift popup - non subs',
          feature_type: 'Content',
          campaign_name: 'Gift article',
          campaign_details: 'Subscribe to send gift article',
        }}
      />
    ),
  };

  return (
    <>
      <ClickArea
        styleExtend={clickAreaStyleExtend}
        rippleSize="small"
        onClick={onClick}
        ref={refersToRef}
        disabled={isPayingUser && generateGiftTokenMutation.isLoading}
        data-testid="gift-btn"
        title={fork({ default: 'שלחו את הכתבה במתנה', hdc: 'Gift this article' })}
        id={id}
      >
        <Icon icon="gift" styleExtend={iconStyleExtend} variant="neutral" />
        <VisuallyHidden>
          {fork({ default: 'שלחו את הכתבה במתנה', hdc: 'Gift this article' })}
        </VisuallyHidden>
      </ClickArea>
      <Popover
        refersToRef={refersToRef}
        onToggle={onToggle}
        isOpen={isPopoverOpen}
        placement="bottom"
        offsetValue={offsetValue}
        styleExtend={[c.popoverBorder]}
        keepInView
      >
        {popoverType && popoverByType[popoverType]}
      </Popover>
    </>
  );
}
